import { MapContainer } from 'react-leaflet';
import styled from 'styled-components';

export const MapWrapper = styled(MapContainer)(({ theme }) => ({
  width: '100%',
  height: `${window.innerHeight - 390}px`,
  [theme.breakpoints.down('xl')]: {
    height: `${window.innerHeight - 365}px`,
  },
}));
