import ic_sun from 'assets/icons/ic_sun.svg';
import ic_moon from 'assets/icons/ic_moon.svg';
import { IconsButton } from '../Buttons';

interface Props {
  mode: string;
  setMode: any;
}

export function DarkLightMode({ mode, setMode }: Props): JSX.Element {
  const ThemeIcon = (): JSX.Element => {
    return <img src={mode === 'dark' ? ic_sun : ic_moon} alt="" />;
  };
  return (
    <IconsButton
      name={'theme'}
      OnIconClick={() => {
        setMode(mode === 'dark' ? 'light' : 'dark');
      }}
      children={ThemeIcon()}
    ></IconsButton>
  );
}
