import { useLocation } from 'react-router-dom';
import {
  CurvedTop,
  CurvedBottom,
  MenuContainer,
  MenuItemWrapper,
  MenuLabelItem,
  SubMenuLink,
  SubMenuItemWrapper,
  IconButtonWrapper,
} from './styles';
import { MenuItemProps } from './types';
import { useTheme } from '@mui/material/styles';
import { DownArrowIcon } from 'assets/component';
import { checkMenusPermission } from 'app/features/authService';
import { actions } from 'app/features/AdminSlice/slice';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import CustomTooltip from 'app/component/core/Tooltip';
import { ERROR_TEXT } from 'utils/Constants/Content';

export function MenuItem({
  info,
  handleMenuSelection = () => {},
  isSelectedPath,
  siteActive,
}: MenuItemProps): JSX.Element {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const themes = useTheme();

  const getFullURL = (): string => {
    return info.elements?.length > 0 && info.elements[0]?.route
      ? info.elements[0]?.route
      : info.route;
  };
  const handleRouteClick = (info: any): void => {
    const menuPermission = checkMenusPermission(info.route);
    if (!menuPermission) {
      dispatch(actions.checkUserAccessPermission(true));
    } else {
      handleMenuSelection(info);
    }
  };

  const subMenuList = (list: any = [], isSelected = false): JSX.Element => {
    return (
      <>
        {list.length > 0 &&
          list.map((item: any) => {
            return (
              <SubMenuItemWrapper
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRouteClick(item);
                }}
                selected={pathname.includes(getFullURL())}
                isshowitems={isSelected}
                theme={themes}
                sx={{ flexDirection: 'column' }}
              >
                <SubMenuLink
                  theme={themes}
                  selected={item.isSelected}
                  permission={checkMenusPermission(item.route)}
                >
                  {item.elements.length > 0 ? (
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <span>{item.title}</span>
                      <IconButtonWrapper rotate={item.isSelected}>
                        <DownArrowIcon />
                      </IconButtonWrapper>
                    </Grid>
                  ) : (
                    <span>{item.title}</span>
                  )}
                </SubMenuLink>
                {item.elements.length > 0 && (
                  <div style={{ paddingLeft: '10px', width: '100%' }}>
                    {subMenuList(item.elements, item.isSelected)}
                  </div>
                )}
              </SubMenuItemWrapper>
            );
          })}
      </>
    );
  };

  return (
    <MenuContainer
      xs={12}
      container
      active={pathname.includes(info.route)}
      issection={Boolean(info.section)}
      theme={themes}
      flexDirection={'column'}
      sx={{
        opacity: siteActive && checkMenusPermission(info.route) ? 1 : 0.6,
      }}
      onClick={() => {
        handleRouteClick(info);
      }}
    >
      <CustomTooltip
        title={ERROR_TEXT.PENDING_INSTALLATION}
        disable={siteActive}
        placement="top"
      >
        <>
          {pathname.includes(info.route) && <CurvedTop theme={themes} />}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MenuItemWrapper
              hasChildren={info?.children?.length > 0}
              info={info}
              selected={pathname.includes(info.route)}
              theme={themes}
            >
              {info.icon}
            </MenuItemWrapper>
            <MenuLabelItem
              info={info}
              active={pathname.includes(info.route)}
              isSelectedPath={isSelectedPath}
              theme={themes}
            >
              {info.title}
            </MenuLabelItem>
            {info.elements.length > 0 ? (
              <Grid
                container
                justifyContent={'flex-end'}
                sx={{ paddingRight: '0.313rem' }}
              >
                <IconButtonWrapper
                  rotate={pathname.includes(info.route) && info.isSelected}
                >
                  <DownArrowIcon />
                </IconButtonWrapper>
              </Grid>
            ) : (
              ''
            )}
          </div>
          <div
            style={{
              display: info.isSelected === true ? 'block' : 'none',
            }}
          >
            {subMenuList(info.elements, info.isSelected)}
          </div>
          {pathname.includes(info.route) && <CurvedBottom theme={themes} />}
        </>
      </CustomTooltip>
    </MenuContainer>
  );
}
