/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useState } from 'react';
import {
  PaginationWrapper,
  PaginationContainer,
  MuiPagination,
  PaginationLabel,
} from './style';
import { PaginationProps } from './type';
import { Grid, useTheme } from '@mui/material';
import { CONTEXT } from 'utils/Constants/Content';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'app/pages/Router/constant';

const Pagination = ({
  totalRecords,
  currentPage,
  setCurrentPage,
  loading,
  recordLimit = CONTEXT.DEFAULT_PAGE_LIMIT,
  style = null,
}: PaginationProps) => {
  const { pathname } = useLocation();
  const [noOfPages, setNoOfPages] = useState(
    totalRecords > 0 ? CONTEXT.DEFAULT_PAGE_LIMIT : 0,
  );
  const [range, setRange] = useState<any>('-');
  const theme = useTheme();
  const getRange = (pageNo: any) => {
    const lastNo = Math.min(pageNo * recordLimit);
    const count = totalRecords < lastNo ? totalRecords : lastNo;
    return (pageNo - 1) * recordLimit + 1 + '-' + count;
  };
  useEffect(() => {
    if (totalRecords > 0) {
      setNoOfPages(Math.ceil(totalRecords / recordLimit));
      setRange(getRange(currentPage));
    } else {
      setNoOfPages(0);
      setRange('-');
    }
  }, [totalRecords]);

  return (
    <PaginationWrapper theme={theme} container sx={style}>
      {pathname.includes(`/${ROUTE_PATH.REPORTS}`) ||
      pathname.includes(`/${ROUTE_PATH.SELF_TEST}`) ? (
        <>
          <PaginationLabel item theme={theme}>
            {totalRecords > 0 ? (
              <Grid item>
                Showing {range} of {totalRecords}
              </Grid>
            ) : (
              <Grid item></Grid>
            )}
          </PaginationLabel>
          <PaginationContainer item>
            <MuiPagination
              count={noOfPages}
              shape="rounded"
              classes={{
                root: '.root',
              }}
              page={currentPage}
              onChange={(_event, value: number) => {
                setRange(getRange(value));
                setCurrentPage(value);
              }}
              disabled={loading}
            />
          </PaginationContainer>
        </>
      ) : (
        <>
          <PaginationLabel item theme={theme}>
            {!loading && totalRecords > 0 && (
              <>
                Showing {range} of {totalRecords}
              </>
            )}
          </PaginationLabel>
          {totalRecords > 0 && !loading && (
            <PaginationContainer item>
              <MuiPagination
                count={noOfPages}
                shape="rounded"
                classes={{
                  root: '.root',
                }}
                page={currentPage}
                onChange={(_event, value: number) => {
                  setRange(getRange(value));
                  setCurrentPage(value);
                }}
              />
            </PaginationContainer>
          )}
        </>
      )}
    </PaginationWrapper>
  );
};

export default Pagination;
