import { Cancel, SearchIcon } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import { Autocomplete, CircularProgress, InputAdornment } from '@mui/material';
import { COLORS } from 'styles/colors';
import { useRef } from 'react';
import { CustomTextField } from './style';
import { CONTEXT } from 'utils/Constants/Content';

export function CustomDefaultAutocomplete({
  value,
  placeholder,
  companySiteList = [],
  theme,
  handleChange,
  onTextChange,
  loading,
  showEndAdornment = false,
  isShowAdvanceSearch = false,
  onTextClear,
  style = null,
  isDisabled = false,
  customStyle = {},
  advanceFilter = {},
}: any): JSX.Element {
  const spanRef: any = useRef();

  const getOptionsLabel = (option: any) => {
    if (option.Type === CONTEXT.ADD_TO_COMPANY) {
      return `${option.partyName} ${
        option.Type ? `- ${option.Type}` : CONTEXT.ADD_TO_COMPANY
      }`;
    } else {
      return option?.Name
        ? `${option.Name} ${option.Type ? `- ${option.Type}` : ''}`
        : option?.ServiceRequestNumber
        ? `${option.ServiceRequestNumber} - ${option.Type}`
        : option?.WorkOrderNumber
        ? `${option.WorkOrderNumber} - ${option.Type}`
        : option || '';
    }
  };

  return (
    <>
      <Autocomplete
        freeSolo
        disableClearable
        value={value}
        filterOptions={(options: any) => options}
        getOptionLabel={getOptionsLabel}
        options={companySiteList}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        ListboxProps={{
          sx: {
            backgroundColor: theme.palette.customBackground.main,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            overflow: 'auto',
            minHeight: companySiteList?.length > 2 ? '200px' : '80px',
            '::-webkit-scrollbar': {
              width: '5px',
            },
            '::-webkit-scrollbar-track': {
              borderRadius: '0.625rem',
            },

            '::-webkit-scrollbar-thumb': {
              background:
                theme.palette.mode === 'dark'
                  ? COLORS.painiViolet
                  : COLORS.linkWaterBlue,
              borderRadius: '0.625rem',
            },
            'scrollbar-color':
              theme.palette.mode === 'dark'
                ? COLORS.painiViolet
                : COLORS.linkWaterBlue,
            'scrollbar-width': 'auto',
            '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)':
              {
                '-ms-overflow-style': 'scrollbar',
                'scrollbar-base-color': theme.palette.customBackground.scroll,
                'scrollbar-face-color': theme.palette.customBackground.scroll,
                'scrollbar-arrow-color': theme.palette.customBackground.scroll,
                'scrollbar-shadow-color': theme.palette.customBackground.scroll,
                'scrollbar-dark-shadow-color':
                  theme.palette.customBackground.scroll,
              },
          },
        }}
        renderInput={params => (
          <CustomTextField
            ref={spanRef}
            {...params}
            theme={theme}
            style={style || { width: '100%', ...customStyle }}
            size="small"
            placeholder={placeholder}
            onChange={onTextChange}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconsButton children={<SearchIcon />} isDisabled />
                </InputAdornment>
              ),

              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    showEndAdornment && (
                      <InputAdornment position="end">
                        <IconsButton
                          children={<Cancel />}
                          OnIconClick={onTextClear}
                          style={{ height: 'auto' }}
                          isDisabled={isDisabled}
                        />
                      </InputAdornment>
                    )
                  )}
                  {isShowAdvanceSearch &&
                    Object.keys(advanceFilter).length > 0 && {
                      ...advanceFilter,
                      props: { ...advanceFilter.props, spanRef },
                    }}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            data-testid="global-autocomplete-textfield"
          />
        )}
        data-testid="global-autocomplete"
        disabled={isDisabled}
      />
    </>
  );
}
