import { Grid } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const DashboardContainer = styled(Grid)`
  width: "auto",
  height: "100vh",
  fontFamily: "Inter, Sans-serif",
  fontStyle: "normal",
`;
export const Title = styled('h4')`
  font-weight: 700;
  font-size: 1.125rem;
  color: COlORS.black;
`;
export const TabsContainer = styled(Grid)(({ theme }) => ({
  background: COLORS.main,
  borderRadius: '0.5rem',
}));

export const CardContainer = styled('div')<any>(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: theme.palette.customBackground.main,
    borderRadius: '0.5rem',
    padding: '1rem',
  };
});
export const NavFooterContainer = styled('div')`
  width: 100%;
  position: fixed;
  bottom: 0.625rem;
`;
export const NavIconContainer = styled(Grid)`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const NavFooterMenuItem = styled(Grid)(({ theme }) => ({
  padding: '0.313rem 0.625rem',
  background: theme.palette.mode === 'dark' ? COLORS.haitiViolet : COLORS.white,
  alignItems: 'center',
  borderRadius: '0.5rem',
  justifyContent: 'space-between',
  marginBottom: '0.625rem',
  cursor: 'pointer',
}));
export const CenterWrapper = styled(Grid)`
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-size: 22px;
`;
export const ErrorMessage = styled(Grid)`
  font-size: 14px;
  overflow: auto;
  height: 15%;
  width: 100%;
  text-align: center;
`;
export const LayoutContainer = styled('div')<any>(({ theme, isHome }) => ({
  overflowY: isHome ? 'unset' : 'auto',
  overflowX: isHome ? 'unset' : 'hidden',
  maxHeight: '100%',
  width: '100%',
  height: '100%',
  borderRadius: '0.5rem',
  background: isHome ? '' : theme.palette.customBackground.primary,
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.containerScroll,
  'scrollbar-width': 'auto',
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    '-ms-overflow-style': 'scrollbar',
    'scrollbar-base-color': theme.palette.customBackground.containerScroll,
    'scrollbar-face-color': theme.palette.customBackground.containerScroll,
    'scrollbar-arrow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-shadow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-dark-shadow-color':
      theme.palette.customBackground.containerScroll,
  },
}));
