import { GlobalLabel, TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import CustomTooltip from '../Tooltip';
import { COLORS } from 'styles/colors';
import { Grid } from '@mui/material';
import { SecurityManagerUserListProps } from './type';
import { DeleteIcon } from 'assets/component';
import { getUserPermissions } from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { useSelector } from 'react-redux';
import { getSecurityManagerUserDeleteStatus } from 'app/features/SecurityManagerSlice/selectors';
import { hideString } from 'utils/CommonFn';

function SecurityManagerUserRowItem({
  key,
  rowItem,
  tableName,
  onEditIconClick,
  onDeleteIconClick,
}: SecurityManagerUserListProps): any {
  const theme = useTheme();
  const deleteSMUserStatus: any = useSelector(
    getSecurityManagerUserDeleteStatus,
  );
  const isAlarmCodesPermission: any = getUserPermissions(
    USER_PERMISSIONS.ALARM_CODES,
  );
  const isHasPermission = getUserPermissions(USER_PERMISSIONS.CONTACT_LIST);
  return (
    <TableRow
      container
      key={key}
      onClick={() => {}}
      alignContent="center"
      theme={theme}
    >
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.EmployeeName ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem?.EmployeeId ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.SiteName ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.SiteAddress ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={rowItem?.SiteCityStateZip ?? '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={
            rowItem?.AlarmCode
              ? !isAlarmCodesPermission
                ? hideString(rowItem?.AlarmCode.toString())
                : rowItem?.AlarmCode
              : '-'
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={
            rowItem?.isGlobal ? (
              <Grid container alignItems={'center'}>
                <GlobalLabel item theme={theme}>
                  Global
                </GlobalLabel>
              </Grid>
            ) : (
              '-'
            )
          }
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <Grid container>
          <CustomTooltip title={'Edit'}>
            <div>
              <IconsButton
                name="edit"
                children={<EditIcon />}
                style={{
                  background: COLORS.iconBgColor,
                  borderRadius: 4,
                  marginLeft: '5px',
                  opacity: isHasPermission && !rowItem?.isGlobal ? 1 : 0.6,
                }}
                OnIconClick={() => onEditIconClick(rowItem)}
                isDisabled={rowItem?.isGlobal}
              />
            </div>
          </CustomTooltip>
          <CustomTooltip title={'Delete'}>
            <div>
              <IconsButton
                name="delete"
                children={<DeleteIcon />}
                style={{
                  background: COLORS.iconBgColor,
                  borderRadius: 4,
                  marginLeft: '5px',
                  opacity: isHasPermission ? 1 : 0.6,
                }}
                OnIconClick={() => onDeleteIconClick(rowItem)}
                isDisabled={deleteSMUserStatus?.loading}
              />
            </div>
          </CustomTooltip>
        </Grid>
      </TableCell>
    </TableRow>
  );
}
export default SecurityManagerUserRowItem;
