import { TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { CUSTOM_ROW_WIDTH, DATE_FORMAT_4 } from 'utils/Constants';
import CustomTooltip from '../Tooltip';
import { CONTEXT } from 'utils/Constants/Content';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import { COLORS } from 'styles/colors';
import { getFormatedDate } from 'utils/CommonFn';
interface SecurityEventProps {
  key: any;
  rowItem: any;
  source: any;
  tableName?: any;
  onSiteHandleClick: any;
  onDetailsViewClick: any;
}
function SecurityEventsRowItem({
  key,
  rowItem,
  source,
  onSiteHandleClick,
  onDetailsViewClick,
}: SecurityEventProps): any {
  const theme = useTheme();
  const handleClick = (): any => {};
  const onSiteClick = (e: any) => {
    if (source !== CONTEXT.SITE_MANAGER) {
      onSiteHandleClick(e, rowItem.siteDetails.Id);
    }
  };
  return (
    <TableRow container key={key} onClick={handleClick} theme={theme}>
      <TableCell
        item
        sm={false}
        md={2.5}
        theme={theme}
        width={CUSTOM_ROW_WIDTH}
      >
        <CustomTooltip title={rowItem.siteDetails.SiteName} placement="top">
          <span
            className={`name ${source !== CONTEXT.SITE_MANAGER ? 'link' : ''}`}
            onClick={onSiteClick}
          >
            {rowItem.siteDetails.SiteName}
          </span>
        </CustomTooltip>
      </TableCell>
      <TableCell item sm={false} md={2} theme={theme}>
        <OverflowTip text={rowItem?.EventName ?? '-'} />
      </TableCell>
      <TableCell item sm={false} md={2} theme={theme}>
        <OverflowTip text={rowItem?.EventCategory ?? '-'} />
      </TableCell>
      <TableCell item sm={false} md={2} theme={theme} width={CUSTOM_ROW_WIDTH}>
        <OverflowTip
          text={getFormatedDate(
            rowItem.AlarmTime,
            DATE_FORMAT_4,
            rowItem?.siteDetails?.TimeZone,
          )}
        />{' '}
      </TableCell>
      {/* <TableCell item sm={false} md={0.75} theme={theme}>
        <OverflowTip text={rowItem?.Area ?? '-'} />
      </TableCell> */}
      <TableCell item sm={false} md={1} theme={theme}>
        <OverflowTip text={rowItem?.Zone ?? '-'} />
      </TableCell>
      <TableCell item sm={false} md={1.5} theme={theme}>
        <OverflowTip text={rowItem?.Resolution ?? '-'} />
      </TableCell>
      <TableCell item sm={false} md={1} theme={theme}>
        <CustomTooltip title={'View Details'}>
          <IconsButton
            name="edit"
            children={<EditIcon />}
            style={{
              background: COLORS.iconBgColor,
              borderRadius: 4,
            }}
            OnIconClick={(e: any) => onDetailsViewClick(e, rowItem)}
          />
        </CustomTooltip>
      </TableCell>
    </TableRow>
  );
}
export default SecurityEventsRowItem;
