import { Grid, useMediaQuery } from '@mui/material';
import { IconsButton } from 'app/component/core/Buttons';
import { getUserDetails, isImpersonateUser } from 'app/features/authService';
import { DownRoundArrowIcon, HamburgerMenuIcon } from 'assets/component';
import LogoIcon from 'assets/component/logoIcon';
import ImpersonateUserBanner from '../impersonateUserBanner';

export const MobileNav = ({
  hideShow,
  isMobileMenu,
  openMobileNavbar,
  theme,
}: any) => {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Grid item>
        <IconsButton
          name={'menu'}
          children={<HamburgerMenuIcon />}
          OnIconClick={hideShow}
        />
      </Grid>
      <Grid item>
        <LogoIcon
          customWidth={isMobileScreen ? 160 : isTabScreen ? 200 : 'auto'}
        />
      </Grid>
      <Grid item sx={{ zIndex: isMobileMenu ? 9999 : 9 }}>
        <IconsButton
          name={'menu'}
          children={<DownRoundArrowIcon isDown={!isMobileMenu} />}
          OnIconClick={openMobileNavbar}
        />
      </Grid>
      {isImpersonateUser() && (
        <ImpersonateUserBanner theme={theme} userDetails={getUserDetails()} />
      )}
    </>
  );
};
