import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { Grid, useTheme } from '@mui/material';
import { BellIcon, Cancel } from 'assets/component';
import { IconsButton } from '../Buttons';
import { CustomDivider } from 'app/pages/Dashboard/component/Tabs/styles';
import NotificationItem from './component/NotificationItem';
import { NotifyContainer, StyledBadge } from './component/style';

interface Props {
  badgeContent: number;
  data: any;
  onNotifyClick?: any;
  newReleaseData?: any;
  isShowNotification?: boolean;
  closeMobileMenu?: any;
}

export function CustomNotification({
  badgeContent = 0,
  data,
  onNotifyClick,
  newReleaseData,
  isShowNotification,
  closeMobileMenu,
}: Props): JSX.Element {
  const theme = useTheme();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const handleOpenUserMenu = (name: string, event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const onNotificationClick = (item: any) => {
    setAnchorElUser(null);
    onNotifyClick(item);
  };

  const open = Boolean(anchorElUser ?? isShowNotification);
  return (
    <>
      <Box sx={{ flexGrow: 0 }}>
        <IconsButton
          name={'notification'}
          children={
            <StyledBadge badgeContent={badgeContent > 9 ? '9+' : badgeContent}>
              <BellIcon />
            </StyledBadge>
          }
          OnIconClick={handleOpenUserMenu}
        />
        <Menu
          sx={{
            mt: '40px',
            '& .MuiList-root': {
              backgroundColor: theme.palette.customBackground.main,
            },
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: isShowNotification ? 'bottom' : 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              style: {
                width: isShowNotification ? '100%' : '23%',
                height: isShowNotification ? '50%' : 'auto',
                backgroundColor: theme.palette.customBackground.main,
                borderRadius: '0.5rem',
                color: theme.palette.text.primary,
              },
            },
          }}
          open={open}
          onClose={handleCloseUserMenu}
        >
          <Grid
            container
            item
            alignItems={'center'}
            justifyContent={'flex-end'}
            sx={{
              padding: '0 10px',
            }}
          >
            <IconsButton
              name="close"
              children={<Cancel />}
              OnIconClick={handleCloseUserMenu}
            />
          </Grid>
          <NotifyContainer container theme={theme} flexDirection={'column'}>
            <Grid item>
              <NotificationItem
                theme={theme}
                data={data}
                url={'/profile/notification'}
                closeMobileMenu={closeMobileMenu}
                onNotifyClick={onNotificationClick}
              />
              <CustomDivider />
              <NotificationItem
                theme={theme}
                title={'New Release'}
                data={newReleaseData}
                url={'/helpSupport/newrelease'}
                closeMobileMenu={closeMobileMenu}
                onNotifyClick={onNotificationClick}
              />
            </Grid>
          </NotifyContainer>
        </Menu>
      </Box>
    </>
  );
}
