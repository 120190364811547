import { FormControlLabel, Checkbox } from '@mui/material';
import { CustomTypography } from '../Breadcrumb/style';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components';
import { CheckboxIcon, CustomUncheckboxIcon } from 'assets/component';

const Wrapper = styled(FormControlLabel)``;

export interface Props {
  label: string;
  boxSize?: 'small' | 'medium' | undefined;
  isChecked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelStyle?: any;
  checkBoxStyle?: any;
  isShowCheckbox?: boolean;
  isDisabled?: boolean;
  wrapperStyle?: any;
  fieldName?: string;
  placement?: 'end' | 'start' | 'top' | 'bottom';
}

function CustomCheckboxWithIcon({
  label,
  boxSize = 'small',
  isChecked,
  handleChange,
  labelStyle = { fontSize: '0.666rem' },
  checkBoxStyle = {},
  isShowCheckbox = true,
  isDisabled = false,
  wrapperStyle = {},
  fieldName = '',
  placement,
}: Props) {
  const theme = useTheme();
  return (
    <Wrapper
      disabled={isDisabled}
      labelPlacement={placement}
      label={
        <CustomTypography theme={theme} key={Math.random()} sx={labelStyle}>
          {label}
        </CustomTypography>
      }
      control={
        <Checkbox
          style={checkBoxStyle}
          checked={isChecked}
          size={boxSize}
          icon={<CustomUncheckboxIcon />}
          checkedIcon={<CheckboxIcon />}
          onChange={handleChange}
          name={fieldName}
        />
      }
      sx={{ ...wrapperStyle }}
    />
  );
}
export default CustomCheckboxWithIcon;
