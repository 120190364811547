import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { CustomDivider } from 'app/pages/Dashboard/component/Tabs/styles';
import { CustomBadge, CustomLabel } from './style';
import { capitalizeFirstLetter } from 'utils/CommonFn';
import { COLORS } from 'styles/colors';
import { Link } from 'react-router-dom';
import { DISPLAY_TEXT } from 'utils/Constants/Content';

function NotificationItem({
  theme,
  title,
  data,
  url,
  closeMobileMenu,
  onNotifyClick,
}: any) {
  const dataLength = data?.length ?? 0;
  return (
    <List>
      {title && <ListItem alignItems="flex-start">{title}</ListItem>}
      {data && dataLength > 0 ? (
        data.map((item: any, index: number) => {
          return (
            <>
              <ListItem
                alignItems="flex-start"
                sx={{
                  transition: 'background-color .8s ease-out',
                  ':hover': {
                    borderRadius: '5px',
                    background:
                      theme.palette.mode === 'dark'
                        ? COLORS.haitiViolet
                        : COLORS.solitude,
                  },
                }}
                key={item.label}
                onClick={() => onNotifyClick(item)}
              >
                <ListItemAvatar sx={{ minWidth: '20px', marginTop: '3px' }}>
                  {!data.Read && (
                    <CustomBadge badgecolor={COLORS.royalBlue} variant="dot" />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <CustomLabel theme={theme} variant="body1">
                      {item.Title && capitalizeFirstLetter(item.Title)}
                    </CustomLabel>
                  }
                  secondary={
                    <CustomLabel theme={theme} variant="body2">
                      {item.Message && capitalizeFirstLetter(item.Message)}
                    </CustomLabel>
                  }
                />
              </ListItem>
              {data && dataLength > 1 && index !== dataLength - 1 && (
                <ListItem>
                  <CustomDivider />
                </ListItem>
              )}
              {url && index === dataLength - 1 && (
                <ListItem
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Link
                    to={url}
                    onClick={closeMobileMenu}
                    style={{ color: COLORS.lightRed, fontSize: '0.875rem' }}
                  >
                    See all
                  </Link>
                </ListItem>
              )}
            </>
          );
        })
      ) : (
        <ListItem sx={{ textAlign: 'center', cursor: 'auto' }}>
          <ListItemText
            primary={DISPLAY_TEXT.NO_NOTIFICATION_FOUND}
          ></ListItemText>
        </ListItem>
      )}
    </List>
  );
}
export default NotificationItem;
