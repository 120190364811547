import axios from 'axios';
import {
  getCompanyId,
  getUserId,
  getSiteId,
  isImpersonateUser,
  clearImpersonateSession,
  getAccessToken,
} from './authService';
import { isTokenExpired, resetTokenRequest } from './TokenUtils';
import { globalConfig } from 'appconfig/index';

export const apiInstance = () => {
  const { type, token } = getAccessToken();
  const authToken = `${type} ${token}`;
  const userID = getUserId();
  const companyID = getCompanyId();
  const siteID = getSiteId();
  const baseURL = globalConfig.getDetails();
  const customHeaders: any = {
    Authorization: authToken,
    CompanyId: companyID,
    SiteId: siteID,
  };
  const header: any = !authToken ? {} : customHeaders;

  const api = axios.create({
    baseURL: baseURL.REACT_APP_BASE_API,
    headers: {
      'content-Type': 'appliation/x-www-form-urlencoded',
      ...header,
    },
    params: { userId: userID },
  });
  api.interceptors.response.use(
    response => {
      return response;
    },
    (error): any => {
      const errorRes = error.response;
      if (isTokenExpired(errorRes) && isImpersonateUser()) {
        clearImpersonateSession();
      } else if (isTokenExpired(errorRes)) {
        return resetTokenRequest(error);
      } else {
        return Promise.reject(error);
      }
    },
  );
  return api;
};
