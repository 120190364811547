import { TextField, useMediaQuery } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { FixedSizeList as List } from 'react-window';

export const Wrapper = styled(Autocomplete)(({ theme }) => ({
  width: '100%',
  fontSize: '0.75rem',
  backgroundColor: theme.palette.neutral.contrastText,
  borderRadius: '4px',
  color: theme.palette.text.primary,
  '& input::placeholder': {
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    opacity: 1,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  'input::-ms-clear': {
    display: 'none',
    height: 0,
    width: 0,
  },
  ListboxProps: {
    sx: {
      backgroundColor: theme.palette.neutral.contrastText,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      overflow: 'auto',
      maxHeight: `${window.innerHeight - 450}px`,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        borderRadius: '0.625rem',
      },

      '::-webkit-scrollbar-thumb': {
        background:
          theme.palette.mode === 'dark'
            ? COLORS.painiViolet
            : COLORS.linkWaterBlue,
        borderRadius: '0.625rem',
      },
      'scrollbar-color':
        theme.palette.mode === 'dark'
          ? COLORS.painiViolet
          : COLORS.linkWaterBlue,
      'scrollbar-width': 'auto',
      '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)':
        {
          '-ms-overflow-style': 'scrollbar',
          'scrollbar-base-color': theme.palette.customBackground.scroll,
          'scrollbar-face-color': theme.palette.customBackground.scroll,
          'scrollbar-arrow-color': theme.palette.customBackground.scroll,
          'scrollbar-shadow-color': theme.palette.customBackground.scroll,
          'scrollbar-dark-shadow-color': theme.palette.customBackground.scroll,
        },
    },
  },
  sx: {
    borderRadius: '0.5rem',
    '& .MuiFilledInput-input:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 0 transparent inset',
    },
  },
}));
export const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.customBackground.main,
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '0.75rem',
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    padding: useMediaQuery(theme.breakpoints.up('xl')) ? '6px 6px' : '5px 6px',
    fontSize: '0.875rem',
  },
  borderRadius: '0.5rem',
  'input::-ms-clear': {
    display: 'none',
    height: 0,
    width: 0,
  },
}));
export const CustomList = styled(List)(({ theme }) => ({
  overflow: 'overlay',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },

  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.scroll,
    borderRadius: '0.625rem',
  },

  '-ms-overflow-style': 'scrollbar',
  'scrollbar-base-color': theme.palette.customBackground.scrollIE,
  'scrollbar-face-color': theme.palette.customBackground.scrollIE,
  'scrollbar-arrow-color': theme.palette.customBackground.scrollIE,
  'scrollbar-shadow-color': theme.palette.customBackground.scrollIE,
  'scrollbar-dark-shadow-color': theme.palette.customBackground.scrollIE,
  'scrollbar-track-color': theme.palette.customBackground.scroll,
}));
