import { useTheme } from '@mui/material/styles';
import { COLORS } from 'styles/colors';
import { ErrorLabel, StyledTextFieldDashboard } from './style';
import React from 'react';
import { IconsButton } from '../Buttons';
import { FilterSliderIcon, SearchWhiteIcon } from 'assets/component';
import { CustomBadge } from '../Notification/component/style';

export interface Props {
  label: string;
  onTextChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  type?: string;
  errorText?: string;
  autofocus?: boolean;
  onIconClick?: any;
  showEndAdornment?: any;
  endAdornmentType?: any;
  isDisabled?: boolean;
  onClick?: any;
  selectedTypes?: any;
  customStyle?: any;
  onKeyDown?: any;
}

function CustomInput({
  type = 'text',
  label,
  value,
  onTextChange,
  errorText = '',
  autofocus = true,
  onIconClick,
  showEndAdornment = true,
  endAdornmentType = 'search',
  isDisabled = false,
  onClick = () => {},
  selectedTypes,
  customStyle = {},
  onKeyDown = () => {},
}: Props) {
  const theme = useTheme();
  return (
    <>
      <StyledTextFieldDashboard
        disabled={isDisabled}
        theme={theme}
        autoFocus={autofocus}
        variant="outlined"
        type={type}
        name={label}
        size="small"
        placeholder={label}
        sx={[
          {
            paddingBottom: '0.625rem',
            border: errorText.length > 1 ? `2px solid ${COLORS.lightRed}` : '',
          },
          customStyle,
        ]}
        InputProps={{
          style: {
            background: theme.palette.primary.main,
          },
          endAdornment:
            showEndAdornment &&
            (endAdornmentType === 'search' ? (
              <IconsButton
                style={{
                  background: COLORS.iconBgColor,
                  borderRadius: 4,
                  scale: '0.7',
                }}
                name={label ?? 'search'}
                children={<SearchWhiteIcon />}
                OnIconClick={onIconClick}
              />
            ) : (
              <IconsButton
                name="advanceSearch"
                children={
                  <CustomBadge
                    badgecolor={COLORS.lightRed}
                    variant={selectedTypes ? 'dot' : ''}
                  >
                    <FilterSliderIcon />
                  </CustomBadge>
                }
                isShowTooltip={true}
                title="Advanced search"
                styleTooltip={{
                  height: '1.625rem',
                  alignItems: 'center',
                  display: 'flex',
                }}
              />
            )),
          'aria-label': 'Without label',
        }}
        InputLabelProps={{
          sx: {
            color: theme.palette.text.primary,
            fontFamily: "'Inter', sans-serif",
            fontWeight: 500,
            'aria-label': 'Without label',
          },
        }}
        value={value}
        onChange={onTextChange}
        fullWidth
        onClick={onClick}
        data-testid="input-dashboard"
        onKeyDown={onKeyDown}
      />
      {errorText.length > 0 && <ErrorLabel>{errorText}</ErrorLabel>}
    </>
  );
}

export default CustomInput;
