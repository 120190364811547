import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { getServicesCount } from 'app/features/DashboardSlice/Selectors';
import { ServiceCardItem } from './ServiceCardItem';
import {
  CustomDivider,
  GridContainer,
  Wrapper,
  ItemHeader,
  ServiceItem,
} from './styles';
import { QUICK_STATS } from 'utils/Constants';

const QuickStats = memo(({ onIconItemClick }: any): JSX.Element => {
  const theme = useTheme();
  const selectorServiceCount: any = useSelector(getServicesCount);
  const [quickStats, setQuickStats] = useState(QUICK_STATS);

  useEffect(() => {
    if (selectorServiceCount && selectorServiceCount.length > 0) {
      setQuickStats(selectorServiceCount);
    }
  }, [selectorServiceCount]);
  return (
    <>
      <Wrapper theme={theme}>
        <ItemHeader theme={theme}>Quick stats</ItemHeader>
        <CustomDivider variant="fullWidth" theme={theme} />
        <GridContainer container theme={theme}>
          {quickStats.map((service: any, index: number) => (
            <ServiceItem
              item
              key={Math.random() * index}
              xs={12}
              sm={3}
              md={3}
              lg={3}
              sx={{
                borderRight:
                  quickStats.length - 1 !== index &&
                  `1px solid ${theme.palette.divider}`,
              }}
            >
              <ServiceCardItem
                onÌconClick={() => onIconItemClick(service)}
                label={service.label}
                value={service.score}
              />
            </ServiceItem>
          ))}
        </GridContainer>
      </Wrapper>
    </>
  );
});
export default QuickStats;
