import styled from 'styled-components';

export const Title = styled('h5')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  color: theme.palette.customBackground.text,
  [theme.breakpoints.down('xl')]: {
    fontSize: '14px !important',
  },
}));
