import { Popover } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { IconsButton } from '../Buttons';
import { FilterSliderIcon } from 'assets/component';

import { CustomBadge } from '../Notification/component/style';
import { COLORS } from 'styles/colors';

const Wrapper = styled(Popover)``;

export interface Props {
  label: string;
  boxSize?: 'small' | 'medium' | undefined;
  isChecked?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelStyle?: any;
  checkBoxStyle?: any;
  isShowCheckbox?: boolean;
}

function CustomAdvanceFilter({
  spanRef = null,
  selectedType,
  children,
  isDisabled = false,
}: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (): void => {
    if (spanRef.current !== null) {
      setAnchorEl(spanRef.current);
    }
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconsButton
        name="advanceSearch"
        children={
          <CustomBadge
            badgecolor={COLORS.lightRed}
            variant={selectedType !== 1 ? 'dot' : ''}
          >
            <FilterSliderIcon />
          </CustomBadge>
        }
        isShowTooltip={!isDisabled}
        title="Advanced search"
        OnIconClick={handleClick}
        styleTooltip={{
          height: '1.625rem',
          alignItems: 'center',
          display: 'flex',
        }}
        isDisabled={isDisabled}
        style={{
          opacity: isDisabled ? 0.6 : 1,
        }}
      />
      <Wrapper
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0.5rem',
          },
        }}
      >
        <div style={{ width: `${spanRef?.current?.clientWidth}px` || 'auto' }}>
          {children}
        </div>
      </Wrapper>
    </>
  );
}
export default CustomAdvanceFilter;
