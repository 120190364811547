import { useState, useEffect } from 'react';
import { CustomTabs, CustomTab, CustomDivider } from './styles';
import { SITE_MANAGER_TAB, TABS, TOTAL_TABS } from 'utils/Constants/index';
import { useLocation } from 'react-router-dom';
import {
  getActiveDashboardTab,
  getCompanyId,
  getDashboardFilter,
  getDashboardListPageNo,
  getDashboardFilterData,
  getReportActiveTab,
  getViewType,
} from 'app/features/DashboardSlice/Selectors';
import {
  Events,
  Network,
  ServiceRequest,
  ServiceTrips,
  Sites,
} from './TabPanels';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, initialState } from 'app/features/DashboardSlice/slice';
import {
  fetchMasterCompanyId,
  getUserPermissions,
} from 'app/features/authService';
import CustomTooltip from 'app/component/core/Tooltip';
import { CONTEXT, DISPLAY_TEXT, ERROR_TEXT } from 'utils/Constants/Content';
import { Grid } from '@mui/material';
import { TabPanelProps } from './types';
import { actions as globalActions } from 'app/features/GlobalSlice/slice';
import { getExportStateList } from 'app/features/GlobalSlice/Selectors';
import { customListSearch, getFilterData } from 'utils/CommonFn';
import { USER_PERMISSIONS } from 'app/features/Constant';
import { isEmpty } from 'utils/CommonFn/validators';

export function Tabs(): JSX.Element {
  const dispatch = useDispatch();
  const activeDashboardTab = useSelector(getActiveDashboardTab);
  const [value, setValue] = useState(activeDashboardTab);
  const [tabs, setTab] = useState(TABS);
  const themes = useTheme();
  const { pathname } = useLocation();
  const companyID = fetchMasterCompanyId();
  const selectedView = useSelector(getViewType);
  const selectedCompanyId = useSelector(getCompanyId);
  const dashboardPageNo = useSelector(getDashboardListPageNo);

  const getFilters = useSelector(getDashboardFilterData);
  const filterEventStatus: any = getFilters?.data?.events?.[0] ?? [];
  const selectorFilterSite: any = getFilters?.data?.site?.[0] ?? [];
  const selectorServiceTripState: any =
    getFilters?.data?.serviceTrip?.[0] ?? [];
  const selectorServiceRequest: any =
    getFilters?.data?.serviceRequest?.[0] ?? [];
  const selectorServiceNetwork: any = getFilters?.data?.netowork?.[0] ?? [];
  const getReportTab: any = useSelector(getReportActiveTab);
  const eventStatus = filterEventStatus.eventCategoryList || [];
  const status = selectorServiceTripState?.st_status || [];
  const subject = selectorServiceRequest?.sr_subject || [];
  const srStatus = selectorServiceRequest?.sr_status || [];
  const severity = selectorServiceRequest?.severity || [];
  const incidentType = selectorServiceRequest?.sr_type || [];
  const system = selectorFilterSite?.installed_system || [];
  const service = selectorFilterSite?.service_level || [];
  const systemList = selectorFilterSite?.site_filter_dd_system || [];

  const networkStatus = selectorServiceNetwork?.network_status || [];
  const switchStatus = selectorServiceNetwork?.switch_status || [];

  const srSubStatus = selectorServiceRequest?.sr_sub_status || [];

  const dashboardFilter: any = useSelector(getDashboardFilter);
  const {
    locationValue,
    statusValue,
    workOrderNo,
    subjectValue,
    SeverityValue,
    StatusValue,
    serviceReqNumber,
    sortColumn,
    sortBy,
    networkStatusValue,
    switchStatusValue,
    systemValue,
    ServiceValue,
    incidentTypeValue,
    systemListValue,
    eventType,
    subStatusValue,
  } = dashboardFilter;
  const selectorStateData: any = useSelector(getExportStateList);
  const isAlarmEventPermission = getUserPermissions(
    USER_PERMISSIONS.ALARM_EVENT,
  );

  useEffect(() => {
    if (pathname.includes('/dashboard/sitemanager')) {
      const newTab: any = [];
      tabs.forEach(tab => {
        if (tab.id !== 1) {
          if (tab.id === 3) {
            tab.isActive = true;
            setValue(3);
          }
          newTab.push(tab);
        }
      });
      setTab(newTab);
      dispatch(actions.updateResolvedEventsActive(false));
    }
  }, [pathname]);

  const defaultServiceTripFilter = (): any => {
    let reqObj: any = {};
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (statusValue.length > 0) {
      reqObj.stStatus = customListSearch(statusValue, status);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    if (workOrderNo.length > 0) {
      reqObj.woNumber = workOrderNo;
    }
    if (sortColumn && selectedView === 'list') {
      reqObj.sortColumn = sortColumn;
      reqObj.sortBy = sortBy;
    }
    return reqObj;
  };

  const defaultNetworkRequestFilter = (): any => {
    let reqObj: any = {};
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (StatusValue.length > 0) {
      reqObj.srStatus = customListSearch(StatusValue, srStatus);
    }
    if (subjectValue.length > 0) {
      reqObj.srSubject = customListSearch(subjectValue, subject);
    }
    if (SeverityValue.length > 0) {
      reqObj.severity = customListSearch(SeverityValue, severity);
    }
    if (incidentTypeValue.length > 0) {
      reqObj.srType = customListSearch(incidentTypeValue, incidentType);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    if (serviceReqNumber.length > 0) {
      reqObj.srNumber = serviceReqNumber;
    }
    if (sortColumn && selectedView === 'list') {
      reqObj.sortColumn = sortColumn;
      reqObj.sortBy = sortBy;
    }
    if (subStatusValue.length > 0) {
      reqObj.srSubStatus = customListSearch(subStatusValue, srSubStatus);
    }
    return reqObj;
  };
  const defaultNetworkFilter = (): any => {
    let reqObj: any = {};
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (networkStatusValue.length > 0) {
      reqObj.networkStatus = customListSearch(
        networkStatusValue,
        networkStatus,
      );
    }
    if (switchStatusValue.length > 0) {
      reqObj.switchStatus = customListSearch(switchStatusValue, switchStatus);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };
  const defaultSiteFilter = (): any => {
    let reqObj: any = {};
    if (!isEmpty(getReportTab) && getReportTab === CONTEXT.SYSTEM) {
      dispatch(actions.updateReportActiveTab(''));
    } else {
      if (!isEmpty(sortBy)) {
        reqObj.sortBy = sortBy;
      }
      if (!isEmpty(sortColumn)) {
        reqObj.sortColumn = sortColumn;
      }
    }
    if (ServiceValue.length > 0) {
      reqObj.serviceLevel = customListSearch(ServiceValue, service);
    }
    if (systemValue.length > 0) {
      reqObj.installedSystem = customListSearch(systemValue, system);
    }
    if (systemListValue.length > 0) {
      reqObj.systemLevel = customListSearch(systemListValue, systemList);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };
  const defaultSecurityEventFilter = (): any => {
    let reqObj: any = {};
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (eventType.length > 0) {
      reqObj.eventType = customListSearch(eventType, eventStatus);
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };
  useEffect(() => {
    if (companyID && selectedCompanyId) {
      dispatch(actions.updateResolvedEventsActive(false));
      if (selectorStateData.data.length === 0) {
        dispatch(globalActions.fetchExportStatesList({}));
      }
      switch (value) {
        case 1:
          dispatch(
            actions.fetchFilterSiteData({
              page: dashboardPageNo,
              recordCountRequire: selectedView === 'list',
              ...defaultSiteFilter(),
            }),
          );
          break;
        case 2:
          dispatch(
            actions.fetchFilterNetworkData({
              page: dashboardPageNo,
              recordCountRequire: selectedView === 'list',
              ...defaultNetworkFilter(),
            }),
          );
          break;
        case 3:
          dispatch(
            actions.fetchFilterServiceRequest({
              page: dashboardPageNo,
              recordCountRequire: selectedView === 'list',
              ...defaultNetworkRequestFilter(),
            }),
          );

          break;
        case 4:
          dispatch(
            actions.fetchFilterServiceTrip({
              page: dashboardPageNo,
              recordCountRequire: selectedView === 'list',
              ...defaultServiceTripFilter(),
            }),
          );
          break;
        case 5:
          // dispatch(actions.fetchActiveSecurityEventsCount({}));
          dispatch(actions.fetchSecurityEventCategory());
          dispatch(
            actions.fetchFilterSecurityEvents({
              page: dashboardPageNo,
              recordCountRequire: selectedView === 'list',
              updateCount: true,
              ...defaultSecurityEventFilter(),
            }),
          );
          break;
      }
    }
  }, [value, selectedCompanyId]);

  const handleChange = (event: any, newValue: number): void => {
    dispatch(actions.updateDashboardListPageNo(1));
    setValue(newValue);
    dispatch(actions.updateDashboardActiveTab(newValue));
    const selectedIndexLabel = tabs.find((x: any) => x.id === newValue)?.label;
    // For service request & service trip default sorting on createdOn
    const filter = {
      ...initialState.dashboardFilter,
    };
    if ([3, 4].includes(newValue)) {
      filter.sortColumn = 'CreatedOn';
      filter.sortBy = 'desc';
    }
    dispatch(actions.updateDashboardFilter(filter));
    dispatch(actions.updateActiveTab(selectedIndexLabel));
  };

  const getTooltipTitle = (tabNo: any) => {
    return tabNo === SITE_MANAGER_TAB.SECURITY_EVENTS && !isAlarmEventPermission
      ? ERROR_TEXT.USER_PERMISSION_DENIEND_WARNING
      : `${DISPLAY_TEXT.FUTURE_ENHANCEMENT} - ${DISPLAY_TEXT.EVENT_TAB_DESC}`;
  };

  function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, index, ...other } = props;

    return (
      <Grid
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Grid item p={2} sx={{ width: '100%' }}>
            {children}
          </Grid>
        )}
      </Grid>
    );
  }
  return (
    <>
      {pathname.includes('/dashboard/sitemanager') && (
        <CustomDivider
          variant="fullWidth"
          theme={themes}
          style={{ marginTop: '1.3rem' }}
        />
      )}
      <CustomTabs
        value={value}
        onChange={handleChange}
        theme={themes}
        variant="scrollable"
        allowScrollButtonsMobile
      >
        {tabs?.map((item, index) => {
          return item.id < TOTAL_TABS ||
            (item.id === SITE_MANAGER_TAB.SECURITY_EVENTS &&
              isAlarmEventPermission) ? (
            <CustomTab
              key={index}
              currentindex={index}
              tabslength={tabs.length - 1}
              value={item.id}
              label={item.label}
              theme={themes}
            />
          ) : (
            <CustomTooltip title={getTooltipTitle(item.id)}>
              <CustomTab
                key={index}
                currentindex={index}
                tabslength={tabs.length - 1}
                value={item.id}
                label={item.label}
                theme={themes}
                disabled={false}
              />
            </CustomTooltip>
          );
        })}
      </CustomTabs>
      <CustomDivider variant="fullWidth" theme={themes} />

      <TabPanel value={value} index={1}>
        <Sites isSort={true} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Network isSort={true} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ServiceRequest isSort={true} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ServiceTrips isSort={true} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Events isSort={true} />
      </TabPanel>
    </>
  );
}
export default Tabs;
