import { createGlobalStyle } from 'styled-components';
import { COLORS } from './colors';
export const GlobalStyle = createGlobalStyle`
html,
body {
  padding:0;
  margin:0;
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Inter', sans-serif !important;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: auto;
  overflow-y: scroll;
   
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  min-height: 100%;
  min-width: 100%;
}
p, span, h1, h2, h3, h4, h5, h6, label {
    margin: 0px;
    font-family: 'Inter', sans-serif;
  }

  input, select {
      font-family: 'Inter', sans-serif;
      font-size: inherit;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      box-shadow: 0 0 0 30px transparent inset !important;
      -webkit-box-shadow: 0 0 0 30px transparent inset !important;
      transition: background-color 5000s ease-in-out 0s;
  }
  .leaflet-container {
    background-color: transparent;
  }
  .leaflet-popup-content {
    font-family: Inter,sans-serif;
    color:${COLORS.nightRiderGrey};
    font-weight: 600;
    margin: 5px 0.5rem;
    line-height: 1.4;
    border-radius: 0.5rem;
    width:max-content;
  }
  .leaflet-bottom, .leaflet-right:{
    display:none;
  }
  .leaflet-top, .leaflet-left{
    top: auto;
    left: auto;
    right: 1rem;
    bottom: 2rem;
  }
  .leaflet-popup-tip {
    width: 0px;
    height: 0px;
  }
  .leaflet-popup-close-button {
    display: none;
  }
  .leaflet-control-attribution a{
    display: none;
  }
  .leaflet-touch .leaflet-bar a{
    width:2.5rem;
    height:2.5rem;
    padding: 0.313rem;
  }
  .leaflet-touch .leaflet-bar a:first-child {
    color:${COLORS.cornFlowerBlue};
    border: 1px solid ${COLORS.greyLight};
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .leaflet-touch .leaflet-bar a:last-child {
    color:${COLORS.cornFlowerBlue};
    border: 1px solid ${COLORS.greyLight};
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .leaflet-control-zoom.leaflet-bar.leaflet-control{
    border:none;
  }
  .marker-cluster-small_network {
    background-color: rgb(210 78 1 / 67%) !important;
  }
  .marker-cluster-small_network div {
    background-color: #d24e01 !important;
    color: #fff !important;
  }
  .marker-cluster-medium_network {
    background-color:  rgb(42 166 123 / 67%) !important;s
  }
  .marker-cluster-medium_network div {
    background-color: ${COLORS.primaryColor} !important;
    color: #fff !important;
  }
  .marker-cluster-large_network {
    background-color: rgb(232 195 1 / 67%) !important;
  }
  .marker-cluster-large_network div {
    background-color: ${COLORS.backupColor} !important;
    color: #fff !important;
  }
  .marker-cluster-medium {
    background-color: #FE5F55 !important;s
  }
  .marker-cluster-large {
    background-color: #FE5F55 !important;
    color: #fff !important;
  }
  .marker-cluster-medium div {
    background-color: #FE5F55 !important;
    color: #fff !important;
  }
  .marker-cluster-small {
    background-color: rgb(72 65 235 / 64%) !important;
  }
  .marker-cluster-small div {
    background-color: ${COLORS.iconBgColor} !important;
    color: #fff !important;
  }
  .marker-cluster-large div{
    background-color: #FE5F55 !important;
  }
  .leaflet-popup-content {
    display: inline-table;
    width:max-content !important;
  }
  .leaflet-popup-content-wrapper{
    border-radius: 0.5rem;
  }
  .react-datepicker-popper{
    width: 330px !important;
    z-index: 3 !important;
  }
  .react-datepicker-wrapper {
    width:100%;
  }

  .leaflet-container .leaflet-control-attribution{
    display:none;
  }
  .react-final-marquee-wrapper{
    align-items:normal !important;
    width:95%;
    height:auto; 
  }

@keyframes animation {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-150%);
  }
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-25 {
  width: 25%;
}
.w-100 {
  width: 100% !important;
}
.responsive-image {
  height: auto;
}
.anchor-link {
  color: #4C58D1;
}
.fw-700 {
  font-weight: 700;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-05 {
  margin-top: 0.5rem;
}
.tip-card {
  width: 50%;
  background-color: #999999;
  padding: 0.35rem;
  display: flex;
  border-radius: 0.5rem;
}
.right-section {
  width: 10%;
  text-align: right;
}
.left-section {
  width: 90%;
}
.card-content {
  font-style: italic;
  border-radius: 0.5rem;
  border: 1px solid #888888;
  padding: 0.5rem
}
.tip-heading {
  color: #125183;
  font-weight: 700;
}
.oveflowText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.react-datepicker__close-icon::after {
  background-color: ${COLORS.iconBgColor} !important;
  border-radius: 4px !important;
  padding: 8px !important;
  content: "X" !important;
  line-height: 0.6 !important;
}
.pending {
  display:inline-block;
  clip-path: inset(0 0.9ch 0 0);
  animation: l 1s steps(5) infinite;
}
@keyframes l {
  to {
    clip-path: inset(0 -2ch 0 0)
  }
}
  `;
// input::-webkit-outer-spin-button,
//   input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
// input[type=number] {
//   -moz-appearance: textfield;
// }
// :root {
//   --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg)
//     saturate(0.3) brightness(0.7);
// }
// .leaflet-tile {
//   filter: ${Getmode === "dark" ? "var(--map-tiles-filter, none);" : ""}
// }
// .leaflet-container {
//   background: #33323e;
// }
