import { useTheme } from '@mui/material';
import { IconsButton } from 'app/component/core/Buttons';
import { CancelBlackIcon } from 'assets/component';
import { Wrapper, customStyle } from './styles';

export function ContentModal({ children, onCancelClick }: any): JSX.Element {
  const theme = useTheme();

  return (
    <>
      <Wrapper theme={theme} container justifyContent={'center'}>
        <IconsButton
          name={'close'}
          children={<CancelBlackIcon />}
          OnIconClick={onCancelClick}
          style={customStyle}
        />
        {children}
      </Wrapper>
    </>
  );
}
