import { Avatar, CardHeader, useMediaQuery } from '@mui/material';
import CustomTooltip from 'app/component/core/Tooltip';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

interface Props {
  fullName: string;
  theme: any;
  title: string;
  label: string;
}

export const CustomHeader = styled(CardHeader)(({ theme }) => ({
  color: COLORS.lightGrey,
  maxWidth: 300,
  '& .MuiCardHeader-avatar': {
    marginRight: 8,
  },
}));
export const CustomAvtar = styled(Avatar)(({ theme }) => ({
  backgroundColor: 'red',
}));

const getAvatar = (name: string, theme: any): any => {
  if (name) {
    const avtarName = name ? name.split(' ') : '';

    return {
      sx: {
        fontSize: 14,
        color: COLORS.black,
        bgcolor:
          theme.palette.mode === 'dark' ? COLORS.dark : COLORS.deeplightGrey,
      },
      children:
        avtarName && avtarName.length > 1
          ? avtarName[0][0] + avtarName[1][0]
          : avtarName[0][0],
    };
  }
};

export function Profile({ fullName, theme, title, label }: Props): JSX.Element {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <CustomHeader
      avatar={<Avatar {...getAvatar(label, theme)} />}
      subheader={
        <div>
          {!isMobileScreen && fullName.length > 8 ? (
            <CustomTooltip title={fullName} placement="bottom">
              <span> {fullName.substring(0, 8)}...</span>
            </CustomTooltip>
          ) : (
            <span>{fullName}</span>
          )}
        </div>
      }
      subheaderTypographyProps={{
        color:
          theme.palette.mode === 'dark' ? COLORS.white : COLORS.gulfBlueBlue,
        fontWeight: '700',
      }}
      title={title}
      sx={{
        padding: 0,
        color: theme.palette.mode === 'dark' ? COLORS.white : COLORS.lightGrey,
      }}
      data-testid="profile"
    />
  );
}
export default Profile;
