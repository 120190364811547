import { isImpersonateUser } from 'app/features/authService';
import IconsButton from './IconButton';
import { ButtonWrapper } from './style';
import { IconAndLabelButtonProps } from './type';
import { COLORS } from 'styles/colors';

function IconAndLabelButton({
  width = 'auto',
  type = 'button',
  fullWidth = false,
  customHeight,
  children,
  className,
  style = {},
  onClick = () => {},
  disabled = false,
  iconName,
  OnIconClick = () => {},
  iconStyle,
  isIcon = true,
}: IconAndLabelButtonProps) {
  const isImpersonate: any = isImpersonateUser(children);
  return (
    <ButtonWrapper
      type={type}
      width={width}
      disabled={disabled || isImpersonate}
      style={style}
      onClick={onClick}
      className={className}
      fullWidth={fullWidth}
      customHeight={customHeight}
      data-testid="btn-icon-label"
    >
      {isIcon && (
        <IconsButton
          style={{
            background: COLORS.testLogColor,
            borderRadius: 4,
            margin: '0.313rem',
            height: '80%',
            ...iconStyle,
          }}
          isDisabled={disabled}
          children={iconName}
          OnIconClick={OnIconClick}
        />
      )}
      {!isIcon && (
        <span style={{ marginRight: children ? '0.4rem' : '0' }}>
          {iconName}
        </span>
      )}
      {children}
    </ButtonWrapper>
  );
}

export default IconAndLabelButton;
