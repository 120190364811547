import { Grid } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const LeftWrapper = styled(Grid)({
  width: '100%',
  background: COLORS.royalBlue,
  borderBottomLeftRadius: '0.5rem',
  borderTopLeftRadius: '0.5rem',
});
export const RightWrapper = styled(Grid)({
  width: '100%',
  background: COLORS.white,
  color: COLORS.black,
  borderBottomRightRadius: '0.5rem',
  borderTopRightRadius: '0.5rem',
});
export const Heading = styled(Grid)({
  width: '100%',
  fontSize: '1.125rem',
  fontWeight: 'bold',
  color: COLORS.white,
  textAlign: 'center',
});
export const Title = styled(Grid)({
  width: '100%',
  fontSize: '1rem',
  fontWeight: 'normal',
  color: COLORS.white,
});
export const CustomBody = styled(Grid)({
  width: '100%',
  fontWeight: 'normal',
  padding: '1.25rem',
  img: {
    width: '100%',
    height: '100%',
  },
});
export const SubTitle = styled(Grid)({
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  color: COLORS.white,
});
