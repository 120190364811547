import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { isEmpty } from 'utils/CommonFn/validators';
import ProtectedRoute from 'app/component/layout/ProtectedRouteLayout';
import { IsMatchRoute } from './constant';
import Pages from './routerConfig';
import { routeTypes } from './type';
import Loader from 'app/component/layout/Loader/Loader';
import { ErrorsBoundary } from 'app/component/layout/ErrorBoundary';

const renderRoute = (route: any, props: any) => {
  return route.map(
    ({ title, path, element, children = [] }: routeTypes, index: number) => {
      const routeProps: any = {
        path,
        key: title,
        index: index === 0,
        element:
          isEmpty(element) || IsMatchRoute.includes(title) ? (
            element
          ) : (
            <ProtectedRoute {...props}>{element}</ProtectedRoute>
          ),
      };
      return (
        <Route {...routeProps} errorElement={<ErrorsBoundary />}>
          {children.length > 0 && <Route>{renderRoute(children, props)}</Route>}
        </Route>
      );
    },
  );
};
const Router = (props: any) => {
  const pageRouts = createRoutesFromElements(renderRoute(Pages, props));
  const router = createBrowserRouter(pageRouts);
  return (
    <RouterProvider
      router={router}
      fallbackElement={<Loader size={100} maxHeight={true} />}
    ></RouterProvider>
  );
};

export default Router;
