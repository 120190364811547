/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Drawer as MuiDrawer } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface DrawerProps {
  toggleDetailsDrawer: boolean;
  setToggleDetailsDrawer: React.Dispatch<boolean>;
  children: JSX.Element;
  variant?: any;
  drawerWidth?: number;
}

const CustomDrawer = ({
  toggleDetailsDrawer,
  setToggleDetailsDrawer,
  children,
  variant = 'temporary',
  drawerWidth = 250,
}: DrawerProps) => {
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
    setToggleDetailsDrawer(false);
  };
  useEffect(() => {
    setVisible(toggleDetailsDrawer);
  }, [toggleDetailsDrawer]);

  return (
    <MuiDrawer
      anchor="left"
      open={visible}
      onClose={onClose}
      sx={{
        width: drawerWidth,
        zIndex: variant !== 'temporary' ? '999' : '1200',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'transparent',
          border: 0,
        },
      }}
      variant={variant}
    >
      {children}
    </MuiDrawer>
  );
};

export default CustomDrawer;
