import { LogoContainer } from './styles';
import LogoIcon from 'assets/component/logoIcon';
import { HelpLogoIcon } from 'assets/component';
import { useTheme } from '@mui/material/styles';
function SidebarHeader({
  onLogoClick,
  isHelpLogo = false,
  style = {},
}: any): JSX.Element {
  const theme = useTheme();
  return (
    <LogoContainer theme={theme} style={style}>
      <div onClick={onLogoClick}>
        {isHelpLogo ? <HelpLogoIcon /> : <LogoIcon />}
      </div>
    </LogoContainer>
  );
}

export default SidebarHeader;
