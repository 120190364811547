import { Grid } from '@mui/material';
import { PrimaryButton } from 'app/component/core/Buttons';
import {
  LeftWrapper,
  RightWrapper,
  Heading,
  Title,
  CustomBody,
} from './styles';
import { COLORS } from 'styles/colors';
import { BUSINESS_SECURITY, BUTTON_TEXT } from './Constant';
import BusinessSecurityContentIcon from 'assets/component/BusinessSecurityContentIcon';

export function BusinessSecurityContent({
  onLearnMoreClick,
  onContactSalesClick,
  item,
}: any): JSX.Element {
  const learnMoreClick = (): void => {
    onLearnMoreClick(item);
  };
  return (
    <Grid container>
      <LeftWrapper item xs={6} p={4}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{ textAlign: 'center', height: '100%' }}
        >
          <Heading item>{BUSINESS_SECURITY.LEFT_TITLE}</Heading>
          <CustomBody item sx={{ display: 'flex', alignItems: 'center' }}>
            <Title item>{BUSINESS_SECURITY.LEFT_SUB_TITLE}</Title>
          </CustomBody>
          <Grid item>
            <PrimaryButton
              style={{
                backgroundColor: COLORS.darkBlue,
              }}
              onClick={onContactSalesClick}
              children={BUTTON_TEXT.CONTACT_SALES}
              fullWidth={false}
              disabled={false}
            />
            <PrimaryButton
              style={{
                backgroundColor: 'transparent',
                width: '100%',
              }}
              onClick={learnMoreClick}
              children={BUTTON_TEXT.LEARN_MORE}
              fullWidth={false}
              disabled={false}
            />
          </Grid>
        </Grid>
      </LeftWrapper>
      <RightWrapper item xs={6} p={4}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Heading item sx={{ fontWeight: 'normal', color: COLORS.black }}>
            {BUSINESS_SECURITY.RIGHT_TITLE}
          </Heading>
          <CustomBody item>
            <BusinessSecurityContentIcon />
            {/* <img src={NetworkSecurity} alt={BUSINESS_SECURITY.LEFT_TITLE} /> */}
          </CustomBody>
          <Title item sx={{ color: COLORS.black }}>
            {BUSINESS_SECURITY.RIGHT_SUB_TITLE}
          </Title>
        </Grid>
      </RightWrapper>
    </Grid>
  );
}
