/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { memo, useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { Popup, Marker, TileLayer } from 'react-leaflet';
import down from 'assets/icons/down.svg';
import pendingLightDot from 'assets/icons/pendingLightDot.svg';
import pendingDarkDot from 'assets/icons/pendingDarkDot.svg';
import primary from 'assets/icons/primary.svg';
import mapDotIcon from 'assets/icons/mapDotIcon.svg';
import backup from 'assets/icons/backup.svg';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { IconsButton } from 'app/component/core/Buttons';
import { NetworkTowerIcon, FullScreenIcon } from 'assets/component';
import L from 'leaflet';
import { useTheme } from '@mui/material/styles';
import { MapWrapper } from './style';
import { COLORS } from 'styles/colors';
import MarkerClusterGroup from 'react-leaflet-cluster';
import Loader from 'app/component/layout/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import { saveOnLocalStorage } from 'store/localStore';
import { isEmpty } from 'utils/CommonFn/validators';
import { Labels } from '../Label';
import { DATE_FORMAT_4, DATE_FORMAT_2, MAP_DATA } from 'utils/Constants';
import { CONTEXT } from 'utils/Constants/Content';
import { getFormatedDate, uniqueId } from 'utils/CommonFn';
import { STORE_KEY } from 'store/Constant';
import { ROUTE_PATH } from 'app/pages/Router/constant';

interface MapProps {
  markerResponseData?: any;
  isShowlabel?: boolean;
  type?: string;
  loading?: boolean;
  labelCount?: any;
  onFullScreenclick?: any;
}
const CustomMaps = ({
  markerResponseData,
  isShowlabel = false,
  type,
  loading,
  labelCount = null,
  onFullScreenclick,
}: MapProps): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();
  const tileRef = useRef<any>();
  const [map, setMap] = useState(null);
  const lightMap =
    'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png';

  const match = useMediaQuery(theme.breakpoints.up('xl'));

  const getMarkerIcon = (item: any): any => {
    const { CurrentStatus, SiteActiveStatus } = item;
    let colorCode: any;
    let icon: any;
    if (type === CONTEXT.NETWORK && CurrentStatus) {
      switch (CurrentStatus) {
        case 'Up':
          icon = primary;
          colorCode = COLORS.jungleGreen;
          break;
        case 'Warning':
          icon = backup;
          colorCode = COLORS.backupColor;
          break;
        case 'Down':
          icon = down;
          colorCode = COLORS.downColor;
          break;
        case 'UNKNOWN':
          icon = mapDotIcon;
          colorCode = COLORS.iconBgColor;
          break;
      }
    } else if (type === CONTEXT.SITE) {
      icon = !SiteActiveStatus
        ? theme.palette.mode === 'dark'
          ? pendingDarkDot
          : pendingLightDot
        : mapDotIcon;
      colorCode = !SiteActiveStatus ? COLORS.darkGrey : COLORS.iconBgColor;
    } else {
      colorCode = COLORS.iconBgColor;
      icon = mapDotIcon;
    }
    const iconUrl = L.icon({ iconUrl: icon });
    return {
      colorCode,
      iconUrl,
    };
  };
  const OnSiteClick = (e: any, item: any) => {
    const ID = item?.Id;
    if (!isEmpty(ID)) {
      e.preventDefault();
      saveOnLocalStorage(STORE_KEY.SITE_INFO_ID, ID);
      navigate(`sitemanager/${ID}`);
    }
  };
  const OnServiceReqClick = (e: any, item: any) => {
    const caseNumber = item.CaseNumber;
    if (!isEmpty(caseNumber)) {
      e.preventDefault();
      navigate(
        { pathname: `/${ROUTE_PATH.DASHBOARD}/${ROUTE_PATH.CASE_DETAILS}` },
        { state: item },
      );
    }
  };
  const OnServiceTripClick = (e: any, item: any) => {
    const workOrderNo = item.WorkOrderNumber;
    if (!isEmpty(workOrderNo)) {
      e.preventDefault();
      navigate(`/dashboard/tripdetail/${workOrderNo}`);
    }
  };
  const redirectToSiteManager = (e: any, item: any) => {
    const itemId = item.Id || item.SiteId;
    if (!isEmpty(itemId)) {
      e.preventDefault();
      saveOnLocalStorage(STORE_KEY.SITE_INFO_ID, itemId);
      navigate(`/dashboard/sitemanager/${itemId}`);
    }
  };
  const getMarkerData = (item: any): any => {
    let result = {};
    if (item) {
      switch (type) {
        case CONTEXT.SITE:
          result = (
            <>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  OnSiteClick(e, item);
                }}
              >
                Name: {item.Name}
              </Stack>
              <Stack>Party Number:{item?.CompanyNumber}</Stack>
              <Stack>Site ID:{item?.OracleSiteId}</Stack>
              <Stack>Address: {item.Address}</Stack>
              <Stack>City: {item.City}</Stack>
              <Stack>
                Phone: {item.PhoneNumber !== null ? item.PhoneNumber : '-'}
              </Stack>
            </>
          );
          break;
        case CONTEXT.NETWORK:
          result = (
            <>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  redirectToSiteManager(e, item);
                }}
              >
                Site: {item.SiteName}
              </Stack>
              <Stack>Party Number:{item.CompanyNumber}</Stack>
              <Stack>Site ID:{item.OracleSiteId}</Stack>
              <Stack>Status: {item.CurrentStatus}</Stack>
              <Stack>Address: {item.SiteAddress}</Stack>
              <Stack>City: {item?.SiteCity ?? '-'}</Stack>
              <Stack>Phone: {item.SitePhoneNumber}</Stack>
            </>
          );
          break;
        case CONTEXT.SERVICE_REQUESTS:
          result = (
            <>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  OnServiceReqClick(e, item);
                }}
              >
                Service Number: {item.CaseNumber}
              </Stack>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  redirectToSiteManager(e, item);
                }}
              >
                Site: {item?.Name}
              </Stack>
              <Stack>Party Number:{item.CompanyNumber}</Stack>
              <Stack>Site ID:{item.OracleSiteId}</Stack>
              <Stack>Subject: {item.Subject}</Stack>
              <Stack>Status: {item.Status}</Stack>
              <Stack>
                Sub Status: {!isEmpty(item.SubStatus) ? item.SubStatus : '-'}
              </Stack>
              <Stack>Severity: {item.Priority}</Stack>
              <Stack>
                Created on:
                {getFormatedDate(item.CreatedOn, DATE_FORMAT_2)}
              </Stack>
              <Stack>
                Resolved on:
                {getFormatedDate(item.ResolvedOn, DATE_FORMAT_2)}
              </Stack>
            </>
          );
          break;
        case CONTEXT.SERVICE_TRIP:
          result = (
            <>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  OnServiceTripClick(e, item);
                }}
              >
                Work Order: {item.WorkOrderNumber}
              </Stack>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  redirectToSiteManager(e, item);
                }}
              >
                Site: {item?.Name}
              </Stack>
              <Stack>Party Number:{item.CompanyNumber}</Stack>
              <Stack>Site ID:{item.OracleSiteId}</Stack>
              <Stack>Status: {item.Status}</Stack>
            </>
          );
          break;
        case CONTEXT.SECURITY_EVENTS:
          result = (
            <>
              <Stack
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={(e: any) => {
                  OnSiteClick(e, item);
                }}
              >
                Site Name: {item?.SiteName}
              </Stack>
              <Stack>Site Address: {item?.Address ?? '-'}</Stack>
              <Stack>City: {item?.City ?? '-'}</Stack>
              <Stack>State:{item?.State}</Stack>
              <Stack>Zip:{item?.ZipCode ?? '-'}</Stack>
              <Stack>Event Name: {item?.EventName ?? '-'}</Stack>
              <Stack>Event Category: {item?.EventCategory ?? '-'}</Stack>
              <Stack>
                Alarm Time:{' '}
                {getFormatedDate(
                  item?.AlarmTime,
                  DATE_FORMAT_4,
                  item?.TimeZone,
                )}
              </Stack>
            </>
          );
          break;
      }
    }
    return { result };
  };

  const createClusterIcon = function (cluster: any) {
    const childCount = cluster.getChildCount();
    let c = ' marker-cluster-';
    const myCustomCount: any = [];

    if (type === CONTEXT.NETWORK) {
      const childMarkers = cluster.getAllChildMarkers();

      for (const childMarker of childMarkers) {
        myCustomCount.push(childMarker.getAttribution());
      }
    }

    if (myCustomCount.includes('Down')) {
      c += 'small_network';
    } else if (myCustomCount.includes('Up')) {
      c += 'medium_network';
    } else if (myCustomCount.includes('Warning')) {
      c += 'large_network';
    } else {
      c += 'small';
    }
    return new L.DivIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'marker-cluster' + c,
      iconSize: new L.Point(40, 40),
    });
  };

  useEffect(() => {
    if (map && theme.palette.mode === 'dark') {
      const mapStyle = tileRef.current.getContainer();
      mapStyle.style.setProperty(
        'filter',
        'brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const pendingSite =
    theme.palette.mode === 'dark' ? COLORS.white : COLORS.dimGrey;
  const ACITVE_DEACTIVE_SITES = [
    {
      label: 'Pending Installation',
      value: 0,
      fill: pendingSite,
      stroke: COLORS.iconBgColor,
    },
    {
      label: 'Active Site',
      value: 0,
      fill: COLORS.iconBgColor,
      stroke: COLORS.iconBgColor,
    },
  ];

  const onready: any = !map ? setMap : () => {};
  return (
    <MapWrapper
      center={[39.5, -98.35]}
      zoom={5}
      whenReady={onready}
      theme={theme}
    >
      <TileLayer ref={tileRef} url={lightMap} />
      <Grid
        container
        sx={{
          position: 'absolute',
          top: 0,
          left: 5,
          zIndex: 999,
          display: 'flex',
          width: 'auto !important',
        }}
        justifyContent={'space-between'}
      >
        {isShowlabel && (
          <Grid item>
            <Grid
              container
              direction="row"
              spacing={2}
              sx={{
                borderRadius: '0.5rem',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(28, 27, 40, 0.7)'
                    : 'rgba(202, 204, 213, 0.7)',
                padding: '0.625rem',
              }}
            >
              <Labels
                labelCount={labelCount}
                customStyle={{
                  fontSize: match ? '0.875rem' : '0.75rem',
                  paddingTop: 0,
                }}
                mapData={
                  type === CONTEXT.NETWORK ? MAP_DATA : ACITVE_DEACTIVE_SITES
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        sx={{
          width: 'auto',
          right: 0,
          position: 'absolute',
          top: 0,
          zIndex: 999,
          padding: '1.25rem',
        }}
      >
        {isShowlabel && type === CONTEXT.NETWORK && (
          <Grid item>
            <IconsButton
              children={<FullScreenIcon />}
              style={{
                border: '1px solid #ccc',
                background: COLORS.white,
                borderRadius: 8,
              }}
              OnIconClick={onFullScreenclick}
              title="Projector Mode"
              isShowTooltip={true}
              name={'ProjectorMode'}
            />
          </Grid>
        )}
      </Grid>
      <MarkerClusterGroup
        key={`cluster_${uniqueId()}`}
        chunkedLoading
        iconCreateFunction={createClusterIcon}
        onMouseOver={(cluster: any) => {
          try {
            if (type === CONTEXT.NETWORK && cluster) {
              const myCustomCount: any = [];
              const childMarkers =
                cluster?.propagatedFrom?.getAllChildMarkers();

              for (const childMarker of childMarkers) {
                myCustomCount.push(childMarker.getAttribution());
              }
              const counts: any = {};
              if (myCustomCount && myCustomCount.length) {
                myCustomCount.forEach(function (x: any) {
                  counts[x] = (counts[x] || 0) + 1;
                });
              }
              let result: any = '';
              for (const [key, value] of Object.entries(counts) as any) {
                result += `<div><strong>${key}</strong>: ${value}</div>`;
              }
              cluster.propagatedFrom.bindPopup(`${result}`).openPopup();
            }
          } catch (error) {
            console.log(error);
          }
        }}
        onMouseOut={(cluster: any) => {
          if (type === CONTEXT.NETWORK) {
            try {
              cluster.propagatedFrom.closePopup();
            } catch (error) {
              console.log(error);
            }
          }
        }}
      >
        {loading ? (
          <>
            <Loader
              size={40}
              customStyle={{
                background: 'transparent',
                position: 'relative',
                zIndex: 999,
                height: '100%',
              }}
            />
          </>
        ) : (
          markerResponseData !== null &&
          markerResponseData.length > 0 &&
          markerResponseData.map((item: any) => {
            const { colorCode, iconUrl } = getMarkerIcon(item);
            const { result } = getMarkerData(item);
            return (
              <Marker
                key={uniqueId()}
                position={[item.Latitude, item.Longitude]}
                icon={iconUrl}
                attribution={item.CurrentStatus}
              >
                <Popup>
                  <Grid container spacing={2}>
                    <Grid item>
                      <IconsButton
                        children={<NetworkTowerIcon />}
                        style={{ backgroundColor: colorCode, height: 'auto' }}
                      />
                    </Grid>
                    <Grid item alignItems={'center'}>
                      {result}
                    </Grid>
                  </Grid>
                </Popup>
              </Marker>
            );
          })
        )}
      </MarkerClusterGroup>
    </MapWrapper>
  );
};
export default memo(CustomMaps);
