/* eslint-disable @typescript-eslint/no-floating-promises */
import * as React from 'react';
import { IconButton, MenuItem, Menu, useTheme } from '@mui/material';
import { DownArrowIcon } from 'assets/component';
import Fade from '@mui/material/Fade';
import { useNavigate } from 'react-router-dom';
import { CustomDivider } from 'app/pages/Dashboard/component/Tabs/styles';
import { ROUTE_PATH } from 'app/pages/Router/constant';
import { isImpersonateUser } from 'app/features/authService';

export function Logout(): JSX.Element {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): any => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): any => {
    setAnchorEl(null);
  };
  const onLogout = (): void => {
    handleClose();
    navigate(`/${ROUTE_PATH.LOGOUT}`, { replace: true });
  };
  const onProfile = (): void => {
    navigate(`/${ROUTE_PATH.PROFILE}`);
  };
  return (
    <>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <DownArrowIcon />
        </IconButton>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
            sx: {
              backgroundColor: theme.palette.customBackground.main,
              '&& .MuiButtonBase-root:hover ': {
                borderRadius: '0.5rem',
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: '0.5rem',
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={onProfile}>Profile</MenuItem>
          {!isImpersonateUser() && (
            <>
              <CustomDivider />
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </>
          )}
        </Menu>
      </div>
    </>
  );
}
