import ModalComponent from 'app/component/core/Modal';
import { AlertPopup } from 'app/component/core/Modal/AlertPopup';
import { hasPermissionAccess } from 'app/features/AdminSlice/Selectors';
import { actions } from 'app/features/AdminSlice/slice';
import { actions as dashhboardAction } from 'app/features/DashboardSlice/slice';
import { ERROR_TEXT } from 'utils/Constants/Content';
import {
  checkMenusPermission,
  clearImpersonateSession,
  getAccessToken,
  getUserDetails,
  isImpersonateUser,
  isLoggedIn,
} from 'app/features/authService';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Layout } from '../index';
import { ROUTE_PATH, PREVENT_PATH } from 'app/pages/Router/constant';
import { getImpersonateSession } from 'app/features/DashboardSlice/Selectors';

const ProtectedRoute = (props: any): any => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname }: any = useLocation();
  const isAnyHistory = pathname.key !== 'default';
  const [isAuthUser] = useState(isLoggedIn());
  const hasAccess = useSelector(hasPermissionAccess);
  const [isAllowPermission, setIsAllowPermission] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const impersonateSessionExpired = useSelector(getImpersonateSession);
  const { beforeSessionExpire, sessionExpire } = impersonateSessionExpired;
  const { token } = getAccessToken();
  const { TncAccepted } = getUserDetails() ?? {};

  const onImpersonateModalClose = () => {
    setViewModal(false);
    dispatch(
      dashhboardAction.updateImpersonateSession({
        beforeSessionExpire: false,
        sessionExpire: false,
      }),
    );
    if (sessionExpire) clearImpersonateSession();
  };

  useEffect(() => {
    if (beforeSessionExpire || sessionExpire) {
      setViewModal(true);
    }
  }, [beforeSessionExpire, sessionExpire]);

  useEffect(() => {
    if (!token) {
      navigate(`/${ROUTE_PATH.LOGIN}`);
    } else if (hasAccess) {
      setIsAllowPermission(hasAccess);
    }
  }, [isAuthUser, hasAccess]);

  useEffect(() => {}, [isAuthUser]);

  const onPermissionModalClose = (): void => {
    setIsAllowPermission(false);
    dispatch(actions.checkUserAccessPermission(false));
  };
  if (!isAuthUser) return <Navigate to={`/${ROUTE_PATH.LOGOUT}`} replace />;

  if (!checkMenusPermission(pathname)) {
    dispatch(actions.checkUserAccessPermission(true));
    if (isAnyHistory) {
      navigate(`/${ROUTE_PATH.DASHBOARD}`, { replace: true });
    }
  }
  if (!TncAccepted && !isImpersonateUser())
    return <Navigate to={`/${ROUTE_PATH.TERMS_CONDITION}`} replace />;

  if (!pathname.includes(ROUTE_PATH.USERS)) {
    dispatch(actions.updateUserActiveTab(1));
  }

  if (PREVENT_PATH.includes(pathname)) return props.children;

  return (
    <>
      <Layout {...props} />
      <ModalComponent
        modalOpen={isAllowPermission}
        children={
          <AlertPopup
            message={ERROR_TEXT.USER_PERMISSION_DENIEND_WARNING}
            onOkClick={onPermissionModalClose}
          />
        }
        setModalOpen={onPermissionModalClose}
      />
      <ModalComponent
        modalOpen={viewModal}
        children={
          <AlertPopup
            message={
              beforeSessionExpire
                ? ERROR_TEXT.IMPERSONATE_USER_SESSION_EXPIRED_WARNING
                : ERROR_TEXT.IMPERSONATE_USER_SESSION_EXPIRED_MESSAGE
            }
            onOkClick={onImpersonateModalClose}
          />
        }
        setModalOpen={onImpersonateModalClose}
      />
    </>
  );
};

export default ProtectedRoute;
