import { Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import {
  MenuContainerProps,
  MenuItemProps,
  MenuItemStyleProps,
  SectionWrapperProps,
  SubMenuItemStyleProps,
} from './types';

export const SidebarWrapper = styled.aside(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  top: 0,
  bottom: 0,
  minHeight: '100%',
  height: '100vh',
  scrollbarWidth: 'none',
  borderRadius: '0 0.5rem 0.5rem 0',
  backgroundColor: theme.palette.customBackground.main,
}));
export const LogoContainer = styled('div')(({ theme }: any) => ({
  padding: '2.25rem 2.083rem 2.25rem 1rem',
  [theme.breakpoints.down('xl')]: {
    padding: '1.25rem 2.083rem 1.25rem 1rem',
    marginBottom: 0,
  },
  marginBottom: '0.625rem',
  div: {
    cursor: 'pointer',
  },
  svg: {
    width: '100%',
  },
}));

export const SidebarMenuList = styled(Grid)(({ theme }) => ({
  paddingLeft: '1rem',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: '0.5rem',
  },
  overflowY: 'hidden',
  overflowX: 'hidden',
  '&:hover': {
    overflowY: 'auto',
    overflowX: 'auto',
  },
  // "::-webkitScrollbar": {
  //   display: "none",
  // },

  '&:hover::-webkit-scrollbar': {
    width: '5px',
  },
  '&:hover::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.scroll,
    borderRadius: '0.625rem',
  },
  'scrollbar-color': theme.palette.customBackground.scroll,
  'scrollbar-width': 'auto',
}));

export const SectionWrapper = styled('div')(
  ({ issection }: SectionWrapperProps) => ({
    ...(issection && {}),
  }),
);
export const MenuContainer = styled(Grid)<MenuContainerProps>(
  ({ active, issection, theme }) => ({
    backgroundColor: active ? theme.palette.background.default : 'transparent',
    borderBottomLeftRadius: '0.5rem',
    borderTopLeftRadius: '0.5rem',
    position: 'relative',
    cursor: !issection ? 'unset' : 'pointer',
    userSelect: 'none',
    padding: '0.625rem',
    [theme.breakpoints.down('xl')]: {
      padding: '0.313rem',
    },
    transition: 'background-color .8s ease-out',
    margin: '2.5px 0',
    ...(!active &&
      issection && {
        ':hover': {
          backgroundColor: theme.palette.hover.primary,
        },
      }),
  }),
);
export const MenuItemWrapper = styled('div')<MenuItemStyleProps>(
  ({ theme, selected, hasChildren = false, info }) => ({
    display: 'flex',
    width: '1.5rem',
    margin: '0rem 0.25rem 0.25rem 0.25rem',
    color: COLORS.btnFocusedColor,
    borderRadius: '0.25rem',
    alignItems: 'center',
    // justifyContent: "center",
    cursor: Boolean(info) && Boolean(info.section) ? 'unset' : 'pointer',
    svg: {
      opacity: 0.4,
    },
    ...(Boolean(selected) && {
      svg: {
        opacity: 1,
      },
      'svg > path, svg > g > path': {
        stroke: COLORS.lightTintRed,
      },
    }),
  }),
);
export const MenuLabelItem = styled('div')<MenuItemProps>(
  ({ info, active, theme }) => ({
    fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '11px',
    letterSpacing: '0.04em',
    flex: 'none',
    // order: "1",
    flexGrow: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: info?.section ?? false ? 'inline-block' : 'inline',
    textOverflow: 'ellipsis',
    color:
      active ?? false
        ? theme.palette.text.primary
        : theme.palette.mode === 'dark'
        ? COLORS.tintGrey
        : COLORS.grey,
    cursor:
      (info?.section ?? false) && Boolean(info?.icon) ? 'unset' : 'pointer',
    fontWeight: active ?? false ? 600 : 500,
  }),
);
export const SubMenuItemWrapper = styled(Grid)<SubMenuItemStyleProps>(
  ({ selected, isshowitems = false, theme }) => ({
    width: '100%',
    display: isshowitems ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // marginBottom: "0.25rem",
    textDecoration: 'none',
    cursor: 'pointer',
    // borderRadius: "0.25rem",
    backgroundColor: selected
      ? theme.palette.background.default
      : 'transparent',
    ...(!selected && {
      '&:hover': {
        color: COLORS.darkGrey,
      },
    }),
  }),
);
export const SubMenuLink = styled(Link)<any>(
  ({ selected, theme, permission }) => ({
    width: '100%',
    fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.875rem' : '11px',
    textDecoration: 'none',
    padding: '0.5rem',
    paddingLeft: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    span: {
      opacity: !permission ? 0.6 : 1,
      paddingLeft: '1.625rem',
      color:
        selected ?? false
          ? theme.palette.text.primary
          : theme.palette.mode === 'dark'
          ? COLORS.tintGrey
          : COLORS.grey,
      // color: selected ? "#FFFFFF" : "rgba(202, 203, 212, 1)",
    },
  }),
);
export const Curved = styled('span')(({ theme }) => {
  return {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '0.625rem',
    backgroundColor: theme.palette.background.default,
  };
});
export const CurvedTop = styled(Curved)`
  top: -0.625rem;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props?.theme.palette.customBackground.main};
    border-bottom-right-radius:0.5rem;
  },
    }
`;

export const CurvedBottom = styled(Curved)`
      bottom: -0.625rem;
      &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${props => props?.theme.palette.customBackground.main};
      border-top-right-radius:0.5rem;
      },
  }
`;
export const Wrapper = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('xxl')]: {
    width: '40% !important',
  },
  [theme.breakpoints.down('xl')]: {
    width: '50% !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: '60% !important',
  },
  [theme.breakpoints.down('md')]: {
    width: '80% !important',
  },
  backgroundColor: theme.palette.neutral.light,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: ' translate(-50%, -50%)',
  borderRadius: '0.5rem',
}));

export const customStyle: any = {
  top: 0,
  right: 0,
  position: 'absolute',
  height: 'auto',
  margin: '5px',
};
export const IconButtonWrapper = styled('div')<any>(({ rotate }) => {
  return {
    float: 'right',
    overflow: 'hidden',
    transition: 'all 0.3s ease-out',
    transform: `${rotate ? `rotate(180deg)` : 'rotate(0deg)'}`,
  };
});
