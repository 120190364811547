import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_BREADCRUMB } from './constant';
import { Breadcrum, CustomLink, CustomTypography } from './style';
import { useSelector } from 'react-redux';
import { getSelectedSiteName } from 'app/features/DashboardSlice/Selectors';
import { ROUTE_PATH } from 'app/pages/Router/constant';

export default function CustomBreadcrumb(): JSX.Element {
  const { pathname } = useLocation();
  const [paths, setPaths] = useState([]);
  const selectedSiteName = useSelector(getSelectedSiteName);
  const theme = useTheme();
  useEffect(() => {
    const pathnames = pathname.split('/').filter(Boolean);
    const pathArray = pathnames.map((path, index) => {
      return {
        path,
        url: `/${pathnames.slice(0, index + 1).join('/')}`,
        label: ROUTE_BREADCRUMB[path],
      };
    });
    const selectedPath: any = [];
    pathArray.forEach((path, i) => {
      if (path.label) {
        selectedPath.push(path);
      } else if (selectedPath.length > 0) {
        selectedPath[selectedPath.length - 1].url = path.url;
      }
    });
    setPaths(selectedPath);
  }, [pathname]);

  useEffect(() => {
    if (selectedSiteName && pathname.includes('/sitemanager')) {
      const selectedPath: any = JSON.parse(JSON.stringify(paths));
      selectedPath.push({
        path: selectedSiteName,
        url: '',
        label: selectedSiteName,
      });
      setPaths(selectedPath);
    }
  }, [selectedSiteName]);

  return (
    <>
      {(paths.length > 1 || pathname !== `/${ROUTE_PATH.DASHBOARD}`) && (
        <Grid container pb={1}>
          <Breadcrum
            separator=">"
            aria-label="breadcrumb"
            theme={theme}
            data-testid="custom-breadcrum"
          >
            {paths.map((path: any, index) =>
              index + 1 === paths.length ? (
                <CustomTypography theme={theme} key={index}>
                  {path.label}
                </CustomTypography>
              ) : (
                <CustomLink theme={theme} key={index} to={path.url}>
                  {path.label}
                </CustomLink>
              ),
            )}
          </Breadcrum>
        </Grid>
      )}
    </>
  );
}
