import { useNavigate } from 'react-router-dom';
import { SidebarWrapper, SidebarMenuList, SectionWrapper } from './styles';
import SidebarHeader from './Header';
import React, { useEffect, useState } from 'react';
import { BASE_ROUTES } from './constants';
import { Grid } from '@mui/material';
import { MenuItem } from './MenuItem';
import { hasPermissionAccess } from 'app/features/AdminSlice/Selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { actions } from 'app/features/AdminSlice/slice';
import { getUserSystems } from 'app/features/GlobalSlice/Selectors';
import { navigateToExternalUrl, uniqueId } from 'utils/CommonFn';
import { INTERFACE_URLS } from 'app/features/Constant';
import { actions as globalActions } from 'app/features/GlobalSlice/slice';
import ModalComponent from 'app/component/core/Modal';
import { ContentModal } from './ContentModal';
import {
  BusinessSecurityContent,
  ManagedNetworkContent,
  BusinessInterfaceContent,
} from 'app/component/common/MarketingTemplate';
import { checkMenusPermission } from 'app/features/authService';
import {
  INACTIVE_SITE_RESTRICT_PATH,
  ROUTE_PATH,
} from 'app/pages/Router/constant';
import { getSiteActiveStatus } from 'app/features/DashboardSlice/Selectors';

export default function SideBar(props: any): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasAccess = useSelector(hasPermissionAccess);
  const hasSystemAccess = useSelector(getUserSystems);
  const siteStatus = useSelector(getSiteActiveStatus, shallowEqual);
  const [hasSystemAccessCount, setHasSystemAccessCount] = useState(0);
  const [, setCheckIsVideoAccess] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [children, setChildren] = useState<any>();
  const onContactSalesClick = (): void => {
    navigateToExternalUrl(INTERFACE_URLS.CONSULTATION);
  };
  const onLearnMoreClick = (value: any): void => {
    onNavigateToExternal(value.route);
  };

  const redirectToModalPopup = (item: any): void => {
    setOpen(true);
    const contentProps = { onContactSalesClick, onLearnMoreClick, item };
    switch (item.route) {
      case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.MANAGED_NETWORK}`:
        setChildren(<ManagedNetworkContent {...contentProps} />);
        break;
      case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.ACCESS_ALARM}`:
        setChildren(<BusinessSecurityContent {...contentProps} />);
        break;
      case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.BUSINESS_INTELLIGENCE}`:
        setChildren(<BusinessInterfaceContent {...contentProps} />);
        break;
    }
  };
  const onNavigateToExternal = (value: string): void => {
    switch (value) {
      case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.MANAGED_NETWORK}`:
        navigateToExternalUrl(INTERFACE_URLS.VOICE);
        break;
      case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.ACCESS_ALARM}`:
        navigateToExternalUrl(INTERFACE_URLS.SYSTEMS);
        break;
      case `/${ROUTE_PATH.PARTNER}/digitalSurveillance`:
        navigateToExternalUrl(INTERFACE_URLS.VIDEO);
        break;
      case `/${ROUTE_PATH.PARTNER}/${ROUTE_PATH.BUSINESS_INTELLIGENCE}`:
        navigateToExternalUrl(INTERFACE_URLS.BUSINESS_INTELLIGENCE);
        break;
    }
  };
  const handleMenuRouting = (item: any): void => {
    if (isSiteActive(item)) {
      const updateDefault = (it: any) => {
        if (it.length === 0) {
          return it;
        } else if (it.length > 0)
          return it.map((o: any) => {
            return {
              ...o,
              isSelected: false,
              elements: updateDefault(o.elements),
            };
          });
      };
      const checkIsActive = (it: any): any => {
        if (it) return it.find((c: any) => c.isSelected);
      };
      const list = menuItems.map((it: any) => {
        if (it.title === item.title) {
          it.isSelected = !item.isSelected;
          if (it.elements.length > 0) {
            const findActive = checkIsActive(it.elements);
            if (!findActive) {
              it.elements[0].isSelected = true;
            } else {
              if (!item.isSelected) it.elements = updateDefault(it.elements);
            }
          }
        } else {
          it.isSelected = false;
          if (it.elements.length > 0) {
            it.elements = it.elements.map((o: any) => {
              o.isSelected = item.isSelected
                ? !item.isSelected
                : o.title === item.title;
              if (o.elementName === item.elementName) {
                it.isSelected = true;
              }
              if (o.elements.length > 0) {
                o.elements = o.elements.map((j: any) => {
                  const active = j.title === item.title;
                  if (active) {
                    o.isSelected = active;
                    it.isSelected = active;
                  }
                  return {
                    ...j,
                    isSelected: j.title === item.title,
                  };
                });
              }
              return { ...o };
            });
          }
        }
        return it;
      });
      setMenuItems(list);

      const validPartnerAccess = (menu: any) => {
        if (hasSystemAccessCount === 0) {
          if (
            menu.route === '/partner/managedNetwork' ||
            menu.route === '/partner/accessAlarm' ||
            menu.route === '/partner/businessIntelligence'
          ) {
            redirectToModalPopup(menu);
          } else {
            navigate(menu.route);
          }
        } else {
          navigate(item.route);
        }
      };
      const menuPermission = checkMenusPermission(item.route);
      if (!menuPermission) {
        dispatch(actions.checkUserAccessPermission(true));
      } else {
        if (item.elements.length === 0) {
          if (item.route === ROUTE_PATH.ONLINE_PAYMENTS) {
            navigateToExternalUrl(INTERFACE_URLS.PAYMENT);
          } else {
            validPartnerAccess(item);
          }
        } else {
          const currentRoute = item.elements.find((c: any) => c.isSelected);
          if (currentRoute) {
            validPartnerAccess(currentRoute);
          }
        }
      }
    }
  };
  const onLogoClick = (): void => {
    navigate(`/${ROUTE_PATH.DASHBOARD}`);
  };
  useEffect(() => {
    dispatch(actions.checkUserAccessPermission(hasAccess));
  }, [hasAccess]);

  useEffect(() => {
    const menuList = BASE_ROUTES.reduce((arr: any, it: any) => {
      if (checkMenusPermission(it.route)) {
        arr.push(it);
      }
      return arr;
    }, []);
    setMenuItems(menuList);
  }, []);

  useEffect(() => {
    if (!hasSystemAccess.loading && hasSystemAccess.data.length === 0) {
      dispatch(globalActions.fetchUserSystems({}));
    }
  }, []);

  useEffect(() => {
    const systemData = hasSystemAccess.data || [];
    if (systemData.length > 0) {
      setHasSystemAccessCount(systemData[0].count);
      setCheckIsVideoAccess(systemData[0].hasVideo);
    }
  }, [hasSystemAccess]);

  const onModalClose = (): any => {
    setOpen(false);
  };
  const isSiteActive = (item: any) => {
    if (siteStatus) return true;
    return !INACTIVE_SITE_RESTRICT_PATH.includes(item.route.replace('/', ''));
  };

  return (
    <>
      <SidebarWrapper theme={props.theme}>
        <SidebarHeader onLogoClick={onLogoClick} />
        <SidebarMenuList theme={props.theme} container rowGap={0}>
          {React.Children.toArray(
            menuItems.map((item: any) => (
              <Grid item xs={12} key={uniqueId()}>
                {item && Boolean(item.section) && (
                  <SectionWrapper issection={item.section}>
                    <MenuItem
                      info={item}
                      handleMenuSelection={handleMenuRouting}
                      siteActive={isSiteActive(item)}
                    />
                  </SectionWrapper>
                )}
              </Grid>
            )),
          )}
        </SidebarMenuList>
      </SidebarWrapper>
      <ModalComponent
        modalOpen={open}
        children={
          <ContentModal children={children} onCancelClick={onModalClose} />
        }
      />
    </>
  );
}
