/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { GlobalLabel, TableCell, TableRow } from './style';
import { useTheme } from '@mui/material/styles';
import { ContactListProps } from './type';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import CustomTooltip from '../Tooltip';
import { IconsButton } from '../Buttons';
import EditIcon from 'assets/component/EditIcon';
import { COLORS } from 'styles/colors';
import { DeleteIcon } from 'assets/component';
import { getUserPermissions } from 'app/features/authService';
import { USER_PERMISSIONS } from 'app/features/Constant';
import { SECURITY_MANAGER } from 'utils/Constants/Content';
import Grid from '@mui/material/Grid';
import { getFormatedPhone, hideString } from 'utils/CommonFn';
import { isEmpty, isOnlyDigits } from 'utils/CommonFn/validators';

function ContactRowItem({
  key,
  rowItem,
  onEditIconClick,
  onDeleteIconClick,
}: ContactListProps): any {
  const theme = useTheme();
  const isContactListPermission = getUserPermissions(
    USER_PERMISSIONS.CONTACT_LIST,
  );
  const isAlarmCodesPermission: any = getUserPermissions(
    USER_PERMISSIONS.ALARM_CODES,
  );
  const getPassword = () => {
    const encrypted = (str: string) => {
      return !isAlarmCodesPermission ? hideString(str) : str;
    };
    try {
      if (!isEmpty(rowItem?.password)) {
        const psswd: any[] = rowItem?.password.split(/\/(.*)/s);
        const passcode = !isEmpty(psswd[0]) ? psswd[0] : '-';
        if (psswd.length === 1) {
          return {
            passcode: isOnlyDigits(passcode) ? encrypted(passcode) : '-',
            password: !isOnlyDigits(passcode) ? encrypted(passcode) : '-',
          };
        } else if (psswd.length > 0) {
          return {
            passcode: encrypted(passcode),
            password: encrypted(psswd[1]) ?? '-',
          };
        }
      } else {
        return { passcode: '-', password: '-' };
      }
    } catch (error) {
      return { passcode: '-', password: '-' };
    }
  };

  return (
    <TableRow container key={key} alignContent="center" theme={theme}>
      <TableCell item xs={1.5}>
        <OverflowTip text={rowItem?.name || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={rowItem?.jobTitle || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={0.5}>
        <OverflowTip text={rowItem?.panelUserId || '-'}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={rowItem?.Mobile ? getFormatedPhone(rowItem?.Mobile) : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip
          text={rowItem?.Home ? getFormatedPhone(rowItem?.Home) : '-'}
        ></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip text={getPassword()?.passcode}></OverflowTip>
      </TableCell>
      <TableCell item xs={2}>
        <OverflowTip text={getPassword()?.password}></OverflowTip>
      </TableCell>
      <TableCell item xs={1}>
        <OverflowTip
          text={
            rowItem?.isGlobal ? (
              <Grid container alignItems={'center'}>
                <GlobalLabel item theme={theme}>
                  Global
                </GlobalLabel>
              </Grid>
            ) : (
              '-'
            )
          }
        ></OverflowTip>
      </TableCell>

      <TableCell item xs={1}>
        <CustomTooltip
          title={
            !isContactListPermission
              ? SECURITY_MANAGER.TEST_PERMISSION_MESSAGE
              : rowItem.isSuspended
              ? 'Suspended'
              : rowItem?.isGlobal
              ? SECURITY_MANAGER.ADMIN_GLOBAL_USER_EDIT
              : 'Edit'
          }
        >
          <div>
            <IconsButton
              name="edit"
              children={<EditIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity:
                  !isContactListPermission ||
                  rowItem.isSuspended ||
                  rowItem?.isGlobal
                    ? 0.6
                    : 1,
              }}
              OnIconClick={() => onEditIconClick(rowItem)}
              isDisabled={
                (!isContactListPermission ||
                  rowItem.isSuspended ||
                  rowItem?.isGlobal) ??
                true
              }
            />
          </div>
        </CustomTooltip>
        <CustomTooltip
          title={
            !isContactListPermission
              ? SECURITY_MANAGER.TEST_PERMISSION_MESSAGE
              : rowItem.isSuspended
              ? 'Suspended'
              : 'Delete'
          }
        >
          <div>
            <IconsButton
              name="delete"
              children={<DeleteIcon />}
              style={{
                background: COLORS.iconBgColor,
                borderRadius: 4,
                marginLeft: '5px',
                opacity:
                  !isContactListPermission || rowItem.isSuspended ? 0.6 : 1,
              }}
              OnIconClick={() => onDeleteIconClick(rowItem)}
              isDisabled={
                (!isContactListPermission || rowItem.isSuspended) ?? true
              }
            />
          </div>
        </CustomTooltip>
      </TableCell>
    </TableRow>
  );
}
export default ContactRowItem;
