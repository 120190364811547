import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';
import { Typography, useMediaQuery } from '@mui/material';

export const CustomList = styled(List)(({ theme }) => ({
  background: theme.palette.customBackground.main,
  '::-webkit-scrollbar': {
    width: '10px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },

  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },
}));

export const CustomMenu = styled(Typography)(({ theme }) => ({
  ':hover': {
    background: theme.palette.primary.main,
    cursor: 'pointer',
  },
  padding: '0.5rem 0 0 0.5rem',
  margin: '0',
  fontSize: useMediaQuery(theme.breakpoints.up('xl'))
    ? '0.875rem !important'
    : '0.75rem !important',
}));
