import { Grid, useTheme } from '@mui/material';
import { IconsButton } from 'app/component/core/Buttons';
import { Cancel } from 'assets/component';
import { Wrapper, MessageContainer } from './style';

export function PreviewModel({
  data,
  style = {},
  onCancelClick,
}: any): JSX.Element {
  const theme = useTheme();
  return (
    <>
      <Wrapper container item theme={theme} sx={style}>
        <Grid container flexDirection={'column'} xs={12} p={2}>
          <Grid
            item
            mb={1}
            pl={2}
            sx={{ justifyContent: 'space-between', display: 'flex' }}
          >
            {data.Title}
            <IconsButton
              name={'close'}
              children={<Cancel />}
              OnIconClick={onCancelClick}
              style={{ alignItems: 'flex-start' }}
            />
          </Grid>
          <MessageContainer item theme={theme}>
            {data.Message}
          </MessageContainer>
        </Grid>
      </Wrapper>
    </>
  );
}
