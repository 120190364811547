import { useTheme } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled('div')(({ theme }) => ({
  width: '100% !important',
  height: '100% !important',
  backgroundColor: theme.palette.neutral.main,
  position: 'fixed',
  top: '80px',
}));

export function MobileMenuModel({ style = {}, Children }: any): JSX.Element {
  const theme = useTheme();
  return (
    <Wrapper theme={theme} {...style}>
      {Children}
    </Wrapper>
  );
}
