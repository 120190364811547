import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import CustomMaps from 'app/component/core/Maps';
import {
  getDashboardFilterData,
  getViewType,
  getFilterServiceTripData,
  getSiteManager,
  getDashboardFilter,
  getCompanyId,
  getResolvedEvents,
  getDashboardListPageNo,
} from 'app/features/DashboardSlice/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  SERVICE_TRIPS_HEADER_SITE_MANAGER,
  SORT_BY,
} from 'utils/Constants/index';
import { FilterMenu } from 'app/component/core/Filter';
import { Listview } from 'app/component/core/CustomListView';
import { actions } from 'app/features/DashboardSlice/slice';
import { PanelGrid } from '../../styles';
import { ClearIcon, RoundTick } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import CustomTooltip from 'app/component/core/Tooltip';
import { useNavigate } from 'react-router';
import CustomInput from 'app/component/core/CustomInputs/customInputsDashboard';
import { MultipleSelectCheckmarks } from 'app/component/core/CustomSelectWithoutLable/multiselectCheckbox';
import { COLORS } from 'styles/colors';
import {
  checkIEVersion,
  customListSearch,
  getFilterData,
  manageDropdownDataForIE,
  useMobileDevice,
} from 'utils/CommonFn';
import { CustomMultiselectIE } from 'app/component/core/CustomMultiselectIE';
import { isEmpty } from 'utils/CommonFn/validators';
import LocationFilter from 'app/component/common/LocationFilter';
import { persistSiteInfoId } from 'app/features/authService';
import { CONTEXT } from 'utils/Constants/Content';
import { ROUTE_PATH } from 'app/pages/Router/constant';

function ServiceTrips({
  isLocation = true,
  isSort = false,
  source = CONTEXT.DASHBOARD,
}: any): JSX.Element {
  const themes = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getFilters = useSelector(getDashboardFilterData);
  const selectorServiceTripState: any =
    getFilters?.data?.serviceTrip?.[0] ?? [];
  const selectorServiceTripData: any = useSelector(getFilterServiceTripData);
  const isSiteManager: any = useSelector(getSiteManager);
  const isResolvedEvents: any = useSelector(getResolvedEvents);

  const viewType = useSelector(getViewType);
  const selectedCompanyId = useSelector(getCompanyId);
  const selectedView = isSiteManager ? 'list' : viewType;
  const isMobile = useMobileDevice();
  const serviceTrips = selectorServiceTripState.st_status || [];
  const MapViewData = selectorServiceTripData.mapData || [];
  const ListViewData = selectorServiceTripData.listData || [];
  const TotalListCount = selectorServiceTripData.recordCount || 0;
  const [status] = useState(
    serviceTrips && serviceTrips.length > 0 ? serviceTrips : [],
  );
  const dashboardPageNo = useSelector(getDashboardListPageNo);
  const [currentPage, setCurrentPage] = useState(dashboardPageNo);
  const dashboardFilter: any = useSelector(getDashboardFilter);
  const { locationValue, statusValue, workOrderNo, sortColumn, sortBy } =
    dashboardFilter;
  const sortObject = { sortColumn: '', sortBy: '' };

  const defaultDashboardFilter = (): any => {
    let reqObj: any = {
      page: dashboardPageNo,
      recordCountRequire: selectedView === 'list',
    };
    if (!isEmpty(sortBy)) {
      reqObj.sortBy = sortBy;
    }
    if (!isEmpty(sortColumn)) {
      reqObj.sortColumn = sortColumn;
    }
    if (statusValue.length > 0) {
      reqObj.stStatus = customListSearch(statusValue, status);
    }
    if (workOrderNo.length > 0) {
      reqObj.woNumber = workOrderNo;
    }
    if (locationValue.isFilterApplied) {
      reqObj = { ...reqObj, ...getFilterData(locationValue) };
    }
    return reqObj;
  };
  const [defaultValue, setDefaultValue] = useState({
    page: 1,
    recordCountRequire: selectedView === 'list',
    ...defaultDashboardFilter(),
  });

  const statusOptionIE: any = manageDropdownDataForIE(serviceTrips);
  const [selectedStatusIE, setSelectedStatusIE] = useState(statusValue ?? []);

  const clearSort = () => {};

  const handleChangeStatus = (e: any): void => {
    const selectedVal = e.target.value || '';
    dispatch(actions.updateResolvedEventsActive(false));
    const newValue = selectedVal.map((val: any) => {
      const obj = status.find((o: any) => o.key === val);
      return obj.value;
    });
    const selectedType = { stStatus: newValue };
    if (selectedVal) {
      dispatch(
        actions.updateDashboardFilter({
          statusValue: selectedVal,
          ...sortObject,
        }),
      );
      clearSort();
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };
  const onServiceTripHandleClick = (caseNumber: any) => {
    navigate(`tripdetail/${caseNumber}`);
  };
  const onFilterMenuClick = (keyName: any) => {
    const sortObj =
      sortColumn && keyName === 'list' ? { sortColumn, sortBy } : {};
    updateRecord({ recordCountRequire: keyName === 'list', ...sortObj }, true);
  };

  const updateRecord = (params?: any, isMenuClick = false) => {
    if (selectedCompanyId) {
      dispatch(
        actions.fetchFilterServiceTrip({
          ...defaultValue,
          ...params,
        }),
      );
    }
  };

  const onPagination = (pageNo: any) => {
    setCurrentPage(pageNo);
    dispatch(actions.updateDashboardListPageNo(pageNo));
    const reqObj: any = { page: pageNo };
    if (sortBy) {
      reqObj.sortColumn = sortColumn;
      reqObj.sortBy = sortBy;
    }
    updateRecord(reqObj);
  };
  const onFilterClear = () => {
    const defaultSelction = ['Pending', 'Scheduled', 'Started'];
    if (selectedCompanyId) {
      setDefaultValue({
        page: 1,
        recordCountRequire: selectedView === 'list',
      });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(
        actions.updateDashboardFilter({
          statusValue: defaultSelction,
          workOrderNo: '',
          locationValue: {
            address: '',
            city: '',
            siteName: '',
            state: [],
            zipcode: '',
          },
          ...sortObject,
        }),
      );
      clearSort();
      dispatch(actions.updateResolvedEventsActive(false));
      const newValue = defaultSelction.map((val: any) => {
        const obj = status.find((o: any) => o.key === val);
        return obj.value;
      });
      const selectedType = { stStatus: newValue };
      dispatch(
        actions.fetchFilterServiceTrip({
          page: 1,
          recordCountRequire: selectedView === 'list',
          ...selectedType,
        }),
      );
      dispatch(actions.updateViewType(selectedView));
      if (checkIEVersion()) {
        setSelectedStatusIE([]);
      }
    }
  };
  const handleChangeResolvedevents = () => {
    if (status && status.length > 0) {
      let selectedVal = ['Complete'];
      if (isResolvedEvents) {
        selectedVal = ['Pending', 'Scheduled', 'Started'];
        dispatch(actions.updateResolvedEventsActive(false));
      } else {
        dispatch(actions.updateResolvedEventsActive(true));
      }
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      clearSort();
      const newValue = selectedVal.map((val: any) => {
        const obj = status.find((o: any) => o.key === val);
        return obj.value;
      });
      const selectedType = { stStatus: newValue, page: 1 };
      if (selectedVal) {
        let selectedValues = [];
        if (checkIEVersion()) {
          selectedValues = statusOptionIE.filter((o: any) =>
            selectedVal.includes(o.label),
          );
          setSelectedStatusIE(selectedValues);
        } else {
          selectedValues = selectedVal;
        }
        dispatch(
          actions.updateDashboardFilter({
            statusValue: selectedValues,
            ...sortObject,
          }),
        );
        clearSort();
        setDefaultValue({ ...defaultValue, ...selectedType });
        setCurrentPage(1);
        dispatch(actions.updateDashboardListPageNo(1));
        updateRecord(selectedType);
      }
    }
  };

  const onSearchClick = (e: any) => {
    if (e === 'Work Order Number' && workOrderNo) {
      const selectedType = {
        woNumber: workOrderNo,
      };
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(actions.updateDashboardFilter({ ...sortObject }));
      setDefaultValue({ ...defaultValue, ...selectedType });
      updateRecord({ woNumber: workOrderNo });
    } else {
      try {
        if ('woNumber' in defaultValue) {
          delete defaultValue.woNumber;
          updateRecord({ ...defaultValue, page: currentPage });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onTextChange = (e: any) => {
    const searchText = e.target.value;
    dispatch(actions.updateDashboardFilter({ workOrderNo: searchText }));
    clearSort();
    if ('woNumber' in defaultValue) {
      delete defaultValue.woNumber;
    }
    if (isEmpty(searchText)) {
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      dispatch(actions.updateDashboardFilter({ ...sortObject }));
      updateRecord({ ...defaultValue, page: 1 });
    }
  };

  const handleChangeStatusIE = (item: any): void => {
    setSelectedStatusIE(item);
    const newValue = item.map((o: any) => o.value);
    const selectedType = { stStatus: newValue };
    if (item) {
      dispatch(actions.updateResolvedEventsActive(false));
      dispatch(
        actions.updateDashboardFilter({ statusValue: item, ...sortObject }),
      );
      clearSort();
      setDefaultValue({ ...defaultValue, ...selectedType });
      setCurrentPage(1);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord(selectedType);
    }
  };

  const onSortClick = (e: any, item: any): void => {
    const sortObj: any = {};
    if (item.sortColumn === sortColumn) {
      sortObj.sortColumn = sortColumn;
      const sort = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC;
      sortObj.sortBy = sort;
    } else {
      sortObj.sortColumn = item.sortColumn;
      sortObj.sortBy = SORT_BY.ASC;
    }
    dispatch(actions.updateDashboardFilter(sortObj));
    updateRecord(sortObj);
  };
  useEffect(() => {
    const obj = {
      page: currentPage,
      recordCountRequire: selectedView === 'list',
      ...defaultDashboardFilter(),
    };
    setDefaultValue(obj);
  }, [selectedView]);

  const applyfilter = (value: any) => {
    if (value) {
      dispatch(actions.updateDashboardFilter(value));
      const data: any = getFilterData(value.locationValue);
      dispatch(actions.updateDashboardListPageNo(1));
      updateRecord({ ...data, page: 1 });
    }
  };
  const onSiteHandleClick = (e: any, siteId: any) => {
    if (siteId) {
      e.preventDefault();
      persistSiteInfoId(siteId);
      navigate(`/dashboard/sitemanager/${siteId}`);
    }
  };
  const onServiceDetailsClick = (value: any) => {
    if (value) {
      navigate(
        {
          pathname: `/${ROUTE_PATH.DASHBOARD}/${ROUTE_PATH.SERVICE_REQUEST_DETAILS}`,
        },
        { state: value },
      );
    }
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item container xs={12} sm={12} md={10} spacing={2}>
            {isLocation && (
              <Grid item xs={6} md={3}>
                <LocationFilter onfilter={applyfilter} />
              </Grid>
            )}
            <Grid item xs={6} md={3}>
              <CustomInput
                label="Work Order Number"
                onIconClick={onSearchClick}
                onTextChange={onTextChange}
                value={workOrderNo}
                autofocus={workOrderNo.length > 0}
              />
            </Grid>
            {!checkIEVersion() ? (
              <Grid item xs={6} md={3}>
                <MultipleSelectCheckmarks
                  value={statusValue}
                  handleChange={handleChangeStatus}
                  options={status}
                  placeholder="ST Status"
                />
              </Grid>
            ) : (
              <Grid item xs={6} md={3}>
                <CustomMultiselectIE
                  key={'st_status'}
                  value={selectedStatusIE}
                  handleChange={handleChangeStatusIE}
                  options={statusOptionIE}
                  placeholder="ST Status"
                />
              </Grid>
            )}
            <Grid item xs={1} sx={{ marginTop: '1px' }}>
              {!isMobile && (
                <IconsButton
                  name={'ClearFilter'}
                  children={<ClearIcon color={themes.palette.icon.main} />}
                  style={{
                    background: themes.palette.primary.main,
                    borderRadius: 4,
                    padding: '6px',
                  }}
                  OnIconClick={onFilterClear}
                  title={CONTEXT.CLEAR_FILTER}
                  isShowTooltip={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={2} justifyContent="flex-end">
            {isMobile && (
              <IconsButton
                name={'ClearFilter'}
                children={<ClearIcon color={themes.palette.icon.main} />}
                style={{
                  background: themes.palette.primary.main,
                  borderRadius: 4,
                  padding: '0.35rem',
                }}
                OnIconClick={onFilterClear}
                title={CONTEXT.CLEAR_FILTER}
                isShowTooltip={true}
              />
            )}
            <FilterMenu onhandleChange={onFilterMenuClick} />
            <CustomTooltip title={'Resolved Events'} key={Math.random()}>
              <div>
                <IconsButton
                  style={{
                    background: isResolvedEvents
                      ? COLORS.lightRed
                      : COLORS.white,
                    borderRadius: 4,
                    marginLeft: '0.5rem',
                  }}
                  name={'ResolvedEvents'}
                  children={
                    <RoundTick
                      color={isResolvedEvents ? COLORS.white : COLORS.royalBlue}
                    />
                  }
                  OnIconClick={handleChangeResolvedevents}
                />
              </div>
            </CustomTooltip>
          </Grid>
        </Grid>

        <PanelGrid container item pt={1} theme={themes}>
          {selectedView === 'map' && !isSiteManager && (
            <CustomMaps
              markerResponseData={
                MapViewData && MapViewData.length > 0 ? MapViewData : []
              }
              type={CONTEXT.SERVICE_TRIP}
              loading={selectorServiceTripData.loading}
            />
          )}
          {selectedView === 'list' && (
            <Listview
              headerItems={SERVICE_TRIPS_HEADER_SITE_MANAGER}
              name={CONTEXT.SERVICE_TRIP}
              listData={
                ListViewData && ListViewData.length > 0 ? ListViewData : []
              }
              totalRecords={TotalListCount}
              onPageChange={onPagination}
              currentPage={currentPage}
              loading={selectorServiceTripData.loading}
              onServiceTripEditHandle={onServiceTripHandleClick}
              CustomHeight={500}
              isSort={isSort}
              onSortClick={onSortClick}
              onSiteHandle={onSiteHandleClick}
              onServiceDetailsClick={onServiceDetailsClick}
              source={source}
            />
          )}
        </PanelGrid>
      </Box>
    </>
  );
}

export default React.memo(ServiceTrips);
