import { apiInstance } from '../apiInstance';
import { API_POINTS } from '../Constant';

export const fetchSecurityManagerSiteInfo = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SECURITY_SITE_INFO, { params: payload })
    .then(res => res.data);
};
export const createPutSiteOnTest = async (payload: any) => {
  return await apiInstance()
    .put(
      `${API_POINTS.PUT_SITE_ON_TEST}?siteId=${payload.siteId}`,
      payload.body,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
export const deleteSiteOnTest = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.PUT_SITE_ON_TEST, { params: payload })
    .then(res => res.data);
};
export const fetchCallContactList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SECURITY_CALL_CONTACT_LIST, { params: payload })
    .then(res => res.data);
};
export const updateCallList = async (payload: any) => {
  return await apiInstance().put(
    `${API_POINTS.SECURITY_UPDATE_CALL_LIST}?siteId=${payload.siteId}`,
    payload.body,
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );
};

export const getContactDetails = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_GET_CONTACT_DETAILS, { params: payload })
    .then(res => res.data);
};
export const postAddContact = async (payload: any, siteId: any) => {
  return await apiInstance()
    .post(API_POINTS.SM_POST_CONTACT_LIST, payload, {
      params: { siteId },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const deleteContact = async (payload: any, params?: any) => {
  return await apiInstance()
    .delete(`${API_POINTS.SM_DELETE_CONTACT_LIST}/${payload}`, {
      params,
    })
    .then(res => res.data);
};
export const editContact = async (payload: any) => {
  const siteId = payload.siteId;
  return await apiInstance()
    .put(API_POINTS.SM_PUT_CONTACT_LIST, payload, {
      params: { siteId },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const sendPasscodeEmail = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.SM_SEND_PASSCODE_EMAIL, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const getCallContactList = async (payload: any) => {
  return await apiInstance()
    .get(`${API_POINTS.SM_CALL_LIST_CODE}/${payload.code}`, {})
    .then(res => res.data);
};

export const getAdminSecurityConfig = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_GET_ADMIN_CONFIG, { params: payload })
    .then(res => res.data);
};

export const updateAdminSecurityConfig = async (payload: any) => {
  return await apiInstance()
    .patch(
      `${API_POINTS.SM_UPDATE_ADMIN_COMPANY_CONFIG}?companyId=${payload.partyNumber}`,
      payload,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};

export const updateAdminSiteSecurityConfig = async (payload: any) => {
  const siteId = payload.SiteId;
  delete payload.SiteId;
  return await apiInstance()
    .patch(
      `${API_POINTS.SM_UPDATE_ADMIN_SITE_CONFIG}?siteId=${siteId}`,
      payload,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};

export const getCompanyProfile = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.COMPANY_PROFILE, { params: payload })
    .then(res => res.data);
};
// Security manager activity log types
export const getSMActivityLogTypes = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_ACTIVITY_LOGS_TYPES, { params: payload })
    .then(res => res.data);
};
// Security manager activity logs
export const getSMActivityLog = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  if (payload.mode === 'export') requestHeaders.responseType = 'blob';
  return await apiInstance()
    .post(API_POINTS.SM_ACTIVITY_LOGS, payload, requestHeaders)
    .then(res => res);
};
export const getSecurityManagerUser = async (payload: any) => {
  return await apiInstance()
    .post(API_POINTS.SECURITY_MANAGER_USER, payload, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.data);
};
export const editSecurityManagerUser = async (payload: any) => {
  const requestHeaders: any = {
    headers: { 'Content-Type': 'application/json' },
  };
  return await apiInstance()
    .put(API_POINTS.SECURITY_MANAGER_USER, payload, requestHeaders)
    .then(res => res);
};
export const deleteSecurityManagerUser = async (payload: any) => {
  return await apiInstance()
    .delete(API_POINTS.SECURITY_MANAGER_USER, { params: payload })
    .then(res => res);
};
export const getSecurityManagerZoneList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_ZONE_LIST, { params: payload })
    .then(res => res.data);
};
export const getSecurityManagerUserAlarmList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SM_ALARM_SEARCH, { params: payload })
    .then(res => res.data);
};
export const getSecurityManagerUserSiteGroupList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.SECURITY_MANAGER_SITE_GROUP, { params: payload })
    .then(res => res.data);
};
export const getUserAffectedSiteList = async (payload: any) => {
  return await apiInstance()
    .get(API_POINTS.GET_GLOBAL_USER_SITES, { params: payload })
    .then(res => res.data);
};

export const deleteGlobalUser = async (payload: any) => {
  return await apiInstance()
    .post(
      API_POINTS.DELETE_GLOBAL_USER,
      { ...payload },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(res => res.data);
};
