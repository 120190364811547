import { Grid, useMediaQuery } from '@mui/material';
import { IconsButton } from 'app/component/core/Buttons';
import { ActiveCardContainer, ServiceValueContainer } from './styles';
import InfoCircleIcon from 'assets/component/InfoCircleIcon';
import { CONTEXT, DISPLAY_TEXT } from 'utils/Constants/Content';
import { useTheme } from '@mui/material/styles';
interface Request {
  label: string;
  value: string;
  onÌconClick?: any;
}

export function ServiceCardItem({
  label,
  value,
  onÌconClick,
}: Request): JSX.Element {
  const theme = useTheme();
  const isSmallerScreen: boolean = useMediaQuery(theme.breakpoints.down('xl'));

  const description =
    label === CONTEXT.EVENT
      ? `${DISPLAY_TEXT.ACTIVE_SECURITY_EVENTS}`
      : label === CONTEXT.DEVICE_OFFLINE
      ? `${DISPLAY_TEXT.FUTURE_ENHANCEMENT} - ${DISPLAY_TEXT.DEVICE_QUICK_STAT_DESC}`
      : '';
  return (
    <ActiveCardContainer>
      <Grid
        container
        sx={{
          alignItems: 'center',
          fontSize: isSmallerScreen ? '0.75rem' : '0.875rem',
          fontWeight: 600,
        }}
      >
        <Grid item xs={9}>
          {label}
        </Grid>
        <Grid item xs={3}>
          <IconsButton
            name={label}
            children={<InfoCircleIcon />}
            OnIconClick={onÌconClick}
            isShowTooltip={
              label === CONTEXT.EVENT || label === CONTEXT.DEVICE_OFFLINE
            }
            title={description}
          />
        </Grid>
      </Grid>
      <ServiceValueContainer item theme={theme}>
        {value}
      </ServiceValueContainer>
    </ActiveCardContainer>
  );
}
