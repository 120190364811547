import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { SecurityManagerState } from './types';
import { ToastAction } from 'app/component/core/ToastAlert/types';
import { CONTEXT } from 'utils/Constants/Content';
import { uniqueId } from 'utils/CommonFn';

export const initialState: SecurityManagerState = {
  securitySiteInfo: {
    loading: false,
    data: [],
    status: '',
  },
  siteOnTest: {
    loading: false,
    status: '',
    data: [],
  },
  deleteSiteTest: {
    loading: false,
    status: '',
    data: [],
  },
  securityCallContactInfo: {
    loading: false,
    data: [],
  },
  callContactList: {
    loading: false,
    data: [],
  },
  putCallList: {
    loading: false,
    status: '',
    data: [],
  },
  userCallList: [],
  userContactList: [],
  toastInfo: {
    showToast: false,
    toastMessage: '',
    toastType: undefined,
  },
  contactList: {
    loading: false,
    data: [],
    status: '',
  },
  contactDetails: {
    loading: false,
    data: [],
    status: '',
  },
  addContact: {
    loading: false,
    data: [],
  },
  selectedSiteName: '',
  passcodeEmail: {
    loading: false,
    status: '',
    data: [],
  },
  adminSecurityConfig: {
    loading: false,
    data: [],
  },
  updateSecurityConfig: {
    loading: false,
    status: '',
    data: [],
  },
  securityManagerJobRole: { jobRoleList: [], lockList: false },
  securityManagerRestrictedPassword: [],
  securityManagerJobTitle: [],
  securityManagerAdminConfig: {
    loading: false,
    data: [],
  },
  putsecurityManagerAdminConfig: {
    loading: false,
    status: '',
    data: [],
  },
  putSiteSecurityManagerAdminConfig: {
    loading: false,
    status: '',
    data: [],
  },
  companyProfile: {
    loading: false,
    data: [],
  },
  securityManagerActivityLogTypes: {
    loading: false,
    data: [],
  },
  securityManagerActivityLog: {
    loading: false,
    data: [],
  },
  securityManagerActivityLogExport: {
    loading: false,
    data: [],
  },
  securityManagerUser: {
    loading: false,
    data: [],
  },
  updateSecurityManagerUser: {
    loading: false,
    data: [],
    status: '',
  },
  deleteSecurityManagerUser: {
    loading: false,
    data: [],
    status: '',
  },
  securityManagerZoneList: {
    loading: false,
    data: [],
  },
  securityManagerAlarmList: {
    loading: false,
    data: [],
  },
  securityManagerSiteGroupList: {
    data: [],
    loading: false,
  },
  selectedSiteGroup: null,
  globalUserAffectedSite: {
    data: [],
    loading: false,
  },
  deleteGlobalUser: {
    data: [],
    loading: false,
    status: '',
  },
  securityManagerUserFilter: {
    page: 1,
    selectedFilter: {
      id: 1,
      label: 'Employee Name',
      value: 'employeeName',
      isChecked: true,
    },
    searchedText: '',
    globalOnly: false,
  },
};

const SecurityManagerSlice = createSlice({
  name: 'securityManager',
  initialState,
  reducers: {
    fetchSecuritySiteInfo(state, action: PayloadAction<any>) {
      state.securitySiteInfo = {
        ...state.securitySiteInfo,
        loading: true,
      };
    },
    updateSecuritySiteInfo(state, action: PayloadAction<any>) {
      state.securitySiteInfo = {
        ...state.securitySiteInfo,
        ...action.payload,
      };
    },
    putSiteOnTest(state, action: PayloadAction<any>) {
      state.siteOnTest = {
        ...state.siteOnTest,
        status: 'pending',
        loading: true,
      };
    },
    updatePutSiteOnTest(state, action: PayloadAction<any>) {
      state.siteOnTest = {
        ...state.siteOnTest,
        ...action.payload,
      };
    },
    deleteSiteTest(state, action: PayloadAction<any>) {
      state.deleteSiteTest = {
        ...state.deleteSiteTest,
        status: 'pending',
        loading: true,
      };
    },
    updateDeleteSiteTest(state, action: PayloadAction<any>) {
      state.deleteSiteTest = {
        ...state.deleteSiteTest,
        ...action.payload,
      };
    },
    displayToast(state, action: PayloadAction<ToastAction>) {
      state.toastInfo.showToast = true;
      state.toastInfo.toastMessage = action.payload.toastMessage;
      state.toastInfo.toastType = action.payload.toastType;
    },
    fetchSecurityManagerCallContactList(state, action: PayloadAction<any>) {
      state.securityCallContactInfo = {
        ...state.securityCallContactInfo,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerCallContactList(state, action: PayloadAction<any>) {
      state.securityCallContactInfo = {
        ...state.securityCallContactInfo,
        ...action.payload,
      };
    },
    putSecurityManagerCallList(state, action: PayloadAction<any>) {
      state.putCallList = {
        ...state.putCallList,
        data: [],
        status: 'pending',
        loading: true,
      };
    },
    updatePutSecurityManagerCallList(state, action: PayloadAction<any>) {
      state.putCallList = {
        ...state.putCallList,
        ...action.payload,
      };
    },
    updateUserCallList(state, action: PayloadAction<any>) {
      state.userCallList = [...action.payload];
    },
    updateUserContactList(state, action: PayloadAction<any>) {
      state.userContactList = [...action.payload];
    },
    fetchAddContact(state, action: PayloadAction<any>) {
      state.addContact = { ...state.addContact, loading: true };
    },
    updateCreatedContact(state, action: PayloadAction<any>) {
      state.addContact = {
        ...state.addContact,
        ...action.payload,
      };
    },
    fetchDeleteContact(state, action: PayloadAction<any>) {
      state.contactList = { ...state.contactList, loading: true };
    },
    updateDeleteContact(state, action: PayloadAction<any>) {
      state.contactList = {
        ...state.contactList,
        ...action.payload,
      };
    },
    fetchEditContact(state, action: PayloadAction<any>) {
      state.addContact = { ...state.addContact, loading: true };
    },
    updateEditContact(state, action: PayloadAction<any>) {
      state.addContact = {
        ...state.addContact,
        ...action.payload,
      };
    },
    fetchContactDetails(state, action: PayloadAction<any>) {
      state.contactDetails = {
        ...state.contactDetails,
        loading: true,
        status: '',
      };
    },
    updateContactDetails(state, action: PayloadAction<any>) {
      state.contactDetails = {
        ...state.contactDetails,
        ...action.payload,
      };
    },
    sendPasscodeEmail(state, action: PayloadAction<any>) {
      state.passcodeEmail = {
        ...state.passcodeEmail,
        status: CONTEXT.PENDING,
        loading: true,
      };
    },
    updatePasscodeEmail(state, action: PayloadAction<any>) {
      state.passcodeEmail = {
        ...state.passcodeEmail,
        ...action.payload,
      };
    },
    updateSelectedSecuritySite(state, action: PayloadAction<any>) {
      state.selectedSiteName = action.payload;
    },
    fetchCallContactList(state, action: PayloadAction<any>) {
      state.callContactList = {
        ...state.callContactList,
        data: [],
        loading: true,
      };
    },
    updateCallContactList(state, action: PayloadAction<any>) {
      state.callContactList = {
        ...state.callContactList,
        ...action.payload,
      };
    },
    updateSecurityManagerJobRole(state, action: PayloadAction<any>) {
      state.securityManagerJobRole = action.payload;
    },
    updateSecurityManagerRestrictedPassword(state, action: PayloadAction<any>) {
      state.securityManagerRestrictedPassword = action.payload;
    },
    updateSecurityManagerJobTitle(state, action: PayloadAction<any>) {
      state.securityManagerJobTitle = action.payload;
    },
    fetchSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.securityManagerAdminConfig = {
        ...state.securityManagerAdminConfig,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.securityManagerAdminConfig = {
        ...state.securityManagerAdminConfig,
        ...action.payload,
      };
    },
    putAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putsecurityManagerAdminConfig = {
        ...state.putsecurityManagerAdminConfig,
        data: [],
        status: 'pending',
        loading: true,
      };
    },
    updateAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putsecurityManagerAdminConfig = {
        ...state.putsecurityManagerAdminConfig,
        ...action.payload,
      };
    },
    putSiteAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putSiteSecurityManagerAdminConfig = {
        ...state.putSiteSecurityManagerAdminConfig,
        data: [],
        status: 'pending',
        loading: true,
      };
    },
    updateSiteAdminSecurityManagerConfig(state, action: PayloadAction<any>) {
      state.putSiteSecurityManagerAdminConfig = {
        ...state.putSiteSecurityManagerAdminConfig,
        ...action.payload,
      };
    },
    fetchCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        data: [],
        loading: true,
      };
    },
    updateCompanyProfile(state, action: PayloadAction<any>) {
      state.companyProfile = {
        ...state.companyProfile,
        ...action.payload,
      };
    },
    fetchSecurityManagerActivityLogTypes(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogTypes = {
        ...state.securityManagerActivityLogTypes,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerActivityLogTypes(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogTypes = {
        ...state.securityManagerActivityLogTypes,
        ...action.payload,
      };
    },
    fetchSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLog = {
        ...state.securityManagerActivityLog,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLog = {
        ...state.securityManagerActivityLog,
        ...action.payload,
      };
    },
    exportSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogExport = {
        ...state.securityManagerActivityLogExport,
        data: [],
        loading: true,
      };
    },
    updateExportSecurityManagerActivityLog(state, action: PayloadAction<any>) {
      state.securityManagerActivityLogExport = {
        ...state.securityManagerActivityLogExport,
        ...action.payload,
      };
    },
    fetchSecurityManagerUser(state, action: PayloadAction<any>) {
      state.securityManagerUser = {
        ...state.securityManagerUser,
        data: [],
        loading: true,
        recordCount: 0,
      };
    },
    updateSecurityManagerUserList(state, action: PayloadAction<any>) {
      state.securityManagerUser = {
        ...state.securityManagerUser,
        ...action.payload,
      };
    },
    putSecurityManagerUser(state, action: PayloadAction<any>) {
      state.updateSecurityManagerUser = {
        ...state.updateSecurityManagerUser,
        data: [],
        loading: true,
      };
    },
    updatePutSecurityManageruser(state, action: PayloadAction<any>) {
      state.updateSecurityManagerUser = {
        ...state.updateSecurityManagerUser,
        ...action.payload,
      };
    },
    deleteSecurityManagerUser(state, action: PayloadAction<any>) {
      state.deleteSecurityManagerUser = {
        ...state.deleteSecurityManagerUser,
        data: [],
        loading: true,
      };
    },
    updateDeleteSecurityManagerUser(state, action: PayloadAction<any>) {
      state.deleteSecurityManagerUser = {
        ...state.deleteSecurityManagerUser,
        ...action.payload,
      };
    },
    fetchSecurityManagerZoneList(state, action: PayloadAction<any>) {
      state.securityManagerZoneList = {
        ...state.securityManagerZoneList,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerZoneList(state, action: PayloadAction<any>) {
      state.securityManagerZoneList = {
        ...state.securityManagerZoneList,
        ...action.payload,
      };
      if (state.securityManagerZoneList?.data != null) {
        state.securityManagerZoneList.data =
          state.securityManagerZoneList?.data.map((item: any) => {
            return {
              isDisabled: false,
              ...item,
              status: '',
              uniqId: uniqueId(),
            };
          });
      }
    },
    fetchSecurityManagerAlarmList(state, action: PayloadAction<any>) {
      state.securityManagerAlarmList = {
        ...state.securityManagerAlarmList,
        data: [],
        loading: true,
      };
    },
    updateSecurityManagerAlarmList(state, action: PayloadAction<any>) {
      state.securityManagerAlarmList = {
        ...state.securityManagerAlarmList,
        ...action.payload,
      };
    },
    fetchSMUserSiteGroupList(state, action: PayloadAction<any>) {
      state.securityManagerSiteGroupList = {
        ...state.securityManagerSiteGroupList,
        loading: true,
      };
    },
    updateSMUserSiteGroupList(state, action: PayloadAction<any>) {
      state.securityManagerSiteGroupList = {
        ...state.securityManagerSiteGroupList,
        ...action.payload,
      };
    },
    updateSelectedSiteGroup(state, action: PayloadAction<any>) {
      state.selectedSiteGroup = {
        ...state.selectedSiteGroup,
        ...action.payload,
      };
    },
    updateSMUserFilter(state, action: PayloadAction<any>) {
      state.securityManagerUserFilter = {
        ...state.securityManagerUserFilter,
        ...action.payload,
      };
    },
    clearSelectedSiteGroup(state, action: PayloadAction<any>) {
      state.selectedSiteGroup = null;
    },
    fetchGlobalUserAffectedSite(state, action: PayloadAction<any>) {
      state.globalUserAffectedSite = {
        ...state.globalUserAffectedSite,
        loading: true,
      };
    },
    updateGlobalUserAffectedSite(state, action: PayloadAction<any>) {
      state.globalUserAffectedSite = {
        ...state.globalUserAffectedSite,
        ...action.payload,
      };
    },
    fetchDeleteGlobalUser(state, action: PayloadAction<any>) {
      state.deleteGlobalUser = {
        ...state.deleteGlobalUser,
        status: CONTEXT.PENDING,
        loading: true,
      };
    },
    updateDeleteGlobalUser(state, action: PayloadAction<any>) {
      state.deleteGlobalUser = {
        ...state.deleteGlobalUser,
        ...action.payload,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = SecurityManagerSlice;
