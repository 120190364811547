export const IsMatchRoute: any = [
  'Not Found',
  'Login',
  'Logout',
  'TermsCondition',
];

export const ROUTE_PATH: any = {
  HASH: '/',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  SITE_MANAGER: 'sitemanager/:siteId',
  ANALYTICS: 'sitemanager/:siteId/analytics',
  SECURITY_MANAGER_LANDING: 'securityManager',
  SECURITY_MANAGER: 'sitemanager/:siteId/securityManager',
  ADD_CONTACT: 'sitemanager/:siteId/securityManager/addContact',
  EDIT_CONTACT: 'sitemanager/:siteId/securityManager/editContact/:contactId',
  SITE_MANAGER_CASE_DETAILS: 'sitemanager/:siteId/details',
  CASE_DETAILS: 'details',
  SERVICE_REQUEST_DETAILS: 'srDetails',
  SITE_MANAGER_TRIP_DETAILS: 'sitemanager/:siteId/tripdetail/:woNumber',
  TRIP_DETAILS: 'tripdetail/:woNumber',
  PROFILE: 'profile',
  NOTIFICATION: 'notification',
  LOGOUT: 'logout',
  OPEN_SERVICE_REQUEST: 'openServiceRequest',
  INSIGHTS_ANALYTICS: 'insightsAnalytics',
  PARTNER: 'partner',
  BUSINESS_INTELLIGENCE: 'businessIntelligence',
  MANAGED_NETWORK: 'managedNetwork',
  ACCESS_ALARM: 'accessAlarm',
  DEVICES: 'devices',
  MY_OPEN_PROJECTS: 'myopenProjects',
  ADMIN: 'admin',
  PERMISSIONS: 'permissions',
  USERS: 'users',
  ADD_USER: 'users/adduser',
  EDIT_USER: 'users/edituser/:userID',
  BULK_UPLOAD: 'bulk-upload',
  COMPANY_SITE: 'companysite',
  // ADD_COMPANY: 'companysite/addcompany',
  EDIT_COMPANY: 'companysite/editcompany/:companyNumber',
  EDIT_SITE: 'companysite/editSite/:siteID',
  ADD_SITE_GROUP: 'addSiteGroup',
  EDIT_SITE_GROUP: 'editSiteGroup',
  VIEW_SITE_DETAILS: 'companysite/viewSiteDetails/:siteID',
  ADD_CHRONIC: 'companysite/addChronic',
  EDIT_CHRONIC: 'companysite/editChronic/:chronicID',
  SITE_GROUP_REALIGNMENT: 'site-group-realignment',
  ADD_NOTIFICATION: 'addNotification',
  EDIT_NOTIFICATION: 'editNotification',
  REPORT: 'report',
  REPORTS: 'reports',
  EXPORT_REPORT: 'exportData',
  EXPORT_DATA: 'exportData',
  SECURITY_MANAGER_REPORT: 'securityManagerReport',
  SM_ALL_ACTIVITY_REPORT: 'AllActivity',
  SM_OPEN_CLOSE_REPORT: 'OpenAndClose',
  SM_USER_CONTACTS: 'userAndContact',
  HELP_SUPPORT: 'helpSupport',
  SUBMIT_IDEA: 'submitIdea',
  DOWNLOAD: 'download',
  NEW_RELEASE: 'newrelease',
  HELP: 'help',
  PROJECTOR_VIEW: 'projectorView',
  IMPERSONATE_USER: 'imUser',
  SECURITY_EVENT_DETAILS: 'event-details/:eventId',
  SITE_SECURITY_EVENT_DETAILS: 'sitemanager/:siteId/event-details/:eventId',
  E_LEARNING: 'elearning',
  TERMS_CONDITION: 'TnC',
  ONLINE_PAYMENTS: 'payment',
};
export const PREVENT_PATH: any = [
  `/${ROUTE_PATH.PROJECTOR_VIEW}`,
  `/${ROUTE_PATH.IMPERSONATE_USER}`,
  `/${ROUTE_PATH.HELP}`,
];
export const INACTIVE_SITE_RESTRICT_PATH: any = [
  ROUTE_PATH.OPEN_SERVICE_REQUEST,
  ROUTE_PATH.INSIGHTS_ANALYTICS,
  ROUTE_PATH.REPORTS,
];
