import { Grid, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { CONTEXT } from 'utils/Constants/Content';
import SessionTimer from './sessionTimer';
import { MobileLogoutIcon } from 'assets/component';
import { IconsButton } from 'app/component/core/Buttons';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'app/pages/Router/constant';

const Impersonate = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  width: '20vw',
  fontSize: useMediaQuery(theme.breakpoints.up('xl')) ? '0.8rem' : '0.7rem',
  marginTop: '-1.6rem !important',
  background: COLORS.backupColor,
  color: COLORS.haitiViolet,
  padding: '0.13rem 0',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
  zIndex: 999,
}));

const ImpersonateUserBanner = ({ theme, userDetails }: any) => {
  const navigate = useNavigate();
  const isTabScreen = useMediaQuery(theme.breakpoints.down('md'));

  const onLogout = () => {
    navigate(`/${ROUTE_PATH.LOGOUT}`, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onLogout);
    return () => window.removeEventListener('beforeunload', onLogout);
  }, []);

  return (
    <Impersonate
      xs={10}
      item
      theme={theme}
      style={{ visibility: isTabScreen ? 'hidden' : 'visible' }}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ paddingLeft: '0.8rem', paddingTop: '0.5rem' }}>
          {`${CONTEXT.USER_VIEW} `}
          <span
            style={{
              fontWeight: 700,
            }}
          >{`${userDetails?.FirstName} ${userDetails?.LastName}`}</span>
          <SessionTimer />
        </div>
        <div style={{ marginLeft: 'auto', padding: '0.2rem' }}>
          <IconsButton
            name={'ClearFilter'}
            children={<MobileLogoutIcon />}
            style={{
              background: COLORS.royalBlue,
              borderRadius: 4,
              padding: '1px',
            }}
            styleTooltip={{
              height: 'auto',
            }}
            title="Logout session"
            isShowTooltip={true}
            OnIconClick={onLogout}
          />
        </div>
      </div>
    </Impersonate>
  );
};

export default ImpersonateUserBanner;
