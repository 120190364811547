/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TableContainer, TableHeader, TableBody } from './style';
import Pagination from '../Pagination';
import HeaderItem from './HeaderItem';
import SiteRowItem from './SiteRowItem';
import ServiceTripRowItem from './ServiceTripRowItem';
import NetowrkRowItem from './NetowrkRowItem';
import ServiceRowItem from './ServiceRequestRowItem';
import PermissionGroupRowItem from './PermissionGroupRowItem';
import UserManagementRowItem from './UserManagementRowItem';
import { TableSkleton } from 'app/component/layout/Loader/Loader/TableSkelton';
import { useEffect, useState } from 'react';
import NetworkOutagesRowItem from './NetworkOutagesRowItem';
import UserListRowItem from './UserListRowItem';
import { ListViewProps } from './type';
import CompanyRowItem from './CompanyRowItem';
import SiteCSRowItem from './SiteCSRowItem';
import SiteGroupsCSRowItem from './SiteGroupsCSRowItem';
import SiteDetailsCSRowItem from './SiteDetailsCSRowItem';
import InstalledSystemsCSRowItem from './InstalledSystemsCSRowItem';
import InstalledSystemsOracleCSRowItem from './InstalledSystemsOracleCSRowItem';
import ViewGroupsCSRowItem from './ViewGroupsCSRowItem';
import NotificationRowItem from './NotificationRowItem';
import UserProfilePermissionRowItem from './UserProfilePermissionRowItem';
import UserProfilePermissionGroupRowItem from './UserProfilePermisisonGroupRowItem';
import SystemExportRowItem from './SystemExportRowItem';
import SitesExportRowItem from './SitesExportRowItem';
import NetworkExportRowItem from './NetworkExportRowItem';
import ServiceRequestExportRowItem from './ServiceRequestExportRowItem';
import ServiceTripExportRowItem from './ServiceTripsExportRowItem';
import UserNotificationList from './UserNotificationList';
import { CONTEXT, DISPLAY_TEXT } from 'utils/Constants/Content';
import NewReleaseRowItem from './NewReleaseRowItem';
import ChronicRowItem from './ChronicRowItem';
import ActivityLog from './ActivityLog';
import ContactRowItem from './ContactRowItem';
import SiteDetailsCheckListRowItem from './SiteDetailsCheckListRowItem';
import SiteGroupBulkUploadedRowItem from './SiteGroupBulkUploadedRowItem';
import BulkUploadedStatusRowItem from './BulkUploadedStatusRowItem';
import SecurityManagerUserRowItem from './SecurityManagerUserRowItem';
import { useMobileDevice } from 'utils/CommonFn';
import SMOpenCloseActivityRowItem from './SMOpenCloseActivityRowItem';
import SMAllUserContactRowItem from './SMAllUserContactRowItem';
import SecurityEventsRowItem from './SecurityEventsRowItem';
import SecurityEventsExportRowItem from './SecurityEventsExportRowItem';

export function Listview({
  headerItems,
  listData,
  name,
  totalRecords,
  onPageChange = () => {},
  currentPage = 1,
  loading = false,
  onIconsClick,
  onSiteHandle,
  onServiceRequestEditHandle,
  onServiceTripEditHandle,
  onhandleChange,
  hideShowSiteData,
  showSiteRemark,
  CustomHeight,
  isAutoCells,
  onSortClick,
  isSort,
  onDeleteIconsClick,
  onServiceDetailsClick,
  source,
  footerLabel,
  recordLimit,
  onImpersonateClick,
  onEventDetailsClick,
}: ListViewProps): JSX.Element {
  const theme = useTheme();
  const [calculateHeight, setCalculateHeight] = useState(
    window.innerHeight > 768
      ? window.innerHeight - CustomHeight + 'px'
      : CustomHeight + 90 + 'px',
  );
  const [responsiveHeight, setResponsiveHeight] = useState<any>({
    width: '100%',
    height:
      window.innerHeight > 768
        ? window.innerHeight - CustomHeight + 'px'
        : window.innerHeight - CustomHeight + 'px',
  });
  const isMobile = useMobileDevice();
  const handlePageChange = (value: any): any => {
    onPageChange(value);
  };
  const callback = (e: any): void => {
    const updatedHeight: any = Number(e.target.innerHeight - 420);
    setCalculateHeight(updatedHeight + 90);
    const heightStyle: any = {
      width: '100%',
      height: `${updatedHeight}px`,
    };
    setResponsiveHeight(heightStyle);
  };
  useEffect(() => {
    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('resize', callback);
    };
  }, [responsiveHeight, calculateHeight]);
  return (
    <TableContainer
      container
      theme={theme}
      sx={{
        width: isMobile ? 'auto !important' : '100%',
      }}
    >
      <Grid
        container
        sx={{
          width: isMobile ? 'auto !important' : '100%',
        }}
      >
        <TableHeader
          item
          container
          theme={theme}
          sx={{
            flexWrap: isMobile ? 'nowrap' : 'wrap',
          }}
        >
          {headerItems.map((headerItem: any, index: any) => (
            <>
              <HeaderItem
                theme={theme}
                label={headerItem.label}
                value={headerItem.value}
                columnSpan={
                  isAutoCells
                    ? Object.keys(headerItems).length <= 12
                      ? headerItem.columnSpan
                      : 1
                    : headerItem.columnSpan
                }
                isFirst={index === 0}
                item={headerItem}
                isSort={isSort}
                onSortClick={onSortClick}
                tooltip={headerItem?.tooltip}
              />
            </>
          ))}
          <Grid item xs={2}></Grid>
        </TableHeader>
        <>
          <TableBody
            theme={theme}
            item
            container
            sx={{
              ...responsiveHeight,
              height: '390px',
              display: 'block',
              width:
                isMobile && !loading && listData.length
                  ? 'auto !important'
                  : '100%',
            }}
            flexDirection={'column'}
          >
            {loading ? (
              <TableSkleton theme={theme} />
            ) : listData.length > 0 ? (
              listData.map((item: any, index: any) =>
                name === CONTEXT.SITE ? (
                  <SiteRowItem
                    key={index * 2}
                    rowItem={item}
                    tableName={name}
                    onSiteHandleClick={onSiteHandle}
                  />
                ) : name === CONTEXT.NETWORK ? (
                  <NetowrkRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    onSiteHandleClick={onSiteHandle}
                    source={source}
                  />
                ) : name === CONTEXT.SERVICE_TRIP ? (
                  <ServiceTripRowItem
                    key={index * 4}
                    rowItem={item}
                    tableName={name}
                    onServiceTripEditClick={onServiceTripEditHandle}
                    onSiteHandleClick={onSiteHandle}
                    onServiceDetailsClick={onServiceDetailsClick}
                    source={source}
                  />
                ) : name === CONTEXT.SECURITY_EVENTS ? (
                  <SecurityEventsRowItem
                    key={index * 5}
                    rowItem={item}
                    tableName={name}
                    source={source}
                    onSiteHandleClick={onSiteHandle}
                    onDetailsViewClick={onEventDetailsClick}
                  />
                ) : name === CONTEXT.PERMISSION_GROUP ? (
                  <PermissionGroupRowItem
                    key={Math.random()}
                    rowItem={item}
                    tableName={name}
                    onIconsClick={onIconsClick}
                    handleChange={onhandleChange}
                  />
                ) : name === CONTEXT.USER_MANAGEMENT ? (
                  <UserManagementRowItem
                    key={index * 2}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.ACTIVE_SERVICE_REQUEST ? (
                  <ServiceRowItem
                    key={index * 4}
                    rowItem={item}
                    tableName={name}
                    onSiteHandleClick={onSiteHandle}
                    onServiceRequestEditClick={onServiceRequestEditHandle}
                  />
                ) : name === CONTEXT.NETWORK_OUTAGES ? (
                  <NetworkOutagesRowItem
                    key={index * 5}
                    rowItem={item}
                    tableName={name}
                    onSiteHandleClick={onSiteHandle}
                  />
                ) : name === CONTEXT.USER_LIST ? (
                  <UserListRowItem
                    key={index * 6}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                    onImpersonateClick={onImpersonateClick}
                    source={source}
                  />
                ) : name === CONTEXT.SECURITY_MANAGER_USER_LIST ? (
                  <SecurityManagerUserRowItem
                    key={index * 6}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    onDeleteIconClick={onDeleteIconsClick}
                  />
                ) : name === CONTEXT.COMPANY_LIST ? (
                  <CompanyRowItem
                    key={index * 4}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                  />
                ) : name === CONTEXT.CHRONIC_LIST ? (
                  <ChronicRowItem
                    key={index * 4}
                    rowItem={item}
                    onEditIconClick={onIconsClick}
                    onDeleteIconClick={onDeleteIconsClick}
                  />
                ) : name === CONTEXT.SITE_CS_LIST ? (
                  <SiteCSRowItem
                    key={index * 4}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                    showSiteRemark={showSiteRemark}
                    hideShowSiteData={hideShowSiteData}
                    onSiteHandleClick={onSiteHandle}
                  />
                ) : name === CONTEXT.SITE_GROUP_CS_LIST ? (
                  <SiteGroupsCSRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                  />
                ) : name === CONTEXT.SITE_DETAILS_CHECK_LIST ? (
                  <SiteDetailsCheckListRowItem
                    key={Math.random() * 3}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.SITE_DETAILS_CS_LIST ? (
                  <SiteDetailsCSRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.INSTALLED_SYSTEM_CS_LIST ? (
                  <InstalledSystemsCSRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.INSTALLED_SYSTEM_ORACLE_CS_LIST ? (
                  <InstalledSystemsOracleCSRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.VIEW_GROUP_CS_LIST ? (
                  <ViewGroupsCSRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.NOTIFICATION_LIST ? (
                  <NotificationRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                  />
                ) : name === CONTEXT.USER_PROFILE_PERMISSION_LIST ? (
                  <UserProfilePermissionRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                  />
                ) : name === CONTEXT.USER_PROFILE_PERMISSION_GROUP_LIST ? (
                  <UserProfilePermissionGroupRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                  />
                ) : name === CONTEXT.SYSTEM_EXPORT_LIST ? (
                  <SystemExportRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    isAutoCells={isAutoCells}
                  />
                ) : name === CONTEXT.SITES_EXPORT_LIST ? (
                  <SitesExportRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    isAutoCells={isAutoCells}
                  />
                ) : name === CONTEXT.NETWORK_EXPORT_LIST ? (
                  <NetworkExportRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    isAutoCells={isAutoCells}
                  />
                ) : name === CONTEXT.SERVICE_REQUEST_EXPORT_LIST ? (
                  <ServiceRequestExportRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    isAutoCells={isAutoCells}
                  />
                ) : name === CONTEXT.SERVICE_TRIP_EXPORT_LIST ? (
                  <ServiceTripExportRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    isAutoCells={isAutoCells}
                  />
                ) : name === CONTEXT.SECURITY_EVENT_EXPORT_LIST ? (
                  <SecurityEventsExportRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    isAutoCells={isAutoCells}
                  />
                ) : name === CONTEXT.USER_NOTIFCATION_LIST ? (
                  <UserNotificationList
                    key={index * 4}
                    rowItem={item}
                    tableName={name}
                    onRowClick={onhandleChange}
                  />
                ) : name === CONTEXT.NEW_RELASE_LIST ? (
                  <NewReleaseRowItem
                    key={index * 3}
                    rowItem={item}
                    tableName={name}
                    handleChange={(e: any) => {
                      onhandleChange(e, item);
                    }}
                  />
                ) : name === CONTEXT.ACTIVITY_LOG ? (
                  <ActivityLog
                    key={index * 7}
                    rowItem={item}
                    tableName={name}
                  />
                ) : name === CONTEXT.CONTACT_LIST ? (
                  <ContactRowItem
                    key={index * 7}
                    rowItem={item}
                    tableName={name}
                    onEditIconClick={(e: any) => onIconsClick(e, item)}
                    onDeleteIconClick={(e: any) => onDeleteIconsClick(e, item)}
                  />
                ) : name === CONTEXT.SITE_GROUP_UPLOADED_LIST ? (
                  <SiteGroupBulkUploadedRowItem
                    key={Math.random()}
                    rowItem={item}
                    tableName={name}
                    onIconClick={(e: any) => onIconsClick(e, item)}
                  />
                ) : name === CONTEXT.BULK_UPLOADED_STATUS ? (
                  <BulkUploadedStatusRowItem
                    key={Math.random()}
                    rowItem={item}
                    tableName={name}
                    onIconClick={(e: any) => onIconsClick(e, item)}
                    source={source}
                  />
                ) : name === CONTEXT.SM_USER_CONTACT ? (
                  <SMAllUserContactRowItem
                    key={Math.random()}
                    rowItem={item}
                    tableName={name}
                    onIconClick={(e: any) => onIconsClick(e, item)}
                    source={source}
                  />
                ) : name === CONTEXT.SM_OPEN_CLOSE_ACTIVITY ? (
                  <SMOpenCloseActivityRowItem
                    key={Math.random()}
                    rowItem={item}
                    tableName={name}
                    onIconClick={(e: any) => onIconsClick(e, item)}
                    source={source}
                  />
                ) : (
                  <ServiceRowItem
                    key={index * 4}
                    rowItem={item}
                    tableName={name}
                    onServiceRequestEditClick={onServiceRequestEditHandle}
                    onSiteHandleClick={onSiteHandle}
                    source={source}
                  />
                ),
              )
            ) : (
              <Grid
                container
                item
                justifyContent="center"
                alignItems="center"
                sx={{ height: 'inherit' }}
              >
                {DISPLAY_TEXT.NO_RECORD_FOUND}
              </Grid>
            )}
          </TableBody>
          {footerLabel ? (
            <Grid container>
              <Grid item p={2}>
                {footerLabel}
              </Grid>
            </Grid>
          ) : (
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              setCurrentPage={handlePageChange}
              loading={loading}
              recordLimit={recordLimit}
            />
          )}
        </>
      </Grid>
    </TableContainer>
  );
}
