import { COLORS } from 'styles/colors';
import { DISPLAY_TEXT, ERROR_TEXT } from './Content';
import { USER_PERMISSIONS } from 'app/features/Constant';

export const REQUIREMENTS_RESET_PASSWORD = [
  { label: 'At least 8 characters', isVerify: false },
  { label: 'A capital letter', isVerify: true },
  { label: 'A lowercase letter', isVerify: false },
  { label: 'At least 1 number', isVerify: false },
  { label: 'At least 1 symbol', isVerify: false },
  { label: 'Not the username or email address', isVerify: false },
];
export const TABS = [
  { id: 1, label: 'Sites', isActive: true },
  { id: 2, label: 'Managed Network', isActive: false },
  { id: 3, label: 'Service Requests', isActive: false },
  { id: 4, label: 'Service Trips', isActive: false },
  { id: 5, label: 'Security Events', isActive: false },
];
export const EXPORT_TABS = [
  { id: 1, label: 'Sites', isActive: true },
  { id: 2, label: 'System', isActive: false },
  { id: 3, label: 'Managed Network', isActive: false },
  { id: 4, label: 'Service Requests', isActive: false },
  { id: 5, label: 'Service Trips', isActive: false },
  { id: 6, label: 'Security Events', isActive: false },
];
export const SECURITY_MANAGER_REPORTS = [
  { id: 1, label: 'All Activity', isActive: true },
  { id: 2, label: 'Open/Close', isActive: false },
];
export const USERS_TABS = [
  {
    id: 1,
    label: 'OVE User',
    isActive: true,
    permission: '',
    isAllowAccess: true,
  },
  {
    id: 2,
    label: 'Security Manager Contacts',
    isActive: false,
    permission: USER_PERMISSIONS.SECURITY_MANAGER_USER_ADMIN,
    isAllowAccess: false,
  },
  {
    id: 3,
    label: 'Bulk Upload',
    isActive: false,
    permission: USER_PERMISSIONS.BULK_UPLOAD_USER,
    isAllowAccess: false,
  },
];

export const ACTIVE_CARDS = [
  {
    label: 'Active Service Requests',
    score: 0,
    isActive: true,
  },
  {
    label: 'Network Outages',
    score: 0,
    isActive: true,
  },
  {
    label: 'Devices Offline',
    score: 0,
    isActive: true,
  },
];
export const CHARTS_DATA = [
  {
    label: 'Up',
    value: 10,
    fill: COLORS.primaryColor,
    stroke: COLORS.primaryColor,
  },
  {
    label: 'Down',
    value: 44,
    fill: COLORS.downColor,
    stroke: COLORS.downColor,
  },
  {
    label: 'Warning',
    value: 22,
    fill: COLORS.backupColor,
    stroke: COLORS.backupColor,
  },
  {
    label: 'Unknown',
    value: 22,
    fill: COLORS.white,
    stroke: COLORS.white,
  },
];
export const MAP_DATA = [
  {
    label: 'Up',
    value: 10,
    fill: COLORS.primaryColor,
    stroke: COLORS.primaryColor,
  },
  {
    label: 'Down',
    value: 44,
    fill: COLORS.downColor,
    stroke: COLORS.downColor,
  },
  {
    label: 'Warning',
    value: 22,
    fill: COLORS.backupColor,
    stroke: COLORS.backupColor,
  },
  {
    label: 'Unknown',
    value: 22,
    fill: COLORS.iconBgColor,
    stroke: COLORS.iconBgColor,
  },
];
export const NETWORK_CHART = [
  {
    label: 'Up',
    value: 10,
    fill: COLORS.primaryColor,
    stroke: COLORS.primaryColor,
  },
  {
    label: 'Down',
    value: 44,
    fill: COLORS.downColor,
    stroke: COLORS.downColor,
  },
  {
    label: 'Warning',
    value: 22,
    fill: COLORS.backupColor,
    stroke: COLORS.backupColor,
  },
];
export const MAP_LEGEND = {
  PRIMARY: 'Up',
  DOWN: 'Down',
  BACKUP: 'Warning',
  UNKNOWN: 'UNKNOWN',
};
export const PERMISSIONS = [
  {
    label: 'Permission 1',
  },
  {
    label: 'Permission 2',
  },
  {
    label: 'Permission 3',
  },
  {
    label: 'Permission 4',
  },
];

export const ADD_EXISTING_GROUP: any = [
  {
    existingPermission: {
      id: 'existingPermission',
      list: [
        {
          PermissionGroupId: 76,
          Name: 'Permission Group Test3',
          Permissions: [
            {
              PermissionGroupId: 71,
              Name: 'Permission 1',
              Permissions: [],
            },
            {
              PermissionGroupId: 72,
              Name: 'Permission 2',
              Permissions: [],
            },
            {
              PermissionGroupId: 73,
              Name: 'Permission 3',
              Permissions: [],
            },
          ],
        },
        {
          PermissionGroupId: 77,
          Name: 'Permission Group Test2',
          Permissions: [
            {
              PermissionGroupId: 1,
              Name: 'Permission 1',
              Permissions: [],
            },
            {
              PermissionGroupId: 2,
              Name: 'Permission 2',
              Permissions: [],
            },
          ],
        },
      ],
    },
    userSelectedPermission: {
      id: 'userSelectedPermission',
      list: [],
    },
  },
];

export const TABS_USER_MANAGEMENT = [
  { id: 1, label: 'Personal Details', isActive: true },
  { id: 2, label: 'Permission', isActive: false },
];

export const TABS_ROLES_PERMISSION = [
  { id: 1, label: 'Master Company', isActive: false },
  { id: 2, label: 'Sites', isActive: false },
  { id: 3, label: 'Permission Groups', isActive: true },
  { id: 4, label: 'User Groups', isActive: false },
];
export const TABS_USER_ADMIN = [
  { id: 1, label: 'Profile', isActive: false },
  { id: 2, label: 'User Group', isActive: false },
  { id: 3, label: 'Sites', isActive: true },
  { id: 4, label: 'Alarm Codes', isActive: false },
  { id: 5, label: 'Activity History', isActive: false },
];

// Table headers
export const CUSTOM_ROW_WIDTH = '36vw';
export const ROW_WIDTH = '26vw';
export const SITE_HEADER: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 2.4,
    sortColumn: 'Name',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Address',
    value: 'address',
    columnSpan: 1.5,
    sortColumn: 'Address',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'City',
    value: 'city',
    columnSpan: 1,
    sortColumn: 'City',
    isSort: true,
  },
  {
    label: 'State',
    value: 'state',
    columnSpan: 1,
    sortColumn: 'State',
    isSort: true,
  },
  {
    label: 'Service Level ',
    value: 'servicelevel',
    columnSpan: 1.2,
    sortColumn: 'ServiceLevel',
    isSort: true,
  },
  {
    label: 'Network',
    value: 'ipnet',
    columnSpan: 0.7,
    sortColumn: 'IPNETW',
    isSort: true,
    icon: 'NetworkIcon',
  },
  {
    label: 'Intrusion',
    value: 'burg',
    columnSpan: 0.7,
    sortColumn: 'BURG',
    isSort: true,
    icon: 'IntrusionIcon',
  },
  {
    label: 'Voice',
    value: 'voip',
    columnSpan: 0.7,
    sortColumn: 'VOIP',
    isSort: true,
    icon: 'VoiceIcon',
  },
  {
    label: 'Video',
    value: 'cctv',
    columnSpan: 0.7,
    sortColumn: 'CCTV',
    isSort: true,
    icon: 'VideoIcon',
  },
  {
    label: 'Fire',
    value: 'burg',
    columnSpan: 0.7,
    sortColumn: 'FIRE',
    isSort: true,
    icon: 'FireIcon',
  },
  {
    label: 'Access Control',
    value: 'voip',
    columnSpan: 0.7,
    sortColumn: 'ACCESS_CONTROL',
    isSort: true,
    icon: 'AccessControlIcon',
  },
  {
    label: 'Fire/Intrusion Combo',
    value: 'cctv',
    columnSpan: 0.7,
    sortColumn: 'FIRE_INTRUSION_COMBO',
    isSort: true,
    icon: 'FireIntrusionIcon',
  },
];
export const NETWORK_HEADER: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 2,
    sortColumn: 'Name',
    isSort: true,
  },
  {
    label: 'Circuit Status',
    value: 'circuitstatus',
    columnSpan: 2,
    sortColumn: 'CurrentStatus',
    isSort: true,
  },
  {
    label: 'Status Change Date',
    value: 'statuschangedate',
    columnSpan: 3,
    sortColumn: 'StatusChangeTime',
    isSort: true,
  },
  {
    label: 'Time Since Status Changed',
    value: 'TimesinceStatuschanged',
    columnSpan: 3,
    sortColumn: 'StatusChangeTime',
    isSort: true,
  },
  {
    label: 'Chronic',
    value: 'chronic',
    columnSpan: 1,
    sortColumn: 'Chronic',
    isSort: true,
  },
  {
    label: 'Switch',
    value: 'switch',
    columnSpan: 1,
    sortColumn: 'SwitchStatus',
    isSort: true,
  },
];
export const SERVICE_REQUEST_HEADER: any = [
  {
    label: 'Service Number',
    value: 'casenumber',
    columnSpan: 2,
  },
  {
    label: 'Site',
    value: 'site',
    columnSpan: 2,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Subject',
    value: 'subject',
    columnSpan: 2,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },
  {
    label: 'Severity',
    value: 'severity',
    columnSpan: 1,
  },
  {
    label: 'Created On',
    value: 'createdon',
    columnSpan: 1,
  },
  {
    label: 'Resolved On',
    value: 'resolvedon',
    columnSpan: 2,
  },
];
export const SERVICE_REQUEST_HEADER_SITE_MANAGER: any = [
  {
    label: 'Service Number',
    value: 'casenumber',
    columnSpan: 1,
    sortColumn: 'CaseNumber',
    isSort: true,
  },
  {
    label: 'Site',
    value: 'site',
    columnSpan: 2,
    sortColumn: 'Site.Name',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Subject',
    value: 'subject',
    columnSpan: 1,
    sortColumn: 'Subject',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'SR Title',
    value: 'srTitle',
    columnSpan: 1.5,
    sortColumn: 'Title',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Type',
    value: 'IncidentType',
    columnSpan: 1,
    sortColumn: 'IncidentType',
    isSort: true,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 1,
    sortColumn: 'Status',
    isSort: true,
  },
  {
    label: 'Sub Status',
    value: 'substatus',
    columnSpan: 1,
    sortColumn: 'SubStatus',
    isSort: true,
  },
  {
    label: 'Severity',
    value: 'severity',
    columnSpan: 1,
    sortColumn: 'Priority',
    isSort: true,
  },
  {
    label: 'Created On',
    value: 'createdon',
    columnSpan: 1,
    sortColumn: 'CreatedOn',
    isSort: true,
  },
  {
    label: 'Resolved On',
    value: 'resolvedon',
    columnSpan: 1,
    sortColumn: 'ResolvedOn',
    isSort: true,
  },
  {
    label: 'Action',
    value: 'action',
    columnSpan: 0.5,
  },
];
export const SERVICE_TRIPS_HEADER: any = [
  {
    label: 'Site',
    value: 'site',
    columnSpan: 2,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 1,
  },
  {
    label: 'Priority',
    value: 'priority',
    columnSpan: 1,
  },
  {
    label: 'Service Number',
    value: 'casenumber',
    columnSpan: 1,
  },
  {
    label: 'Work Order',
    value: 'Workorder',
    columnSpan: 2,
  },
  {
    label: 'Type',
    value: 'type',
    columnSpan: 1,
  },
  {
    label: 'Scheduled Date',
    value: 'ScheduledDate',
    columnSpan: 1,
  },
  {
    label: 'Created On',
    value: 'casenumber',
    columnSpan: 1,
  },
  {
    label: 'Dispatched On',
    value: 'dispatchedon',
    columnSpan: 2,
  },
];
export const SERVICE_TRIPS_HEADER_SITE_MANAGER: any = [
  {
    label: 'Site',
    value: 'site',
    columnSpan: 1.5,
    sortColumn: 'Site.Name',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 1,
    sortColumn: 'Status',
    isSort: true,
  },
  {
    label: 'Priority',
    value: 'priority',
    columnSpan: 1,
    sortColumn: 'ServiceRequest.Priority',
    isSort: true,
  },
  {
    label: 'Service Number',
    value: 'casenumber',
    columnSpan: 1,
    sortColumn: 'ServiceRequest.CaseNumber',
    isSort: true,
  },
  {
    label: 'SR Title',
    value: 'sr_Title',
    columnSpan: 1.5,
    sortColumn: 'Title',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Work Order',
    value: 'Workorder',
    columnSpan: 1.25,
    sortColumn: 'WorkOrderNumber',
    isSort: true,
  },
  {
    label: 'Type',
    value: 'type',
    columnSpan: 1.25,
    sortColumn: 'Type',
    isSort: true,
  },
  {
    label: 'Scheduled Date',
    value: 'ScheduledDate',
    columnSpan: 1,
    sortColumn: 'ScheduledDate',
    isSort: true,
  },
  {
    label: 'Resource',
    value: 'resource',
    columnSpan: 1,
    sortColumn: 'ScheduledResource',
    isSort: true,
    width: CUSTOM_ROW_WIDTH,
  },
  {
    label: 'Created On',
    value: 'casenumber',
    columnSpan: 1,
    sortColumn: 'CreatedOn',
    isSort: true,
  },
  {
    label: 'Action',
    value: 'action',
    columnSpan: 0.5,
  },
];
export const SECURITY_EVENTS_HEADER = [
  {
    label: 'Site Name',
    value: 'siteName',
    columnSpan: 2.5,
    sortColumn: 'siteDetails.SiteName',
    isSort: false,
  },
  {
    label: 'Event Name',
    value: 'eventName',
    columnSpan: 2,
    sortColumn: 'EventName',
    isSort: false,
  },
  {
    label: 'Event Category',
    value: 'eventCategory',
    columnSpan: 2,
    sortColumn: 'EventCategory',
    isSort: false,
  },
  {
    label: 'Time of Alarm',
    value: 'timeOfAlarm',
    columnSpan: 2,
    sortColumn: 'AlarmTime',
    isSort: false,
  },
  /* {
    label: 'Area',
    value: 'area',
    columnSpan: 0.75,
    sortColumn: 'Area',
    isSort: false,
  }, */
  {
    label: 'Zone',
    value: 'zone',
    columnSpan: 1,
    sortColumn: 'Zone',
    isSort: false,
  },
  {
    label: 'Resolution',
    value: 'resolution',
    columnSpan: 1.5,
    sortColumn: 'Resolution',
    isSort: false,
  },
  {
    label: 'Action',
    value: 'action',
    columnSpan: 1,
    isSort: false,
  },
];
export const PERMISSION_GROUP_HEADER: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 4,
  },
  {
    label: 'Date Created',
    value: 'DateCreated',
    columnSpan: 3,
  },
  {
    label: 'Created By',
    value: 'CreatedBy',
    columnSpan: 3,
  },
  {
    label: 'Controls',
    value: 'Controls',
    columnSpan: 2,
  },
];
export const ADD_EXISTING_GROUP1 = [
  {
    label: 'Permission Group A',
  },
  {
    label: 'Permission Group B',
  },
  {
    label: 'Permission Group C',
  },
  {
    label: 'Permission Group D',
  },
];
export const DASHBOARD_SERVICE_REQUEST: any = [
  {
    label: 'Service Number',
    value: 'servicenumber',
    columnSpan: 1,
  },
  {
    label: 'Site',
    value: 'site',
    columnSpan: 2,
  },
  {
    label: 'Subject',
    value: 'subject',
    columnSpan: 1,
  },
  {
    label: 'SR Title',
    value: 'Title',
    columnSpan: 1.5,
  },
  {
    label: 'Type',
    value: 'IncidentType',
    columnSpan: 1,
    sortColumn: 'IncidentType',
    isSort: true,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 1,
  },
  {
    label: 'Sub Status',
    value: 'substatus',
    columnSpan: 1,
    sortColumn: 'SubStatus',
    isSort: true,
  },
  {
    label: 'Severity',
    value: 'severity',
    columnSpan: 1,
  },
  {
    label: 'Created on',
    value: 'createdOn',
    columnSpan: 1,
  },
  {
    label: 'Resolved on',
    value: 'resolvedOn',
    columnSpan: 1,
  },
];
export const DASHBOARD_NETWORK_OUTAGES: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 3,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },
  {
    label: 'Chronic',
    value: 'chronic',
    columnSpan: 2,
  },
  {
    label: 'Status Time',
    value: 'statuschangetime',
    columnSpan: 3,
  },
  {
    label: 'SwitchStatus',
    value: 'switchstatus',
    columnSpan: 2,
  },
];
export const USERS_COLUMNS_LABELS = {
  CUSTOMER_VIEW: 'Customer View',
  NAME: 'Name',
  CONTROLS: 'Controls',
};
export const USERS_LIST: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 2,
  },
  {
    label: 'Email',
    value: 'email',
    columnSpan: 3,
  },
  {
    label: 'Date Created',
    value: 'Created',
    columnSpan: 2,
  },
  {
    label: 'Created By',
    value: 'createdby',
    columnSpan: 2,
  },
  {
    label: 'Customer View',
    value: '',
    columnSpan: 1.5,
  },
  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 1.5,
  },
];
export const SECURITY_MANAGER_USERS_LIST: any = [
  {
    label: 'Employee Name',
    value: 'employeeName',
    columnSpan: 2,
  },
  {
    label: 'Employee ID',
    value: 'employeeId',
    columnSpan: 1,
  },
  {
    label: 'Site Name',
    value: 'siteName',
    columnSpan: 2,
  },
  {
    label: 'Site Address',
    value: 'siteAddress',
    columnSpan: 2,
  },
  {
    label: 'Site City/State/Zip',
    value: 'address',
    columnSpan: 2,
  },
  {
    label: 'Alarm Code',
    value: 'alarmCode',
    columnSpan: 1,
  },
  {
    label: 'Global',
    value: 'global',
    columnSpan: 1,
  },
  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 1,
  },
];
export const QUICK_STATS = [
  {
    label: 'Active Service Requests',
    score: 0,
  },
  {
    label: 'Site Network Down',
    score: 0,
  },
  {
    label: 'Devices Offline',
    score: DISPLAY_TEXT.FUTURE_ENHANCEMENT,
  },
  {
    label: 'Security Events',
    score: 0,
  },
];

export const COMPANY_SITE_TABS = [
  { id: 1, label: 'Company', isActive: true },
  { id: 2, label: 'Sites', isActive: false },
  { id: 3, label: 'Sites Group', isActive: false },
];

export const SITE_GROUP_REALIGNMENT = {
  id: 4,
  label: 'Site Groups Realignment',
  isActive: false,
};

export const CHRONIC_TAB = { id: 5, label: 'Chronic', isActive: false };

export const COMPANY_LIST_CS: any = [
  {
    label: 'Company Name',
    value: 'company_name',
    columnSpan: 4,
  },
  {
    label: 'Party Number',
    value: 'party_number',
    columnSpan: 4,
  },
  {
    label: 'Account type',
    value: 'account_type',
    columnSpan: 3,
  },
  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 1,
  },
];

export const SITES_LIST_CS: any = [
  {
    label: 'Company Number',
    value: 'company_number',
    columnSpan: 2,
  },
  {
    label: 'Name',
    value: 'name',
    columnSpan: 4,
  },
  {
    label: 'Hide Site Data',
    value: 'hide_site_data',
    columnSpan: 2,
  },
  {
    label: 'Remark',
    value: 'remark',
    columnSpan: 2,
  },

  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 2,
  },
];

export const SITES_GROUP_LIST_CS: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 4,
  },
  {
    label: 'Company',
    value: 'master_company',
    columnSpan: 3,
  },
  {
    label: 'Is All Group',
    value: 'is_all_group',
    columnSpan: 3,
  },

  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 2,
  },
];
export const CHRONIC_LIST_CS: any = [
  {
    label: 'SMB Code',
    value: 'smb_code',
    columnSpan: 2,
  },
  {
    label: 'SMB Rule',
    value: 'smb_rule',
    columnSpan: 5,
  },
  {
    label: 'Param 1',
    value: 'param1',
    columnSpan: 1,
  },
  {
    label: 'Param 2',
    value: 'param2',
    columnSpan: 1,
  },
  {
    label: 'Param 3',
    value: 'param3',
    columnSpan: 2,
  },
  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 1,
  },
];
export const ACTIVITY_LOG_LIST: any = [
  {
    label: 'Date and Time',
    value: 'date_time',
    columnSpan: 3,
  },
  {
    label: 'Log Description',
    value: 'log_description',
    columnSpan: 8,
  },
];
export const CONTACT_LIST: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 1.5,
  },
  {
    label: 'Job Title',
    value: 'job_title',
    columnSpan: 1,
  },
  {
    label: 'User',
    value: 'user',
    columnSpan: 0.5,
  },
  {
    label: 'Mobile Phone',
    value: 'mobile',
    columnSpan: 2,
  },
  {
    label: 'Home Phone',
    value: 'home',
    columnSpan: 2,
  },
  {
    label: 'Alarm Code',
    value: 'alarmcode',
    columnSpan: 1,
    tooltip: DISPLAY_TEXT.SM_ALARM_CODE_TOOLTIP,
  },
  {
    label: 'Verbal Password',
    value: 'password',
    columnSpan: 2,
    tooltip: DISPLAY_TEXT.SM_VERBAL_PASSWORD_TOOLTIP,
  },
  {
    label: 'Global',
    value: 'global',
    columnSpan: 1,
  },
  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 1,
  },
];
export const SITE_DETAILS_LIST_CS: any = [
  {
    label: 'Company Number',
    value: 'company_number',
    columnSpan: 2,
  },
  {
    label: 'Host Name',
    value: 'host_name',
    columnSpan: 2,
  },

  {
    label: 'Navision Status',
    value: 'navision_status',
    columnSpan: 2,
  },

  {
    label: 'Temp Closed',
    value: 'temp_closed',
    columnSpan: 2,
  },
  {
    label: 'Perm Closed',
    value: 'perm_closed',
    columnSpan: 2,
  },
  {
    label: 'Is Active',
    value: 'is_active',
    columnSpan: 1,
  },
  {
    label: 'Is Hidden',
    value: 'is_hidden',
    columnSpan: 1,
  },
];

export const SITE_DETAILS_CHECKLIST: any = [
  {
    label: 'Current Service Level',
    value: 'Current_service_level',
    columnSpan: 2,
  },
  {
    label: 'Site Installation Status',
    value: 'site_installation_status',
    columnSpan: 2,
  },

  {
    label: 'System Status',
    value: 'system_status',
    columnSpan: 2,
  },

  {
    label: 'Navision Status',
    value: 'navision_status',
    columnSpan: 2,
  },
  {
    label: 'OVE (Is Active)',
    value: 'ove_active',
    columnSpan: 2,
  },
  {
    label: 'Is Hidden',
    value: 'is_hidden',
    columnSpan: 2,
  },
];

export const INSTALLED_SYSTEMS_LIST_CS: any = [
  {
    label: 'System Number',
    value: 'system_number',
    columnSpan: 2,
  },
  {
    label: 'Type',
    value: 'type',
    columnSpan: 2,
  },

  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },

  {
    label: 'Installation Status',
    value: 'installation_status',
    columnSpan: 2,
  },
];
export const INSTALLED_SYSTEMS_ORACLE_LIST_CS: any = [
  {
    label: 'System Number',
    value: 'system_number',
    columnSpan: 2,
  },
  {
    label: 'Company Number',
    value: 'company_number',
    columnSpan: 2,
  },

  {
    label: 'Type',
    value: 'type',
    columnSpan: 2,
  },

  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },

  {
    label: 'Installation Status',
    value: 'installation_status',
    columnSpan: 2,
  },
];
export const VIEW_GROUPS_LIST_CS: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 4,
  },

  {
    label: 'Company',
    value: 'master_company',
    columnSpan: 4,
  },

  {
    label: 'Is All Group',
    value: 'is_all_group',
    columnSpan: 4,
  },
];
export const PERMISSION_SLICE = 10;
export const SCROLL_SLICE = 15;

export const NOTIFICATION_LIST: any = [
  {
    label: 'Title',
    value: 'title',
    columnSpan: 2,
  },
  {
    label: 'Message',
    value: 'message',
    columnSpan: 2,
  },
  {
    label: 'Type',
    value: 'type',
    columnSpan: 1,
  },
  {
    label: 'Category',
    value: 'category',
    columnSpan: 1,
  },
  {
    label: 'Start Date',
    value: 'start_date',
    columnSpan: 2,
  },
  {
    label: 'End Date',
    value: 'end_date',
    columnSpan: 2,
  },

  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 2,
  },
];

export const PREFERRED_HOURS = [
  {
    id: 'Anytime',
    name: 'Anytime',
  },
  {
    id: 'Before 8am',
    name: 'Before 8am',
  },
  {
    id: '8am - Noon',
    name: '8am - Noon',
  },
  {
    id: 'Noon - 4pm',
    name: 'Noon - 4pm',
  },
  {
    id: '4pm - 8pm',
    name: '4pm - 8pm',
  },
  {
    id: '8pm - Midnight',
    name: '8pm - Midnight',
  },
  {
    id: '9am - 8pm',
    name: '9am - 8pm',
  },
];
export const USER_PROFILE_PERMISSION_LIST: any = [
  {
    label: 'Permission Name',
    value: 'permission_name',
    columnSpan: 4,
  },
  {
    label: 'Description',
    value: 'description',
    columnSpan: 8,
  },
];

export const SYSTEM_EXPORT_HEADER: any = [
  {
    label: 'System Number',
    value: 'SystemNumber',
    columnSpan: 3,
    sortColumn: 'SystemNumber',
    isSort: true,
  },
  {
    label: 'Type',
    value: 'Type',
    columnSpan: 3,
    sortColumn: 'Type',
    isSort: true,
  },
  {
    label: 'Site Name',
    value: 'SiteName',
    columnSpan: 3,
    sortColumn: 'SiteName',
    isSort: true,
  },
  {
    label: 'Status',
    value: 'Status',
    columnSpan: 3,
    sortColumn: 'Status',
    isSort: true,
  },
];

export const SITES_EXPORT_HEADER: any = [
  {
    label: 'Name',
    value: 'name',
    columnSpan: 1,
    sortColumn: 'Name',
    isSort: true,
  },
  {
    label: 'Company Number',
    value: 'companyNumber',
    columnSpan: 1,
    sortColumn: 'CompanyNumber',
    isSort: true,
  },
  {
    label: 'Address',
    value: 'address',
    columnSpan: 1,
    sortColumn: 'Address',
    isSort: true,
  },
  {
    label: 'City',
    value: 'city',
    columnSpan: 1,
    sortColumn: 'City',
    isSort: true,
  },
  {
    label: 'State',
    value: 'state',
    columnSpan: 1,
    sortColumn: 'State',
    isSort: true,
  },
  {
    label: 'Zip',
    value: 'zip',
    columnSpan: 1,
    sortColumn: 'Zip',
    isSort: true,
  },
  {
    label: 'Last Chronic Reason',
    value: 'lastChronicReason',
    columnSpan: 1,
    sortColumn: 'LastChronicReason',
    isSort: true,
  },
  {
    label: 'Last Chronic Date',
    value: 'lastChronicDate',
    columnSpan: 1,
    sortColumn: 'LastChronicDate',
    isSort: true,
  },
  {
    label: 'Chronic Description',
    value: 'chronicDescription',
    columnSpan: 1,
    sortColumn: 'ChronicDescription',
    isSort: true,
  },
  {
    label: 'Service Level',
    value: 'serviceLevel',
    columnSpan: 1,
    sortColumn: 'ServiceLevel',
    isSort: true,
  },
  {
    label: 'Network',
    value: 'network',
    columnSpan: 1,
    sortColumn: 'IPNETW',
    isSort: true,
  },
  {
    label: 'Intrusion',
    value: 'intrusion',
    columnSpan: 1,
    sortColumn: 'BURG',
    isSort: true,
  },
  {
    label: 'Voice',
    value: 'voice',
    columnSpan: 1,
    sortColumn: 'VOIP',
    isSort: true,
  },
  {
    label: 'Video',
    value: 'video',
    columnSpan: 1,
    sortColumn: 'CCTV',
    isSort: true,
  },
  {
    label: 'Fire',
    value: 'burg',
    sortColumn: 'FIRE',
    isSort: true,
  },
  {
    label: 'Access Control',
    value: 'voip',
    sortColumn: 'ACCESS_CONTROL',
    isSort: true,
  },
  {
    label: 'Fire/Intrusion Combo',
    value: 'cctv',
    sortColumn: 'FIRE_INTRUSION_COMBO',
    isSort: true,
  },
];

export const NETWORK_EXPORT_HEADER: any = [
  {
    label: 'Company Number',
    value: 'number',
    columnSpan: 1,
    sortColumn: 'CompanyNumber',
    isSort: true,
  },
  {
    label: 'Address',
    value: 'address',
    columnSpan: 1,
    sortColumn: 'Address',
    isSort: true,
  },
  {
    label: 'City',
    value: 'city',
    columnSpan: 0.5,
    sortColumn: 'City',
    isSort: true,
  },
  {
    label: 'State',
    value: 'state',
    columnSpan: 0.5,
    sortColumn: 'State',
    isSort: true,
  },
  {
    label: 'Zip',
    value: 'zip',
    columnSpan: 0.5,
    sortColumn: 'Zip',
    isSort: true,
  },
  {
    label: 'Current Status',
    value: 'currentStatus',
    columnSpan: 1,
    sortColumn: 'CurrentStatus',
    isSort: true,
  },
  {
    label: 'Chronic',
    value: 'chronic',
    columnSpan: 0.5,
    sortColumn: 'Chronic',
    isSort: true,
  },
  {
    label: 'State Change Date',
    value: 'statuschangedate',
    columnSpan: 1,
    sortColumn: 'StatusChangeDate',
    isSort: true,
  },

  {
    label: 'Circuit State',
    value: 'circuit',
    columnSpan: 0.5,
    sortColumn: 'CircuitState',
    isSort: true,
  },
  {
    label: 'Backup',
    value: 'backup',
    columnSpan: 1,
    sortColumn: 'Backup',
    isSort: false,
  },
  {
    label: 'VPN',
    value: 'vpn',
    columnSpan: 0.5,
    sortColumn: 'Vpn',
    isSort: true,
  },
  {
    label: 'Backup Vpn',
    value: 'backupVPN',
    columnSpan: 1,
    sortColumn: 'BackupVpn',
    isSort: true,
  },
  {
    label: 'Switch',
    value: 'switch',
    columnSpan: 1,
    sortColumn: 'Switch',
    isSort: true,
  },
  {
    label: 'Last Chronic Reason',
    value: 'lastChronicReason',
    columnSpan: 1,
    sortColumn: 'LastChronicReason',
    isSort: false,
  },
  {
    label: 'Last Chronic Date',
    value: 'lastChronicDate',
    columnSpan: 1,
    sortColumn: 'LastChronicDate',
    isSort: false,
  },
];

export const SERVICE_REQUEST_EXPORT_HEADER: any = [
  {
    label: 'Site Name',
    value: 'sitename',
    columnSpan: 1,
    sortColumn: 'SiteName',
    isSort: true,
  },
  {
    label: 'Service Number',
    value: 'casenumber',
    columnSpan: 2,
    sortColumn: 'ServiceNumber',
    isSort: true,
  },
  {
    label: 'Company Number',
    value: 'companyNumber',
    columnSpan: 2,
    sortColumn: 'CompanyNumber',
    isSort: true,
  },
  {
    label: 'Address',
    value: 'address',
    columnSpan: 2,
    sortColumn: 'Address',
    isSort: true,
  },
  {
    label: 'City',
    value: 'city',
    columnSpan: 1,
    sortColumn: 'City',
    isSort: true,
  },
  {
    label: 'State',
    value: 'state',
    columnSpan: 1,
    sortColumn: 'State',
    isSort: true,
  },
  {
    label: 'Zip',
    value: 'zip',
    columnSpan: 1,
    sortColumn: 'Zip',
    isSort: true,
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    columnSpan: 1,
    sortColumn: 'PhoneNumber',
    isSort: true,
  },

  {
    label: 'Status',
    value: 'status',
    columnSpan: 1,
    sortColumn: 'Status',
    isSort: true,
  },
  {
    label: 'Sub Status',
    value: 'substatus',
    columnSpan: 1,
    sortColumn: 'SubStatus',
    isSort: true,
  },
  {
    label: 'Type',
    value: 'type',
    columnSpan: 1,
    sortColumn: 'IncidentType',
    isSort: true,
  },
  {
    label: 'SR Title',
    value: 'Title',
    columnSpan: 1,
    sortColumn: 'Title',
    isSort: true,
  },
  {
    label: 'Subject',
    value: 'subject',
    columnSpan: 1,
    sortColumn: 'Subject',
    isSort: true,
  },
  {
    label: 'Created On',
    value: 'Created On',
    columnSpan: 1,
    sortColumn: 'CreatedOn',
    isSort: true,
  },
  {
    label: 'Dispatched On',
    value: 'Dispatched On',
    columnSpan: 1,
    sortColumn: 'DispatchedOn',
    isSort: true,
  },
  {
    label: 'Origin',
    value: 'Origin',
    columnSpan: 1,
    sortColumn: 'Origin',
    isSort: true,
  },
  {
    label: 'Priority',
    value: 'Priority',
    columnSpan: 1,
    sortColumn: 'Priority',
    isSort: true,
  },
  {
    label: 'Resolution Method',
    value: 'Resolution Method',
    columnSpan: 1,
    sortColumn: 'ResolutionMethod',
    isSort: true,
  },
  {
    label: 'Resolved On',
    value: 'Resolved On',
    columnSpan: 1,
    sortColumn: 'ResolvedOn',
    isSort: true,
  },
  {
    label: 'Root Cause',
    value: 'RootCause',
    columnSpan: 1,
    sortColumn: 'RootCause',
    isSort: true,
  },
  {
    label: 'Sub Cause',
    value: 'SubCause',
    columnSpan: 1,
    sortColumn: 'SubCause',
    isSort: true,
  },
  {
    label: 'Updated On',
    value: 'Updated On',
    columnSpan: 1,
    sortColumn: 'UpdatedOn',
    isSort: true,
  },
];

export const SERVICE_TRIPS_EXPORT_HEADER: any = [
  {
    label: 'Site',
    value: 'site',
    columnSpan: 1,
    sortColumn: 'Site',
    isSort: true,
  },
  {
    label: 'Company Number',
    value: 'number',
    columnSpan: 1,
    sortColumn: 'CompanyNumber',
    isSort: true,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 1,
    sortColumn: 'Status',
    isSort: true,
  },

  {
    label: 'Service Request Number',
    value: 'casenumber',
    columnSpan: 1,
    sortColumn: 'ServiceRequestNumber',
    isSort: true,
  },
  {
    label: 'SR Title',
    value: 'Title',
    columnSpan: 1,
    sortColumn: 'Title',
    isSort: true,
  },
  {
    label: 'Work Order Number',
    value: 'Workorder',
    columnSpan: 1,
    sortColumn: 'WorkOrderNumber',
    isSort: true,
  },
  {
    label: 'Type',
    value: 'type',
    columnSpan: 1,
    sortColumn: 'Type',
    isSort: true,
  },
  {
    label: 'Priority',
    value: 'Priority',
    columnSpan: 1,
    sortColumn: 'Priority',
    isSort: true,
  },
  {
    label: 'Phone Number',
    value: 'PhoneNumber',
    columnSpan: 1,
    sortColumn: 'PhoneNumber',
    isSort: true,
  },
  {
    label: 'Created Date',
    value: 'casenumber',
    columnSpan: 1,
    sortColumn: 'CreatedOn',
    isSort: true,
  },
  {
    label: 'Scheduled Date',
    value: 'ScheduledDate',
    columnSpan: 1,
    sortColumn: 'ScheduledDate',
    isSort: true,
  },
  {
    label: 'Resource',
    value: 'ScheduledResource',
    columnSpan: 1,
    sortColumn: 'ScheduledResource',
    isSort: true,
  },
  {
    label: 'Address',
    value: 'address',
    columnSpan: 1,
    sortColumn: 'Address',
    isSort: true,
  },
  {
    label: 'City',
    value: 'city',
    columnSpan: 1,
    sortColumn: 'City',
    isSort: true,
  },
  {
    label: 'State',
    value: 'state',
    columnSpan: 1,
    sortColumn: 'State',
    isSort: true,
  },
  {
    label: 'Zip',
    value: 'zip',
    columnSpan: 1,
    sortColumn: 'Zip',
    isSort: true,
  },
];
export const SECURITY_EVENTS_EXPORTS_HEADER = [
  {
    label: 'Site Name',
    value: 'siteName',
    columnSpan: 2.5,
    sortColumn: 'SiteName',
    isSort: false,
  },
  {
    label: 'Event Name',
    value: 'eventName',
    columnSpan: 2,
    sortColumn: 'EventName',
    isSort: false,
  },
  {
    label: 'Event Category',
    value: 'eventCategory',
    columnSpan: 2,
    sortColumn: 'EventCategory',
    isSort: false,
  },
  {
    label: 'Time of Alarm',
    value: 'timeOfAlarm',
    columnSpan: 2,
    sortColumn: 'AlarmTime',
    isSort: false,
  },
  /* {
    label: 'Area',
    value: 'area',
    columnSpan: 0.7,
    sortColumn: 'Area',
    isSort: false,
  }, */
  {
    label: 'Zone',
    value: 'zone',
    columnSpan: 1.5,
    sortColumn: 'Zone',
    isSort: false,
  },
  {
    label: 'Resolution',
    value: 'resolution',
    columnSpan: 2,
    sortColumn: 'Resolution',
    isSort: false,
  },
];
export const SORT_OBJECT = { sortColumn: '', sortBy: '' };

export const GRAPHS_TITLE = {
  NETWOTK: 'Network Status Trend chart',
  SITE_STATUS: 'Current site status',
  SERVICE_BY_CAUSE: 'Service Requests by Cause',
  SERVICE_BY_SUBJECT: 'Service Requests by Subject',
};

export const GRAPH_FILTER: any = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
];

export const REFRESH_FILTER: any = [
  { label: '1 Min', value: 60000 },
  { label: '2 Min', value: 120000 },
  { label: '3 Min', value: 180000 },
  { label: '4 Min', value: 240000 },
  { label: '5 Min', value: 300000 },
  { label: '6 Min', value: 360000 },
  { label: '7 Min', value: 420000 },
  { label: '8 Min', value: 480000 },
  { label: '9 Min', value: 540000 },
  { label: '10 Min', value: 6000000 },
];
export const SECURITY_MANAGER_FILTER: any = [
  { id: 1, label: 'Employee Name', value: 'employeeName', isChecked: true },
  { id: 2, label: 'Employee ID', value: 'employeeId', isChecked: false },
  { id: 3, label: 'Site Name', value: 'siteName', isChecked: false },
  { id: 4, label: 'Company Number', value: 'companyNumber', isChecked: false },
  {
    label: 'Address (including city and state)',
    value: 'address',
    isChecked: false,
  },
];
export const REQUEST_URLS_TITLE: any = [
  'Bosch Intrusion Dashboard',
  'Cradlepoint',
  'Openeye Web Services',
];

export const USER_NOTIFICATION_LIST: any = [
  {
    label: 'Title',
    value: 'Title',
    columnSpan: 4,
  },
  {
    label: 'Message',
    value: 'message',
    columnSpan: 8,
  },
];

export const ANALYTICS_CHARTS_CRITERIA = {
  interval: '1day',
};
export const NEW_RELASE_NOTIFICATION_LIST: any = [
  {
    label: 'Title',
    value: 'title',
    columnSpan: 3,
  },
  {
    label: 'Message',
    value: 'message',
    columnSpan: 5,
  },
  {
    label: 'Start Date',
    value: 'start_date',
    columnSpan: 2,
  },
  {
    label: 'End Date',
    value: 'end_date',
    columnSpan: 2,
  },
];
export const TITLE_MAX_LIMIT = 50;
export const DESC_MAX_LIMIT = 580;
export const SR_TITLE_MAX_LIMIT = 400;
export const NOTIFICATION_DESC_MAX_LIMIT = 3000;
export const REASON_MAX_LIMIT = 100;
export const GLOBAL_SEARCH_LIMIT = 20;
export const CATEGORY_ALL = 0;
export const VIDEO = {
  VIEWER: 2,
  LAUNCHER: 1,
};
export const FILE_EXPORT_NAME = {
  SITE: 'OVE_Sites',
  SYSTEM: 'OVE_System',
  NETWORK: 'OVE_Managed_Network',
  SERVICE_REQUEST: 'OVE_Service Requests',
  SERVICE_TRIPS: 'OVE_Service Trips',
  SECURITY_EVENT: 'OVE_Security Events',
  ACTIVITY_LOG: 'ActivityLog',
  SM_All_ACTIVITY: 'All_Activity',
  SM_OPEN_CLOSE_ACTIVITY: 'All_Open_Close_Activity',
  SM_USER_CONTACT: 'User_Contact',
};
export const FILE_EXPORT_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_1 = 'MM/DD/YYYY HH:mm';
export const DATE_FORMAT_2 = 'MM/DD/YYYY';
export const DATE_FORMAT_3 = 'LTS MM-DD-YYYY';
export const DATE_FORMAT_4 = 'MM/DD/YYYY hh:mm A';
export const DATE_FORMAT_5 = 'hh:mm:ss a MM-DD-YYYY';
export const DATE_FORMAT_6 = 'MM-DD-YYYY  hh:mm:ssa';
export const DATE_FORMAT_7 = 'MM/DD/YYYY hh:mm a';
export const DATE_FORMAT_8 = 'MM-DD-YYYY HH:mm';
export const NO_CAMERA_MESSAGE = 'No cameras available';
export const VIDEO_SURVILLENCE = 'Video Surveillance';
export const DATE_FORMAT = 'MM-DD-YYYY hh:mm';
export const DATEPICKER_FORMAT = 'YYYY/MM/DD hh:mm';
export const DATEPICKER_DATE_ONLY = 'MM/dd/yyyy';
export const ACTIVITY_DATE = 'MM-DD-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_AP = 'hh:mm a';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
export const PASSWORD_LENGTH = 16;
export const MAX_PASSCODE_CODE_LENGTH = '4';
export const MAX_ALARM_CODE_LENGTH = '40';
export const MAX_ACTIVITY_LOG_LIMIT = 20;
export const ACTIVITY_LOG_VIEW = 'view';
export const ACTIVITY_LOG_EXPORT = 'export';
export const TIMEZONE_TEXT = 'TimeZone';
export const PREFERED_OTHER = 'Other';
export const WINDOW_SIZE = {
  HEIGHT_MD: 768,
};
export const MAX_HEADER_ITEMS = 8;
export const CALL_LIST_POINT_CONTACT = [1, 3];
export const TEST_HOURS_LIMIT = {
  MIN: 0,
  MAX: 24,
};
export const LOG_TYPE = {
  ONTEST: 'O',
  SUMMARY: 'S',
  DETAILS: 'D',
};
export const EVENT_MAX_LIMIT = 10;
export const DEFAULT_PAGE = { page: 1 };
export const KEEP_ACTIVE_COMPANY_SITE_TABS = [2, 3, 5];
export const TOTAL_TABS = 5;
export const SR_ITEM_LIST_LIMIT = 20;
export const SR_ITEM_LIST_OFFSET = 0;
export const SR_ITEM_FIELDS_MAXLENGTH = {
  TITLE: 100,
  DESCRIPTION: 1000,
  COMMENT: 500,
};
export const DEFAULT_PASSCODE = [
  {
    passcode: '2580',
    isGlobal: true,
  },
  {
    passcode: '1234',
    isGlobal: true,
  },
];
export const SORT_BY = {
  ASC: 'asc',
  DESC: 'desc',
};
export const REPORT_MODE = {
  VIEW: 'view',
  EXPORT: 'export',
};
export const IMPERSONATE_BUTTON_DISABLE = [
  'Impersonate',
  'Delete',
  'Save',
  'Bulk Upload',
  'Put Site on Test',
  'Stop Test',
  'Update',
  'Submit idea',
  'Submit Service Request',
  'add',
  'Remove',
  'save',
  'delete',
  'export',
  'ProjectorMode',
];
export const SITE_MANAGER_TAB = {
  SITE: 1,
  NETWORK: 2,
  SERVICE_REQUEST: 3,
  SERVICE_TRIP: 4,
  SECURITY_EVENTS: 5,
};
export const TRIP_KEY_MAPPING: any = {
  SiteId: 'Site Number',
  Alert: 'Alert',
  CreatedOn: 'Created',
  Device: 'Device',
  Priority: 'Severity',
  Status: 'Status',
  ResolvedOn: 'Resolution Date',
  RootCause: 'Root Cause',
  SubCause: 'Sub Cause',
  ResolutionMethod: 'Resolution Method',
  WorkOrderNumber: 'Work Order Number',
  Type: 'Type',
  DispatchedOn: 'Closed',
  ClosedOn: 'Closed',
  ParentCase: 'Parent Case',
  Address: 'Address',
  Name: 'Site Name',
  CaseNumber: 'Case Number',
  Title: 'SR Title',
  Subject: 'Subject',
  Created: 'Created',
  UpdatedOn: 'Last Updated',
  ScheduledDate: 'Scheduled Date',
  ActualTechSiteDate: 'Actual Tech on Site Date',
  WOStatus: 'Work Order Status',
  Resource: 'Resource',
};
export const DURATION_LIST: any = [
  {
    name: 'Last 5 minutes',
    Id: '-5min',
  },
  {
    name: 'Last 15 minutes',
    Id: '-15min',
  },
  {
    name: 'Last 30 minutes',
    Id: '-30min',
  },
  {
    name: 'Last 1 hour',
    Id: '-1h',
  },
  {
    name: 'Last 3 hour',
    Id: '-3h',
  },
  {
    name: 'Last 6 hour',
    Id: '-6h',
  },
  {
    name: 'Last 12 hour',
    Id: '-12h',
  },
  {
    name: 'Last 24 hour',
    Id: '-24h',
  },
  {
    name: 'Last 2 days',
    Id: '-2d',
  },
  {
    name: 'Last 7 days',
    Id: '-7d',
  },
  {
    name: 'Last 30 days',
    Id: '-30d',
  },
  {
    name: 'Last 90 days',
    Id: '-90d',
  },
  {
    name: 'Last 6 months',
    Id: '-6mon',
  },
  {
    name: 'Last 1 year',
    Id: '-1y',
  },
  {
    name: 'Last 2 year',
    Id: '-2y',
  },
  {
    name: 'Last 5 year',
    Id: '-5y',
  },
];
export const DATE_RANGE_FORMAT: any = {
  '-5min': 'HH:mm',
  '-15min': 'HH:mm',
  '-30min': 'HH:mm',
  '-1h': 'HH:mm',
  '-3h': 'HH:mm',
  '-6h': 'HH:mm',
  '-12h': 'HH:mm',
  '-24h': 'HH:mm',
  '-2d': 'MM/DD HH:mm',
  '-7d': 'MM/DD HH:mm',
  '-30d': 'MM/DD',
  '-90d': 'MM/DD',
  '-6mon': 'MM/DD',
  '-1y': 'MM/DD',
  '-2y': 'MM/DD',
  '-5y': 'MM/DD',
};
export const NOTIFICATION_CATEGORY = [
  { id: 1, value: 'Company', name: 'company' },
  { id: 2, value: 'Site Groups', name: 'siteGroup' },
  { id: 3, value: 'Sites', name: 'site' },
];
export const INTERNAL_USER_CATEGORY = {
  id: 4,
  value: 'Internal User',
  name: 'internal',
};
export const NOTIFICATION_TYPE = [
  { id: 1, value: 'Notification', name: 'general' },
  { id: 2, value: 'New Release', name: 'new_release' },
  { id: 3, value: 'Banner', name: 'banner' },
];
export const FILTER_TYPE = [
  { id: 1, value: 'Contains', name: 'contains' },
  { id: 2, value: 'Starts With', name: 'startsWith' },
  { id: 3, value: 'Equal', name: 'equals' },
];
export const PREFERRED_CONTACT = [
  { id: 1, value: 'Mobile Phone', name: 'isMobile' },
  { id: 2, value: 'Home Phone', name: 'isMobile' },
];
export const SEND_PASSCODE_TYPE = [
  { id: 1, value: 'Email', name: 'Email' },
  { id: 2, value: 'Text', name: 'Text' },
];
export const EXISTING_OPEN_CASES: any = [
  {
    label: 'Case',
    value: 'case',
    columnSpan: 2,
  },
  {
    label: 'Subject',
    value: 'subject',
    columnSpan: 2,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },
  {
    label: 'Created on',
    value: 'createdOn',
    columnSpan: 1,
  },
  {
    label: 'Title',
    value: 'title',
    columnSpan: 3,
  },
  {
    label: 'Priority',
    value: 'priority',
    columnSpan: 1,
  },

  {
    label: 'Last Updated',
    value: 'lastupdated',
    columnSpan: 1,
  },
];
export const BULK_UPLOAD: any = [
  {
    id: 1,
    label: 'File Size:',
    value: 'Max 2 MB',
    isError: false,
    error: 'size is larger than 2 MB',
    limit: 2000000,
    type: 'size',
    info: false,
    tooltip: '',
    errorTitle: '',
  },
  {
    id: 2,
    label: 'File Format:',
    value: '.xlsx',
    isError: false,
    error: '.xlsx only!',
    limit: ['xlsx'],
    type: 'format',
    info: false,
    tooltip: '',
    errorTitle: ERROR_TEXT.BULK_UPLOAD,
  },
];
export const SR_UPLOAD: any = [
  {
    id: 1,
    label: 'File Size:',
    value: 'Max 5 MB',
    isError: false,
    error: 'size is larger than 5 MB',
    limit: 5000000,
    type: 'size',
    info: false,
    tooltip: '',
    errorTitle: ERROR_TEXT.SR_FILE_SIZE,
  },
  {
    id: 2,
    label: 'File Format:',
    value: '.jpeg,.png,..etc.',
    isError: false,
    error: '.jpeg,.png,..etc',
    limit: ['pdf', 'doc', 'docx', 'xlsx', 'csv', 'jpg', 'jpeg', 'png', 'txt'],
    type: 'format',
    info: true,
    tooltip:
      'Supported Format- .pdf, .doc, .docx, .xlsx, .csv, .jpg, .jpeg, .png, .txt',
    errorTitle: ERROR_TEXT.SR_FILE_FORMAT,
  },
  {
    id: 3,
    label: 'Files Allowed:',
    value: 'Max 10',
    isError: false,
    error: 'Max 10 Files allowed.',
    type: 'filescount',
    info: false,
    tooltip: '',
    errorTitle: ERROR_TEXT.SR_FILE_ALLOWED,
  },
];
export const SITE_GROUP_UPLOAD_STATUS: any = [
  {
    label: 'Site Group Name',
    value: 'SiteGroup',
    columnSpan: 4,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },
  {
    label: 'Reason',
    value: 'reason',
    columnSpan: 6,
  },
];
export const USER_UPLOAD_STATUS: any = [
  {
    label: 'User Name',
    value: 'username',
    columnSpan: 4,
  },
  {
    label: 'Status',
    value: 'status',
    columnSpan: 2,
  },
  {
    label: 'Reason',
    value: 'reason',
    columnSpan: 6,
  },
];
export const UPLOADED_LIST: any = [
  {
    label: 'File Name',
    value: 'filename',
    columnSpan: 5,
  },
  {
    label: 'Uploaded On',
    value: 'uploadedon',
    columnSpan: 5,
  },
  {
    label: 'Controls',
    value: 'controls',
    columnSpan: 2,
  },
];
export const SECURITY_MANAGER_ACTIVITY_REPORT: any = [
  {
    label: 'Date',
    value: 'date',
    columnSpan: 1,
    sortColumn: 'Date',
    isSort: true,
  },
  {
    label: 'Time',
    value: 'time',
    columnSpan: 1,
    sortColumn: 'Time',
    isSort: true,
  },
  {
    label: 'Event Type',
    value: 'eventType',
    columnSpan: 1,
    sortColumn: 'Event Type',
    isSort: true,
  },
  {
    label: 'Event Category',
    value: 'eventCategory',
    columnSpan: 1.5,
    sortColumn: 'Event Category',
    isSort: true,
  },
  {
    label: 'Event Name',
    value: 'eventName',
    columnSpan: 1.5,
    sortColumn: 'Event Name',
    isSort: true,
  },
  {
    label: 'User Name',
    value: 'userName',
    columnSpan: 1.5,
    sortColumn: 'User Name',
    isSort: true,
  },
  {
    label: 'Zone Number',
    value: 'zoneNumber',
    columnSpan: 1,
    sortColumn: 'Zone Number',
    isSort: true,
  },

  {
    label: 'Zone Description',
    value: 'zoneDescription',
    columnSpan: 2,
    sortColumn: 'Zone Description',
    isSort: true,
  },
  {
    label: 'Manitou ID',
    value: 'siteName',
    columnSpan: 1,
    sortColumn: 'Manitou Site Id',
    isSort: true,
  },
  {
    label: 'Customer Name',
    value: 'siteName',
    columnSpan: 1,
    sortColumn: 'User Name',
    isSort: true,
  },
  {
    label: 'Store Number',
    value: 'siteName',
    columnSpan: 1,
    sortColumn: 'Store Number',
    isSort: true,
  },
  {
    label: 'Dealer',
    value: 'siteName',
    columnSpan: 1,
    sortColumn: 'Manitou Dealer',
    isSort: true,
  },
  {
    label: 'Region',
    value: 'siteName',
    columnSpan: 0.5,
    sortColumn: 'Region',
    isSort: true,
  },
  {
    label: 'District',
    value: 'siteName',
    columnSpan: 0.5,
    sortColumn: 'District',
    isSort: true,
  },
];
export const SECURITY_MANAGER_OPEN_CLOSE: any = [
  {
    label: 'Date',
    value: 'date',
    columnSpan: 1,
    sortColumn: 'Date',
    isSort: true,
  },
  {
    label: 'Time',
    value: 'time',
    columnSpan: 1,
    sortColumn: 'Time',
    isSort: true,
  },
  {
    label: 'Event Name',
    value: 'eventName',
    columnSpan: 1,
    sortColumn: 'Event Name',
    isSort: true,
  },
  {
    label: 'Area',
    value: 'area',
    columnSpan: 0.5,
    sortColumn: 'Area',
    isSort: true,
  },
  {
    label: 'Manitou ID',
    value: 'manitouId',
    columnSpan: 1,
    sortColumn: 'Manitou ID',
    isSort: true,
  },
  {
    label: 'Customer Name',
    value: 'customerName',
    columnSpan: 1.5,
    sortColumn: 'Site Name',
    isSort: true,
  },
  {
    label: 'Store Number',
    value: 'storeNumber',
    columnSpan: 1,
    sortColumn: 'Store Number',
    isSort: true,
  },
  {
    label: 'Dealer',
    value: 'dealer',
    columnSpan: 1,
    sortColumn: 'Dealer',
    isSort: true,
  },
  {
    label: 'Region',
    value: 'region',
    columnSpan: 1,
    sortColumn: 'Region',
    isSort: true,
  },
  {
    label: 'District',
    value: 'district',
    columnSpan: 1,
    sortColumn: 'District',
    isSort: true,
  },
  {
    label: 'User Number',
    value: 'usernumber',
    columnSpan: 1,
    sortColumn: 'User Number',
    isSort: true,
  },
  {
    label: 'User Name',
    value: 'username',
    columnSpan: 1,
    sortColumn: 'User Name',
    isSort: true,
  },
];
export const SECURITY_MANAGER_USER_CONTACT: any = [
  {
    label: 'Serial No',
    value: 'serialNo',
    columnSpan: 1,
    sortColumn: 'SERIALNO',
    isSort: true,
  },
  {
    label: 'Customer ID',
    value: 'customerId',
    columnSpan: 2,
    sortColumn: 'Customer ID',
    isSort: true,
  },
  {
    label: 'Accounting No.',
    value: 'accountingNo',
    columnSpan: 2,
    sortColumn: 'Accounting No',
    isSort: true,
  },
  {
    label: 'Store',
    value: 'store',
    columnSpan: 1,
    sortColumn: 'Store',
    isSort: true,
  },
  {
    label: 'Customer Name',
    value: 'customerName',
    columnSpan: 3,
    sortColumn: 'Customer Name',
    isSort: true,
  },
  {
    label: 'Username',
    value: 'userName',
    columnSpan: 2,
    sortColumn: 'User Name',
    isSort: true,
  },
  {
    label: 'User Slot',
    value: 'userslot',
    columnSpan: 1,
    sortColumn: 'User Slot',
    isSort: true,
  },
];
export const SERVICES = [
  'CCTV',
  'VOIP',
  'BURG',
  'IPNETW',
  'FIRE',
  'ACCESS_CONTROL',
  'FIRE_INTRUSION_COMBO',
];

export const SITE_CONTACT_INITIAL_STATE = {
  ContactFirstName: '',
  ContactLastName: '',
  FormattedPhoneNumber: '',
  Email: '',
  SUPContactFirstName: '',
  SUPContactLastName: '',
  SUPFormattedPhoneNumber: '',
  SUPEmail: '',
  ESCContactFirstName: '',
  ESCContactLastName: '',
  ESCFormattedPhoneNumber: '',
  ESCSUPEmail: '',
  type: '',
};
