import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Grid)(({ theme }) => ({
  width: '100%',
  background: theme.palette.customBackground.primary,
  borderRadius: '0.5rem',
  padding: '1rem',
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
  width: '50% !important',
  [theme.breakpoints.down('xxl')]: {
    width: '40% !important',
  },
  [theme.breakpoints.down('xl')]: {
    width: '50% !important',
  },
  [theme.breakpoints.down('lg')]: {
    width: '60% !important',
  },
  [theme.breakpoints.down('md')]: {
    width: '80% !important',
  },
  backgroundColor: theme.palette.neutral.light,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: ' translate(-50%, -50%)',
  borderRadius: '0.5rem',
}));

export const MessageContainer = styled(Grid)(({ theme }) => ({
  width: '100%',
  height: '25rem',
  padding: '1rem',
  wordWrap: 'break-word',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.625rem',
  },
  '::-webkit-scrollbar-thumb': {
    background: theme.palette.customBackground.containerScroll,
    borderRadius: '0.625rem',
  },

  'scrollbar-color': theme.palette.customBackground.containerScroll,
  'scrollbar-width': 'auto',
  '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
    '-ms-overflow-style': 'scrollbar',
    'scrollbar-base-color': theme.palette.customBackground.containerScroll,
    'scrollbar-face-color': theme.palette.customBackground.containerScroll,
    'scrollbar-arrow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-shadow-color': theme.palette.customBackground.containerScroll,
    'scrollbar-dark-shadow-color':
      theme.palette.customBackground.containerScroll,
  },
}));
