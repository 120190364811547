import { useTheme } from '@mui/material/styles';
export default function PrintIcon({ customColour = '' }: any): JSX.Element {
  const theme = useTheme();
  const color = customColour || theme.palette.icon.main;
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.45581 12.8651V3.53174H24.4558V12.8651"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.45589 24.8652H5.78923C5.08198 24.8652 4.4037 24.5843 3.90361 24.0842C3.40351 23.5841 3.12256 22.9058 3.12256 22.1986V15.5319C3.12256 14.8247 3.40351 14.1464 3.90361 13.6463C4.4037 13.1462 5.08198 12.8652 5.78923 12.8652H27.1226C27.8298 12.8652 28.5081 13.1462 29.0082 13.6463C29.5083 14.1464 29.7892 14.8247 29.7892 15.5319V22.1986C29.7892 22.9058 29.5083 23.5841 29.0082 24.0842C28.5081 24.5843 27.8298 24.8652 27.1226 24.8652H24.4559"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.4558 19.5317H8.45581V30.1984H24.4558V19.5317Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
