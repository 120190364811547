import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectSlice = (state: RootState) =>
  state && state.admin != null ? state.admin : initialState;

export const getPermissionList = createSelector(
  [selectSlice],
  state => state.permissonList,
);
export const getPermissionGroupList = createSelector(
  [selectSlice],
  state => state.permissonGroupList,
);
export const getDeletePermissionGroup = createSelector(
  [selectSlice],
  state => state.deletePermissionGroup,
);
export const getDeletePermission = createSelector(
  [selectSlice],
  state => state.deletePermission,
);
export const getPermissionGroupCustomerList = createSelector(
  [selectSlice],
  state => state.permissonGroupCustomerList,
);
export const getUsersList = createSelector(
  [selectSlice],
  state => state.usersList,
);
export const getUserValidation = createSelector(
  [selectSlice],
  state => state.userValidation,
);
export const getUserDetails = createSelector(
  [selectSlice],
  state => state.userDetails,
);
export const getUsersCompanyList = createSelector(
  [selectSlice],
  state => state.userCompanyList,
);
export const getUserAssociateSitesList = createSelector(
  [selectSlice],
  state => state.associateSites,
);
export const getUserPermissionGroupList = createSelector(
  [selectSlice],
  state => state.userPermissionGroupList,
);
export const selectToastInfo = createSelector(
  [selectSlice],
  state => state.toastInfo,
);
export const getUserPermissionChildList = createSelector(
  [selectSlice],
  state => state.permissionChild,
);

// permission Managment
export const getUserPermissionList = createSelector(
  [selectSlice],
  state => state.permissionList,
);

export const getUserPermissionsGroupList = createSelector(
  [selectSlice],
  state => state.permissionGroupList,
);
export const getUserPermissionsGroupDetails = createSelector(
  [selectSlice],
  state => state.permissionGroupDetails,
);

export const getSearchPermission = createSelector(
  [selectSlice],
  state => state.searchPermission,
);

export const getSearchPermissionGroup = createSelector(
  [selectSlice],
  state => state.searchPermissionGroup,
);

export const getSearchPermissionUser = createSelector(
  [selectSlice],
  state => state.searchPermissionUser,
);

export const getUserPermissionGroupDetail = createSelector(
  [selectSlice],
  state => state.userPermissionGroupDetail,
);

export const getUserPermissionGroupStatus = createSelector(
  [selectSlice],
  state => state.permissionGroupStatus,
);

export const getActiveTab = createSelector(
  [selectSlice],
  state => state.CompanySiteActiveTab,
);
export const getPasswordChange = createSelector(
  [selectSlice],
  state => state.userChangePassword,
);
export const getSitesList = createSelector(
  [selectSlice],
  state => state.sitesList,
);
export const getSiteRemark = createSelector(
  [selectSlice],
  state => state.siteRemark,
);
export const getAssociateSiteList = createSelector(
  [selectSlice],
  state => state.associateSiteList,
);
export const getPatchSiteListResponse = createSelector(
  [selectSlice],
  state => state.companySelectedSites,
);
export const getUserSiteGroupsList = createSelector(
  [selectSlice],
  state => state.userSiteGroupList,
);
export const getCSSiteGroupsList = createSelector(
  [selectSlice],
  state => state.csSitesGroupList,
);
export const getCompanySiteDetails = createSelector(
  [selectSlice],
  state => state.companySiteDetails,
);
export const getCompanyAccountType = createSelector(
  [selectSlice],
  state => state.companyAccountType,
);
export const getProfilePartyNumber = createSelector(
  [selectSlice],
  state => state.profilePartyNumber,
);
export const getMasterCompanyList = createSelector(
  [selectSlice],
  state => state.masterCompanyList,
);
export const getCompanyProfileResponse = createSelector(
  [selectSlice],
  state => state.companyProfile,
);

export const getAssociateSiteGroupList = createSelector(
  [selectSlice],
  state => state.associateSiteGroupList,
);

export const getPatchCSSiteGroupListResponse = createSelector(
  [selectSlice],
  state => state.csSelectedSiteGroupList,
);
export const hasPermissionAccess = createSelector(
  [selectSlice],
  state => state.isUserHasAccessPermission,
);

export const getNotificationList = createSelector(
  [selectSlice],
  state => state.notificationList,
);

export const deleteNotification = createSelector(
  [selectSlice],
  state => state.notificationList,
);

export const deleteSiteGroup = createSelector(
  [selectSlice],
  state => state.csSitesGroupList,
);
export const getNotificationsCompanyList = createSelector(
  [selectSlice],
  state => state.notificationProps,
);
export const getNotificationDetails = createSelector(
  [selectSlice],
  state => state.notificationDetails,
);
export const getUpdatedNotification = createSelector(
  [selectSlice],
  state => state.updateNotification,
);
export const getAddNotification = createSelector(
  [selectSlice],
  state => state.addNotification,
);
export const getChronicProfileList = createSelector(
  [selectSlice],
  state => state.chronicProfileList,
);
export const getChronicProfileData = createSelector(
  [selectSlice],
  state => state.chronicProfileData,
);
export const getChronicDefinition = createSelector(
  [selectSlice],
  state => state.chronicDefinitionData,
);
export const getChronicRequest = createSelector(
  [selectSlice],
  state => state.chronicProfileRequest,
);
export const getDeleteChronicStatus = createSelector(
  [selectSlice],
  state => state.deleteChronicProfile,
);
export const getNewUser = createSelector([selectSlice], state => state.NewUser);

export const getBulkUploadStatus = createSelector(
  [selectSlice],
  state => state.BulkUploadStatus,
);
export const getBulkUploadListStatus = createSelector(
  [selectSlice],
  state => state.BulkUploadedListStatus,
);
export const getBulkUploadSiteGroupTaskList = createSelector(
  [selectSlice],
  state => state.BulkUploadSiteGroupTaskList,
);
export const getTaskStatus = createSelector(
  [selectSlice],
  state => state.BulkUploadCancelTask,
);
export const getBulkUploadModal = createSelector(
  [selectSlice],
  state => state.isOpenBulkUpload,
);
export const getImpersonateUser = createSelector(
  [selectSlice],
  state => state.ImpersonateUser,
);
export const getCloseImpersonateSession = createSelector(
  [selectSlice],
  state => state.CloseImpersonateSession,
);
export const getSitesContactDetails = createSelector(
  [selectSlice],
  state => state.sitesContactDetails,
);
export const getupdateSiteContactDetails = createSelector(
  [selectSlice],
  state => state.updateSiteContactDetails,
);
export const getCompanySitesContactDetails = createSelector(
  [selectSlice],
  state => state.companySitesContactDetails,
);
export const getupdateCompanySitesContactDetails = createSelector(
  [selectSlice],
  state => state.updateCompanySiteContactDetails,
);
export const getActiveUserTab = createSelector(
  [selectSlice],
  state => state.getActiveUserTab,
);
