export const ROUTE_BREADCRUMB: any = {
  dashboard: 'Dashboard',
  sitemanager: 'Site Manager',
  details: 'Active Service Request > Item Details',
  tripdetail: 'Service Trip Details > Item Details',
  'event-details': 'Security Event > Details',
  siteInsight: 'Insight and Analytics',
  admin: 'Administration',
  permissions: 'Permissions',
  users: 'Users',
  'bulk-upload': 'Bulk Upload > Preview',
  'site-group-realignment': 'Site Groups Realignment > Preview',
  companysite: 'Company & Site Admin',
  notification: 'Notifications',
  adduser: 'Add User',
  edituser: 'Edit User',
  helpSupport: 'Help And Support',
  download: 'Helpful Downloads',
  newrelease: 'New Release',
  submitIdea: 'Submit an Idea',
  addSiteGroup: 'Site Group > Add Site Group',
  editSiteGroup: 'Site Group > Edit Site Group',
  addSite: 'Sites > Add Site',
  editSite: 'Sites > Edit Site',
  addcompany: 'Company > Add Company',
  editcompany: 'Company > Edit Company',
  accessAlarm: 'Business Security',
  businessIntelligence: 'Business Intelligence',
  managedNetwork: 'Network & Voice',
  devices: 'Devices',
  myopenProjects: 'My Open Project',
  reports: 'Reports',
  exportData: 'Export Data',
  securityManagerReport: 'Security Manager',
  AllActivity: 'All Activity',
  OpenAndClose: 'Open/Close',
  userAndContact: 'User and Contacts',
  // digitalSurveillance: "Video Verified Alarm",
  insightsAnalytics: 'Insight And Analytics',
  openServiceRequest: 'Open a Service Request',
  profile: 'User profile',
  viewSiteDetails: 'Sites > View',
  addNotification: 'Add New Notification',
  editNotification: 'Edit Notification',
  analytics: 'Site Analytics',
  editChronic: 'Edit Chronic Profile',
  addChronic: 'Add Chronic Profile',
  partner: 'Partner Portals',
  srDetails: 'Service Trip Details > Item Details',
  securityManager: 'Security Manager',
  editContact: 'Edit Contact',
  addContact: 'Add Contact',
  elearning: 'E-Learning',
};
