import { routeTypes } from './type';
import { Navigate } from 'react-router-dom';
import { NotFoundPage } from 'app/component/layout/NotFoundPage';
import { ROUTE_PATH } from './constant';
import {
  Login,
  Logout,
  Dashboard,
  UserProfile,
  SiteManager,
  ItemDetails,
  TripsItemDetails,
  UserProfileNotification,
  OpenServiceRequest,
  InsightAndAnalytics,
  AnalyticsCharts,
  ManagedNetwork,
  BusinessSecurity,
  BusinessIntelligence,
  Devices,
  MyOpenProject,
  Users,
  Permission,
  CompanySite,
  AddUser,
  EditUser,
  Notification,
  AddNotification,
  AddNewCompany,
  ViewSiteDetails,
  AddSite,
  AddSiteGroup,
  ChronicProfile,
  Preview,
  Report,
  AllActivity,
  OpenAndClose,
  UserAndContact,
  Help,
  NewRelase,
  SubmitIdea,
  HelpAndSupport,
  SupportDownload,
  SecurityManager,
  AddContact,
  ProjectorView,
  // SecurityManagerReport,
  SecurityEventDetails,
  ELearning,
  TermsConditionAgreement,
} from 'app/pages';
import ImpersonateUser from '../ImpersonateUser';

const Pages: routeTypes[] = [
  {
    path: ROUTE_PATH.HASH,
    element: <Navigate to={`/${ROUTE_PATH.LOGIN}`} />,
    title: 'Login',
  },
  {
    path: ROUTE_PATH.IMPERSONATE_USER,
    element: <ImpersonateUser />,
    title: 'Impersonate User View',
  },
  { path: ROUTE_PATH.LOGIN, element: <Login />, title: 'Login' },
  {
    path: ROUTE_PATH.TERMS_CONDITION,
    element: <TermsConditionAgreement />,
    title: 'TermsCondition',
  },
  {
    path: ROUTE_PATH.DASHBOARD,
    element: null,
    title: 'Dashboard',
    children: [
      {
        path: '',
        element: <Dashboard />,
        title: 'Site Manager',
      },
      {
        path: ROUTE_PATH.SITE_MANAGER,
        element: <SiteManager />,
        title: 'Site Manager',
      },
      {
        path: ROUTE_PATH.ANALYTICS,
        element: <AnalyticsCharts />,
        title: 'Site Manager Analytics',
      },
      {
        path: ROUTE_PATH.SECURITY_MANAGER,
        element: <SecurityManager />,
        title: 'Security Manager',
      },
      {
        path: ROUTE_PATH.ADD_CONTACT,
        element: <AddContact />,
        title: 'Add Contact',
      },
      {
        path: ROUTE_PATH.EDIT_CONTACT,
        element: <AddContact />,
        title: 'Edit Contact',
      },
      {
        path: ROUTE_PATH.SITE_MANAGER_CASE_DETAILS,
        element: <ItemDetails />,
        title: 'Site Manager Case Details',
      },
      {
        path: ROUTE_PATH.CASE_DETAILS,
        element: <ItemDetails />,
        title: 'Case Details',
      },
      {
        path: ROUTE_PATH.SERVICE_REQUEST_DETAILS,
        element: <ItemDetails />,
        title: 'Service Request Details',
      },
      {
        path: ROUTE_PATH.SITE_MANAGER_TRIP_DETAILS,
        element: <TripsItemDetails />,
        title: 'Service Request Details',
      },
      {
        path: ROUTE_PATH.TRIP_DETAILS,
        element: <TripsItemDetails />,
        title: 'Service Request Details',
      },
      {
        path: ROUTE_PATH.SECURITY_EVENT_DETAILS,
        element: <SecurityEventDetails />,
        title: 'Security Event Details',
      },
      {
        path: ROUTE_PATH.SITE_SECURITY_EVENT_DETAILS,
        element: <SecurityEventDetails />,
        title: 'Security Event Details',
      },
    ],
  },
  {
    path: ROUTE_PATH.PROFILE,
    element: null,
    title: 'Profile',
    children: [
      {
        path: '',
        element: <UserProfile />,
        title: 'User Profile',
      },
      {
        path: ROUTE_PATH.NOTIFICATION,
        element: <UserProfileNotification />,
        title: 'User Profile Notification',
      },
    ],
  },
  {
    path: ROUTE_PATH.LOGOUT,
    element: null,
    title: 'Logout',
    children: [
      {
        path: '',
        element: <Logout />,
        title: 'Logout',
      },
    ],
  },
  {
    path: ROUTE_PATH.OPEN_SERVICE_REQUEST,
    element: <OpenServiceRequest />,
    title: 'Open Service Request',
  },
  {
    path: ROUTE_PATH.INSIGHTS_ANALYTICS,
    element: <InsightAndAnalytics />,
    title: 'Insights Analytics',
  },

  {
    path: ROUTE_PATH.PARTNER,
    element: null,
    title: 'Partner',
    children: [
      {
        path: '',
        element: <Navigate to={ROUTE_PATH.BUSINESS_INTELLIGENCE} />,
        title: 'Business Intelligence',
      },
      {
        path: ROUTE_PATH.BUSINESS_INTELLIGENCE,
        element: <BusinessIntelligence />,
        title: 'Business Intelligence',
      },
      {
        path: ROUTE_PATH.MANAGED_NETWORK,
        element: <ManagedNetwork />,
        title: 'Managed Network',
      },
      {
        path: ROUTE_PATH.ACCESS_ALARM,
        element: <BusinessSecurity />,
        title: 'Business Security',
      },
    ],
  },
  { path: ROUTE_PATH.DEVICES, element: <Devices />, title: 'Devices' },
  {
    path: ROUTE_PATH.MY_OPEN_PROJECTS,
    element: <MyOpenProject />,
    title: 'My Open Projects',
  },
  {
    path: ROUTE_PATH.ADMIN,
    element: null,
    title: 'Admin',
    children: [
      {
        path: '',
        element: <Navigate to={'/admin/permissions'} />,
        title: 'Permission',
      },
      {
        path: ROUTE_PATH.PERMISSIONS,
        element: <Permission />,
        title: 'Permission',
      },
      { path: ROUTE_PATH.USERS, element: <Users />, title: 'Users' },
      {
        path: ROUTE_PATH.COMPANY_SITE,
        element: <CompanySite />,
        title: 'Company And Site',
      },
      // {
      //   path: ROUTE_PATH.ADD_COMPANY,
      //   element: <AddNewCompany />,
      //   title: 'Add New Company',
      // },
      {
        path: ROUTE_PATH.EDIT_COMPANY,
        element: <AddNewCompany />,
        title: 'Edit Company',
      },
      {
        path: ROUTE_PATH.EDIT_SITE,
        element: <AddSite />,
        title: 'EDIT Site',
      },
      {
        path: `${ROUTE_PATH.COMPANY_SITE}/${ROUTE_PATH.ADD_SITE_GROUP}`,
        element: <AddSiteGroup />,
        title: 'Add Site Group',
      },
      {
        path: `${ROUTE_PATH.COMPANY_SITE}/${ROUTE_PATH.EDIT_SITE_GROUP}`,
        element: <AddSiteGroup />,
        title: 'Edit Site Group',
      },
      {
        path: ROUTE_PATH.VIEW_SITE_DETAILS,
        element: <ViewSiteDetails />,
        title: 'View Site Details',
      },
      {
        path: ROUTE_PATH.ADD_CHRONIC,
        element: <ChronicProfile />,
        title: 'Add Chronic',
      },
      {
        path: ROUTE_PATH.EDIT_CHRONIC,
        element: <ChronicProfile />,
        title: 'Edit Chronic',
      },
      {
        path: `${ROUTE_PATH.COMPANY_SITE}/${ROUTE_PATH.SITE_GROUP_REALIGNMENT}`,
        element: <Preview />,
        title: 'Site Group Bulk Upload Preview',
      },
      {
        path: ROUTE_PATH.NOTIFICATION,
        element: <Notification />,
        title: 'Notification',
      },
      {
        path: `${ROUTE_PATH.NOTIFICATION}/${ROUTE_PATH.ADD_NOTIFICATION}`,
        element: <AddNotification />,
        title: 'Add Notification',
      },
      {
        path: `${ROUTE_PATH.NOTIFICATION}/${ROUTE_PATH.EDIT_NOTIFICATION}`,
        element: <AddNotification />,
        title: 'Edit Notification',
      },
      { path: ROUTE_PATH.ADD_USER, element: <AddUser />, title: 'Add User' },
      {
        path: ROUTE_PATH.EDIT_USER,
        element: <EditUser />,
        title: 'Edit User',
      },
      {
        path: `${ROUTE_PATH.USERS}/${ROUTE_PATH.BULK_UPLOAD}`,
        element: <Preview />,
        title: 'User Bulk Upload Preview',
      },
    ],
  },
  {
    path: ROUTE_PATH.REPORTS,
    element: null,
    title: 'Reports',
    children: [
      {
        path: '',
        element: <Navigate to={ROUTE_PATH.EXPORT_REPORT} />,
        title: 'Reports',
      },
      { path: ROUTE_PATH.EXPORT_DATA, element: <Report />, title: 'Reports' },
      {
        path: ROUTE_PATH.SECURITY_MANAGER_REPORT,
        element: null,
        title: 'security Manager Report',
        children: [
          {
            path: '',
            element: <Navigate to={ROUTE_PATH.SM_ALL_ACTIVITY_REPORT} />,
            title: 'All Activity Reports',
          },
          {
            path: ROUTE_PATH.SM_ALL_ACTIVITY_REPORT,
            element: <AllActivity />,
            title: 'All Activity Reports',
          },
          {
            path: ROUTE_PATH.SM_OPEN_CLOSE_REPORT,
            element: <OpenAndClose />,
            title: 'Open Close Report',
          },
          {
            path: ROUTE_PATH.SM_USER_CONTACTS,
            element: <UserAndContact />,
            title: 'User And Contancts Report',
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_PATH.HELP_SUPPORT,
    element: null,
    title: 'Help Support',
    children: [
      {
        path: '',
        element: <HelpAndSupport />,
        title: 'Help Support',
      },
      {
        path: ROUTE_PATH.SUBMIT_IDEA,
        element: <SubmitIdea />,
        title: 'Submit Idea',
      },
      {
        path: ROUTE_PATH.DOWNLOAD,
        element: <SupportDownload />,
        title: 'Download',
      },
      {
        path: ROUTE_PATH.NEW_RELEASE,
        element: <NewRelase />,
        title: 'New Release',
      },
      {
        path: ROUTE_PATH.E_LEARNING,
        element: <ELearning />,
        title: 'E-Learing',
      },
    ],
  },
  { path: ROUTE_PATH.HELP, element: <Help />, title: 'Help' },
  {
    path: ROUTE_PATH.PROJECTOR_VIEW,
    element: <ProjectorView />,
    title: 'Projector View',
  },
  {
    path: '*',
    element: <NotFoundPage />,
    title: 'Not Found',
  },
];

export default Pages;
