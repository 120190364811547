import * as React from 'react';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@mui/material';
import styled from 'styled-components';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { COLORS } from 'styles/colors';
import { checkIEVersion, useDebounce } from 'utils/CommonFn';
import { SearchIcon } from 'assets/component';

const Wrapper = styled(Select)`
width: "100%",
borderRadius: "0.5rem",
`;
interface Props {
  label: string;
  options: any;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  value?: string;
  handleChange: (event: any, item: any) => void;
  onScroll?: any;
  loading?: boolean;
  ref?: any;
  fieldName?: any;
  isSearchFlag?: boolean;
  handleSearchEvent?: any;
  placeholder?: any;
  isDisabled?: boolean;
}

const CustomSelectWithSearch = ({
  label,
  options,
  style,
  labelStyle,
  value = '',
  handleChange,
  loading = false,
  onScroll = () => {},
  fieldName,
  placeholder = '',
  handleSearchEvent = () => {},
  isDisabled = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const [searchText, setSearchText] = React.useState('');

  const handleSearch = (e: any): void => {
    const text = e.target.value ? e.target.value : '';
    setSearchText(text);
  };
  const onCloseSelect = (): void => {
    setSearchText('');
  };
  const handleSelectChange = (event: any) => {
    const item = options.find(
      (element: any) => element.Id === parseInt(event.target.value),
    );
    handleChange(event, item);
  };
  React.useEffect(() => {
    handleSearchEvent(searchText);
  }, [useDebounce(searchText, 500)]);

  return (
    <>
      <FormControl size="small" style={{ width: '100%' }}>
        <InputLabel
          id="search-select-label"
          sx={{
            color: theme.palette.text.primary,
            fontSize: '14px',
            '&.MuiInputLabel-shrink': {
              color: 'transparent !important',
            },
          }}
        >
          {label}
        </InputLabel>
        <Wrapper
          sx={{
            border: 0,
            fontSize: useMediaQuery(theme.breakpoints.up('xl'))
              ? '0.875rem'
              : '0.75rem',
            borderRadius: '0.5rem',
            '&.MuiInputBase-root': {
              cursor: 'pointer',
              paddingRight: '0.625rem',
            },
            '&.MuiSelect-select': {
              padding: '10.5px 0.875rem',
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)':
              {
                '-ms-overflow-style': 'scrollbar',
                'scrollbar-base-color':
                  theme.palette.mode === 'dark'
                    ? COLORS.portage
                    : COLORS.solitude,
                'scrollbar-face-color':
                  theme.palette.mode === 'dark'
                    ? COLORS.portage
                    : COLORS.solitude,
                'scrollbar-arrow-color':
                  theme.palette.mode === 'dark'
                    ? COLORS.portage
                    : COLORS.solitude,
                'scrollbar-shadow-color':
                  theme.palette.mode === 'dark'
                    ? COLORS.portage
                    : COLORS.solitude,
              },
          }}
          labelId="simple-select-label"
          id="simple-select"
          name={fieldName}
          value={value}
          onChange={handleSelectChange}
          onClose={onCloseSelect}
          style={style}
          // IconComponent={DownArrowIconNav}
          label={''}
          native={checkIEVersion() ?? false}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  backgroundColor: theme.palette.customBackground.main,
                  '& .MuiMenuItem-root': {
                    cursor: 'default',
                    fontSize: useMediaQuery(theme.breakpoints.up('xl'))
                      ? '0.875rem'
                      : '0.75rem',
                  },
                },
              },
              PaperProps: {
                sx: {
                  borderRadius: '0.5rem',
                  overflow: 'auto',
                  maxHeight: '50%',
                  // backgroundColor: COLORS.main,
                  '::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '::-webkit-scrollbar-track': {
                    borderRadius: '0.625rem',
                  },

                  '::-webkit-scrollbar-thumb': {
                    background: theme.palette.customBackground.scroll,
                    borderRadius: '0.625rem',
                  },
                  'scrollbar-color': theme.palette.customBackground.scroll,
                  'scrollbar-width': 'auto',
                },
                onScroll: (e: any) => onScroll(e),
              },
              autoFocus: false,
            },
            'aria-label': 'Without label',
          }}
          endAdornment={
            loading && (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={20} />
              </InputAdornment>
            )
          }
          disabled={isDisabled}
        >
          <ListSubheader
            style={{
              backgroundColor: theme.palette.customBackground.main,
              padding: '0 10px',
            }}
          >
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              style={{
                marginTop: '0.4rem',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '0.45rem',
                margin: '0.55rem 0',
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '10px',
                },
              }}
              placeholder={placeholder}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: { fontSize: '0.8rem' },
              }}
              value={searchText}
              onChange={handleSearch}
              onKeyDown={(e: any) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {/* Select label item */}
          {checkIEVersion() && (
            <option disabled value={''}>
              {''}
            </option>
          )}
          {/* Listed items option for IE, menuitems for other */}
          {!checkIEVersion()
            ? options.map((item: any, id: any) => (
                <MenuItem value={item.id || item.Id} key={id}>
                  {item.name || item.Name}
                </MenuItem>
              ))
            : options.map((item: any, id: any) => (
                <option value={`${item.Id ?? item.id}`} key={id}>
                  {item.name || item.Name}
                </option>
              ))}
        </Wrapper>
      </FormControl>
    </>
  );
};
export default React.memo(CustomSelectWithSearch);
