import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MENUICONS } from 'utils/Constants/MenuIcons';
import { COLORS } from 'styles/colors';
import { isEmpty } from 'utils/CommonFn/validators';

export const initialState: GlobalState = {
  companyList: {
    loading: true,
    companiesList: [],
    companyTypesList: [],
  },
  getCompanySiteList: {
    loading: true,
    companySiteList: [],
  },
  urls: {
    BusinessIntelligence: {
      loading: false,
      data: [],
    },
    BusinessSecurity: {
      loading: false,
      data: [],
    },
    NetworkVoices: {
      loading: false,
      data: [],
    },
    VideoVerifiedAlarm: {
      loading: false,
      data: [],
    },
    HelpSupport: {
      loading: false,
      data: [],
    },
    BusinessSecurityCombined: {
      loading: false,
      data: [],
    },
  },
  insightAnyaltics: {
    ServiceReqByCause: {
      loading: false,
      data: [],
    },
    ServiceReqBySubject: {
      loading: false,
      data: [],
    },
    CurrentSiteStatus: {
      loading: false,
      data: [],
    },
    NetworkTrendProps: {
      loading: false,
      data: [],
    },
  },
  sericeRequestSiteList: {
    loading: true,
    data: [],
  },
  affectedSystemList: {
    loading: false,
    data: [],
  },
  serviceRequest: {
    loading: true,
    data: [],
    status: '',
  },
  userProfileInfo: { loading: false, data: [] },
  userProfileViewSetting: {
    loading: false,
    data: [],
  },
  submitIdeaInfo: {
    data: [],
    loading: false,
  },
  exportData: {
    Sites: {
      location: '',
      installedSystem: [],
      serviceLevel: [],
      column: [],
    },
    System: {
      location: '',
      billingStatus: [],
      systemType: [],
      column: [],
    },
    Network: {
      location: '',
      networkStatus: [],
      switchStatus: [],
      column: [],
    },
    ServiceRequest: {
      location: '',
      serviceRequestNumber: '',
      srSubject: [],
      srStatus: [],
      severity: [],
      column: [],
    },
    ServiceTrip: {
      location: '',
      workOrderNumber: '',
      stStatus: [],
      column: [],
    },
  },
  exportDataShowListonSearch: false,

  exportDataSiteInfo: {
    data: [],
    loading: true,
  },
  exportDataSystemInfo: {
    data: [],
    loading: false,
  },
  exportDataSecurityEventInfo: {
    data: [],
    loading: false,
  },
  exportDataNetworkInfo: {
    data: [],
    loading: false,
  },
  exportDataServiceRequestInfo: {
    data: [],
    loading: false,
  },
  exportDataServiceTripInfo: {
    data: [],
    loading: false,
  },
  jitterAnalyticsData: {
    loading: true,
    data: [],
  },
  mosAnalyticsData: {
    loading: true,
    data: [],
  },
  plossAnalyticsData: {
    loading: true,
    data: [],
  },
  wuplink1AnalyticsData: {
    loading: true,
    data: [],
  },
  wuplink2AnalyticsData: {
    loading: true,
    data: [],
  },
  userNotifcationList: {
    data: [],
    loading: false,
  },
  readUserNotification: {
    data: [],
    loading: false,
  },
  globalUserNotifcationList: {
    data: [],
    loading: false,
  },
  downloadSupport: {
    data: [],
    loading: false,
  },
  userSystems: {
    data: [],
    loading: false,
  },
  globalSearchList: {
    data: [],
    loading: false,
  },
  exportStateList: {
    data: [],
    loading: true,
  },
  analyticsTimeFilter: {
    networkChartInterval: 300000,
    networkChartDays: 30,
    siteStatusChartInterval: 300000,
    byCauseChartInterval: 300000,
    byCauseChartDays: 30,
    bySubjectChartInterval: 300000,
    bySubjectChartDays: 30,
    wan1ChartInterval: 300000,
    wan2ChartInterval: 300000,
    plossChartInterval: 300000,
    jitterChartInterval: 300000,
    mosChartInterval: 300000,
  },
  siteAnalyticsTimeRange: '-24h',
  bannerNotifcationList: {
    data: [],
    loading: false,
  },
  readUserBannerNotification: {
    data: [],
    loading: false,
  },
  newReleaseNotifcationList: {
    data: [],
    loading: false,
  },
  exportAllActivty: {
    data: [],
    loading: false,
  },
  exportOpenCloseActivty: {
    data: [],
    loading: false,
  },
  securityManagerFilter: {
    page: 1,
    sortColumn: '',
    sortBy: 'DESC',
    site: [],
    siteGroup: [],
    startdt: '',
    enddt: '',
  },
  securityManagerSiteList: {
    loading: false,
    data: [],
  },
  securityManagerSiteGroupList: {
    loading: false,
    data: [],
  },
  exportUserContanct: {
    data: [],
    loading: false,
  },
  elearningUrls: {
    data: [],
    loading: false,
  },
  onlineManuals: {
    data: [],
    loading: false,
  },
  termsConditions: {
    data: [],
    loading: false,
  },
  acceptAgreement: {
    data: [],
    loading: false,
  },
};
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    fetchCompanyList(state, action: PayloadAction<any>) {
      state.companyList = { ...state.companyList };
    },
    updateCompanyList(state, action: PayloadAction<any>) {
      state.companyList = {
        ...state.companyList,
        ...action.payload,
      };
    },
    fetchCompanySiteList(state, action: PayloadAction<any>) {
      state.getCompanySiteList = { ...state.getCompanySiteList, loading: true };
    },
    updateCompanySiteList(state, action: PayloadAction<any>) {
      state.getCompanySiteList = {
        ...state.getCompanySiteList,
        ...action.payload,
      };
    },
    fetchBusinessIntelligence(state, action: PayloadAction<any>) {
      state.urls.BusinessIntelligence = {
        ...state.urls.BusinessIntelligence,
        loading: true,
      };
    },
    updateBusinessIntelligence(state, action: PayloadAction<any>) {
      state.urls.BusinessIntelligence = {
        ...action.payload,
      };
      if (state.urls.BusinessIntelligence) {
        const data: any = state?.urls?.BusinessIntelligence?.data[0];
        if (Object.keys(data) && Object.keys(data).length > 0) {
          const menuDetails: any = Object.values(data).map((item: any) => {
            const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
            return {
              ...item,
              imageUrl: MENUICONS[IconName],
            };
          });
          state.urls.BusinessIntelligence.data = menuDetails;
        }
      }
    },
    fetchBusinessSecurity(state, action: PayloadAction<any>) {
      state.urls.BusinessSecurity = {
        ...state.urls.BusinessSecurity,
        loading: true,
      };
    },
    updateBusinessSecurity(state, action: PayloadAction<any>) {
      state.urls.BusinessSecurity = {
        ...action.payload,
      };
      if (state.urls.BusinessSecurity) {
        const data = state.urls.BusinessSecurity.data[0];
        if (Object.keys(data) && Object.keys(data).length > 0) {
          const menuDetails = Object.values(data).map((item: any) => {
            const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
            return {
              ...item,
              imageUrl: MENUICONS[IconName],
            };
          });
          state.urls.BusinessSecurity.data = menuDetails;
        }
      }
    },
    fetchBusinessSecurityCombined(state, action: PayloadAction<any>) {
      state.urls.BusinessSecurityCombined = {
        ...state.urls.BusinessSecurityCombined,
        loading: true,
      };
    },
    updateBusinessSecurityCombined(state, action: PayloadAction<any>) {
      state.urls.BusinessSecurityCombined = {
        ...action.payload,
      };
      const responseData = state.urls.BusinessSecurityCombined.data || [];
      if (responseData) {
        const businessSecurityData = responseData?.businessSecurity[0];
        const videoVerifedData = responseData?.vvw[0];

        let businessSecurity: any;
        let videoVerifed: any;

        if (businessSecurityData && typeof businessSecurityData === 'object') {
          businessSecurity = Object.values(businessSecurityData).map(
            (item: any) => {
              const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
              return {
                ...item,
                imageUrl: MENUICONS[IconName],
              };
            },
          );
        }
        if (videoVerifedData && typeof videoVerifedData === 'object') {
          videoVerifed = Object.values(videoVerifedData).map((item: any) => {
            const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
            return {
              ...item,
              imageUrl: MENUICONS[IconName],
            };
          });
        }
        state.urls.BusinessSecurityCombined.data = [
          { businessSecurity, videoVerifed },
        ];
      }
    },
    fetchNetworkVoices(state, action: PayloadAction<any>) {
      state.urls.NetworkVoices = { ...state.urls.NetworkVoices, loading: true };
    },
    updateNetworkVoices(state, action: PayloadAction<any>) {
      state.urls.NetworkVoices = {
        ...action.payload,
      };
      if (state.urls.NetworkVoices) {
        const data = state.urls.NetworkVoices.data[0];
        if (Object.keys(data) && Object.keys(data).length > 0) {
          const menuDetails = Object.values(data).map((item: any) => {
            const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
            return {
              ...item,
              imageUrl: isEmpty(MENUICONS[IconName])
                ? MENUICONS.default
                : MENUICONS[IconName],
            };
          });
          state.urls.NetworkVoices.data = menuDetails;
        }
      }
    },
    fetchVideoVerifiedAlarm(state, action: PayloadAction<any>) {
      state.urls.VideoVerifiedAlarm = {
        ...state.urls.VideoVerifiedAlarm,
        loading: true,
      };
    },
    updateVideoVerifiedAlarm(state, action: PayloadAction<any>) {
      state.urls.VideoVerifiedAlarm = {
        ...action.payload,
      };
      if (state.urls.VideoVerifiedAlarm) {
        const data = state.urls.VideoVerifiedAlarm.data[0];
        if (Object.keys(data) && Object.keys(data).length > 0) {
          const menuDetails = Object.values(data).map((item: any) => {
            const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
            return {
              ...item,
              imageUrl: MENUICONS[IconName],
            };
          });
          state.urls.VideoVerifiedAlarm.data = menuDetails;
        }
      }
    },
    fetchHelpSupport(state, action: PayloadAction<any>) {
      state.urls.HelpSupport = { ...state.urls.HelpSupport, loading: true };
    },
    updateHelpSupport(state, action: PayloadAction<any>) {
      state.urls.HelpSupport = {
        ...action.payload,
      };
      if (state.urls.HelpSupport) {
        const data = state.urls.HelpSupport.data[0];
        if (Object.keys(data) && Object.keys(data).length > 0) {
          const menuDetails = Object.values(data).map((item: any) => {
            const IconName: any = item.title.replace(/\s/g, '').toLowerCase();
            return {
              ...item,
              imageUrl: MENUICONS[IconName],
            };
          });
          state.urls.HelpSupport.data = menuDetails;
        }
      }
    },
    clearSiteList(state, action: PayloadAction<any>) {
      state.getCompanySiteList = {
        companySiteList: [],
        loading: false,
      };
    },
    fetchServiceRequestByCause(state, action: PayloadAction<any>) {
      state.insightAnyaltics.ServiceReqByCause = {
        ...state.insightAnyaltics.ServiceReqByCause,
        loading: true,
      };
    },
    updateServiceRequestByCause(state, action: PayloadAction<any>) {
      state.insightAnyaltics.ServiceReqByCause = {
        ...state.insightAnyaltics.ServiceReqByCause,
        ...action.payload,
      };
    },
    fetchServiceRequestBySubject(state, action: PayloadAction<any>) {
      state.insightAnyaltics.ServiceReqBySubject = {
        ...state.insightAnyaltics.ServiceReqBySubject,
        loading: true,
      };
    },
    updateServiceRequestBySubject(state, action: PayloadAction<any>) {
      state.insightAnyaltics.ServiceReqBySubject = {
        ...state.insightAnyaltics.ServiceReqBySubject,
        ...action.payload,
      };
      // if (state.insightAnyaltics.ServiceReqBySubject.data != null) {
      //   const maxValue = Math.max(
      //     ...state.insightAnyaltics.ServiceReqBySubject.data.map(
      //       (o: any) => o.RecordCount,
      //     ),
      //   );
      //   const emptyData = {
      //     RootParentSubject: "",
      //     ParentSubject: "",
      //     Subject: "",
      //     RootCause: "",
      //     RootCauseFull: "",
      //     RecordCount: maxValue + 500,
      //   };
      //   state.insightAnyaltics.ServiceReqBySubject.data.push({ emptyData });
      // }
    },
    fetchCurrentSiteStatus(state, action: PayloadAction<any>) {
      state.insightAnyaltics.CurrentSiteStatus = {
        ...state.insightAnyaltics.CurrentSiteStatus,
        loading: true,
      };
    },
    updateCurrentSiteStatus(state, action: PayloadAction<any>) {
      state.insightAnyaltics.CurrentSiteStatus = {
        ...state.insightAnyaltics.CurrentSiteStatus,
        ...action.payload,
      };
      if (state.insightAnyaltics?.CurrentSiteStatus.data != null) {
        const donutData: any = [];
        const data = state.insightAnyaltics?.CurrentSiteStatus?.data || [];
        if (data && data.length > 0) {
          if (data[0]?.Down) {
            donutData.push({
              label: 'Down',
              value: data[0].Down,
              fill: COLORS.downColor,
              stroke: COLORS.downColor,
            });
          }
          if (data[0]?.Warning) {
            donutData.push({
              label: 'Warning',
              value: data[0].Warning,
              fill: COLORS.backupColor,
              stroke: COLORS.backupColor,
            });
          }
          if (data[0]?.Up) {
            donutData.push({
              label: 'Up',
              value: data[0].Up,
              fill: COLORS.primaryColor,
              stroke: COLORS.primaryColor,
            });
          }
          if (data[0]?.UNKNOWN) {
            donutData.push({
              label: 'Unknown',
              value: data[0].UNKNOWN,
              fill: COLORS.white,
              stroke: COLORS.white,
            });
          }
        }
        state.insightAnyaltics.CurrentSiteStatus.data = donutData;
      }
    },
    fetchNetworkCountByDate(state, action: PayloadAction<any>) {
      state.insightAnyaltics.NetworkTrendProps = {
        ...state.insightAnyaltics.NetworkTrendProps,
        loading: true,
      };
    },
    updateNetworkCountByDate(state, action: PayloadAction<any>) {
      state.insightAnyaltics.NetworkTrendProps = {
        ...state.insightAnyaltics.NetworkTrendProps,
        ...action.payload,
      };
    },
    fetchServiceRequestSiteList(state, action: PayloadAction<any>) {
      state.sericeRequestSiteList = {
        ...state.sericeRequestSiteList,
        loading: true,
      };
    },
    updateFetchServiceRequestList(state, action: PayloadAction<any>) {
      state.sericeRequestSiteList = {
        ...state.sericeRequestSiteList,
        ...action.payload,
      };
    },
    fetchAffectedSystemList(state, action: PayloadAction<any>) {
      state.affectedSystemList = {
        ...state.affectedSystemList,
        loading: true,
      };
    },
    updateAffectedSystemList(state, action: PayloadAction<any>) {
      state.affectedSystemList = {
        ...state.affectedSystemList,
        ...action.payload,
      };
    },
    postServiceRequest(state, action: PayloadAction<any>) {
      state.serviceRequest = {
        ...state.serviceRequest,
        loading: true,
        status: 'pending',
      };
    },
    updateServiceRequest(state, action: PayloadAction<any>) {
      state.serviceRequest = {
        ...state.serviceRequest,
        ...action.payload,
      };
    },
    fetchUserProfileViewSetting(state, action: PayloadAction<any>) {
      state.userProfileViewSetting = {
        ...state.userProfileViewSetting,
        loading: true,
      };
    },
    updateUserProfileViewSetting(state, action: PayloadAction<any>) {
      state.userProfileViewSetting = {
        ...state.userProfileViewSetting,
        ...action.payload,
      };
      if (state.userProfileViewSetting?.data != null) {
        const viewSettings =
          state.userProfileViewSetting?.data.viewerIPSetting.map(
            (key: any) => ({ name: key, id: key }),
          );
        state.userProfileViewSetting.data.viewerIPSetting = viewSettings;
      }
    },
    fetchUserProfileInfo(state, action: PayloadAction<any>) {
      state.userProfileInfo = {
        ...state.userProfileInfo,
        loading: true,
      };
    },
    updateUserProfileInfo(state, action: PayloadAction<any>) {
      state.userProfileInfo = {
        ...state.userProfileInfo,
        ...action.payload,
      };
    },
    fetchUpdateUserPrfileInfo(state, action: PayloadAction<any>) {
      state.userProfileInfo = {
        ...state.userProfileInfo,
        loading: true,
      };
    },

    postSubmitAnIdea(state, action: PayloadAction<any>) {
      state.submitIdeaInfo = {
        ...state.submitIdeaInfo,
        loading: true,
      };
    },
    updatePatchSubmitIdea(state, action: PayloadAction<any>) {
      state.submitIdeaInfo = {
        ...state.submitIdeaInfo,
        ...action.payload,
      };
    },

    updateExportSiteList(state, action: PayloadAction<any>) {
      state.exportDataSiteInfo = {
        ...state.exportDataSiteInfo,
        ...action.payload,
      };
    },
    postExportDataSite(state, action: PayloadAction<any>) {
      state.exportDataSiteInfo = {
        ...state.exportDataSiteInfo,
        loading: true,
        status: action.payload.mode,
      };
    },
    updatePatchSite(state, action: PayloadAction<any>) {
      state.exportData.Sites = {
        ...state.exportData.Sites,
        ...action.payload,
      };
    },
    postExportDataSystem(state, action: PayloadAction<any>) {
      state.exportDataSystemInfo = {
        ...state.exportDataSystemInfo,
        loading: true,
        status: action.payload.mode,
      };
    },
    updatePatchSystem(state, action: PayloadAction<any>) {
      state.exportDataSystemInfo = {
        ...state.exportDataSystemInfo,
        ...action.payload,
      };
    },
    postExportDataNetwork(state, action: PayloadAction<any>) {
      state.exportDataNetworkInfo = {
        ...state.exportDataNetworkInfo,
        loading: true,
        status: action.payload.mode,
      };
    },
    updatePatchNetwork(state, action: PayloadAction<any>) {
      state.exportDataNetworkInfo = {
        ...state.exportDataNetworkInfo,
        ...action.payload,
      };
    },

    updateExportNetworkList(state, action: PayloadAction<any>) {
      state.exportDataNetworkInfo = {
        ...state.exportDataNetworkInfo,
        ...action.payload,
      };
    },
    postExportDataServiceRequest(state, action: PayloadAction<any>) {
      state.exportDataServiceRequestInfo = {
        ...state.exportDataServiceRequestInfo,
        loading: true,
        status: action.payload.mode,
      };
    },
    updatePatchServiceRequest(state, action: PayloadAction<any>) {
      state.exportDataServiceRequestInfo = {
        ...state.exportDataServiceRequestInfo,
        ...action.payload,
      };
    },
    updateExportSRList(state, action: PayloadAction<any>) {
      state.exportDataServiceRequestInfo = {
        ...state.exportDataServiceRequestInfo,
        ...action.payload,
      };
      let data = state?.exportDataServiceRequestInfo?.data?.data;
      if (data && data.length > 0) {
        data = data.map((it: any) => {
          delete it?.sr_created_date;
          delete it?.rn;
          return it;
        });
      }
    },
    postExportDataServiceTrip(state, action: PayloadAction<any>) {
      state.exportDataServiceTripInfo = {
        ...state.exportDataServiceTripInfo,
        loading: true,
        status: action.payload.mode,
      };
    },

    updatePatchServicetrip(state, action: PayloadAction<any>) {
      state.exportDataServiceTripInfo = {
        ...state.exportDataServiceTripInfo,
        ...action.payload,
      };
    },
    updateExportSTList(state, action: PayloadAction<any>) {
      state.exportDataServiceTripInfo = {
        ...state.exportDataServiceTripInfo,
        ...action.payload,
      };
    },
    updateExportSystemList(state, action: PayloadAction<any>) {
      state.exportDataSystemInfo = {
        ...state.exportDataSystemInfo,
        ...action.payload,
      };
    },
    postExportSecurityEvent(state, action: PayloadAction<any>) {
      state.exportDataSecurityEventInfo = {
        ...state.exportDataSecurityEventInfo,
        loading: true,
        status: action.payload.mode,
      };
    },
    updatePostExportSecurityEvent(state, action: PayloadAction<any>) {
      state.exportDataSecurityEventInfo = {
        ...state.exportDataSecurityEventInfo,
        ...action.payload,
      };
    },

    updateExportDataShowListonSearch(state, action: PayloadAction<any>) {
      state.exportDataShowListonSearch = action.payload;
    },
    fetchJitterAnalyticsInfo(state, action: PayloadAction<any>) {
      state.jitterAnalyticsData = {
        ...state.jitterAnalyticsData,
        data: [],
        loading: true,
      };
    },
    updateJitterAnalyticsInfo(state, action: PayloadAction<any>) {
      state.jitterAnalyticsData = {
        ...state.jitterAnalyticsData,
        ...action.payload,
      };
    },
    fetchMOSAnalyticsInfo(state, action: PayloadAction<any>) {
      state.mosAnalyticsData = {
        ...state.mosAnalyticsData,
        data: [],
        loading: true,
      };
    },
    updateMOSAnalyticsInfo(state, action: PayloadAction<any>) {
      state.mosAnalyticsData = {
        ...state.mosAnalyticsData,
        ...action.payload,
      };
    },
    fetchPlossAnalyticsInfo(state, action: PayloadAction<any>) {
      state.plossAnalyticsData = {
        ...state.plossAnalyticsData,
        data: [],
        loading: true,
      };
    },
    updatePlossAnalyticsInfo(state, action: PayloadAction<any>) {
      state.plossAnalyticsData = {
        ...state.plossAnalyticsData,
        ...action.payload,
      };
    },
    fetchWlan1AnalyticsInfo(state, action: PayloadAction<any>) {
      state.wuplink1AnalyticsData = {
        ...state.wuplink1AnalyticsData,
        data: [],
        loading: true,
      };
    },
    updateWlan1AnalyticsInfo(state, action: PayloadAction<any>) {
      state.wuplink1AnalyticsData = {
        ...state.wuplink1AnalyticsData,
        ...action.payload,
      };
    },
    fetchWlan2AnalyticsInfo(state, action: PayloadAction<any>) {
      state.wuplink2AnalyticsData = {
        ...state.wuplink2AnalyticsData,
        data: [],
        loading: true,
      };
    },
    updateWlan2AnalyticsInfo(state, action: PayloadAction<any>) {
      state.wuplink2AnalyticsData = {
        ...state.wuplink2AnalyticsData,
        ...action.payload,
      };
    },
    fetchUserNotification(state, action: PayloadAction<any>) {
      state.userNotifcationList = {
        ...state.userNotifcationList,
        loading: !action.payload?.isGlobal,
      };
    },
    updateUserNotificationList(state, action: PayloadAction<any>) {
      state.userNotifcationList = {
        ...state.userNotifcationList,
        ...action.payload,
      };
    },
    updateGlobalUserNotificationList(state, action: PayloadAction<any>) {
      state.globalUserNotifcationList = {
        ...state.globalUserNotifcationList,
        ...action.payload,
      };
    },
    fetchNewReleaseNotification(state, action: PayloadAction<any>) {
      state.newReleaseNotifcationList = {
        ...state.newReleaseNotifcationList,
        loading: true,
      };
    },
    updateNewReleaseNotificationList(state, action: PayloadAction<any>) {
      state.newReleaseNotifcationList = {
        ...state.newReleaseNotifcationList,
        ...action.payload,
      };
    },
    fetchUserBannerNotification(state, action: PayloadAction<any>) {
      state.bannerNotifcationList = {
        ...state.bannerNotifcationList,
        loading: true,
      };
    },
    updateBannerNotificationList(state, action: PayloadAction<any>) {
      state.bannerNotifcationList = {
        ...state.bannerNotifcationList,
        ...action.payload,
      };
    },
    postReadUserBannerNotification(state, action: PayloadAction<any>) {
      state.readUserBannerNotification = {
        ...state.readUserBannerNotification,
        ...action.payload,
      };
    },
    updateReadUserBannerNotification(state, action: PayloadAction<any>) {
      state.readUserBannerNotification = {
        ...state.readUserBannerNotification,
        ...action.payload,
      };
    },
    postReadUserNotification(state, action: PayloadAction<any>) {
      state.readUserNotification = {
        ...state.readUserNotification,
        ...action.payload,
      };
    },
    updateReadUserNotification(state, action: PayloadAction<any>) {
      state.readUserNotification = {
        ...state.readUserNotification,
        ...action.payload,
      };
    },
    fetchHelpDownloadSupport(state, action: PayloadAction<any>) {
      state.downloadSupport = { ...state.downloadSupport, loading: true };
    },
    updateHelpDownloadSupport(state, action: PayloadAction<any>) {
      state.downloadSupport = {
        ...action.payload,
      };
    },
    fetchUserSystems(state, action: PayloadAction<any>) {
      state.userSystems = { ...state.userSystems, loading: true };
    },
    updateUserSystems(state, action: PayloadAction<any>) {
      state.userSystems = {
        ...state.userSystems,
        ...action.payload,
      };
    },
    fetchGlobalSearchList(state, action: PayloadAction<any>) {
      state.globalSearchList = {
        data: [],
        loading: true,
      };
    },
    updateGlobalSearchList(state, action: PayloadAction<any>) {
      state.globalSearchList = {
        ...state.globalSearchList,
        ...action.payload,
      };
    },
    fetchExportStatesList(state, action: PayloadAction<any>) {
      state.exportStateList = { ...state.exportStateList };
    },
    updateExportStateList(state, action: PayloadAction<any>) {
      state.exportStateList = {
        ...state.exportStateList,
        ...action.payload,
      };
    },
    updateAnalyticsTimerFilter(state, action: PayloadAction<any>) {
      state.analyticsTimeFilter = {
        ...state.analyticsTimeFilter,
        ...action.payload,
      };
    },
    updateAnalyticsTimeRange(state, action: PayloadAction<any>) {
      state.siteAnalyticsTimeRange = action.payload;
    },
    fetchExportAllActivity(state, action: PayloadAction<any>) {
      state.exportAllActivty = {
        ...state.exportAllActivty,
        loading: true,
        message: '',
      };
    },
    updateExportAllActivity(state, action: PayloadAction<any>) {
      state.exportAllActivty = {
        ...state.exportAllActivty,
        ...action.payload,
      };
    },
    fetchExportOpenCloseActivity(state, action: PayloadAction<any>) {
      state.exportOpenCloseActivty = {
        ...state.exportOpenCloseActivty,
        loading: true,
      };
    },
    updateExportOpenCloseActivity(state, action: PayloadAction<any>) {
      state.exportOpenCloseActivty = {
        ...state.exportOpenCloseActivty,
        ...action.payload,
      };
    },
    fetchExportSMSiteList(state, action: PayloadAction<any>) {
      state.securityManagerSiteList = {
        ...state.securityManagerSiteList,
        loading: true,
      };
    },
    updateExportSMSiteList(state, action: PayloadAction<any>) {
      state.securityManagerSiteList = {
        ...state.securityManagerSiteList,
        ...action.payload,
      };
      if (state.securityManagerSiteList?.data) {
        state.securityManagerSiteList.data =
          state.securityManagerSiteList?.data.map((it: any) => {
            return { key: it.Name, value: it.Id };
          });
      }
    },
    fetchExportSMSiteGroupList(state, action: PayloadAction<any>) {
      state.securityManagerSiteGroupList = {
        ...state.securityManagerSiteGroupList,
        loading: true,
      };
    },
    updateExportSMSiteGroupList(state, action: PayloadAction<any>) {
      state.securityManagerSiteGroupList = {
        ...state.securityManagerSiteGroupList,
        ...action.payload,
      };
      if (state.securityManagerSiteGroupList?.data) {
        state.securityManagerSiteGroupList.data =
          state.securityManagerSiteGroupList?.data.map((it: any) => {
            return { key: it.Name, value: it.Id };
          });
      }
    },
    updateSecurityManagerFilter(state, action: PayloadAction<any>) {
      state.securityManagerFilter = {
        ...state.securityManagerFilter,
        ...action.payload,
      };
    },
    fetchExportUserContact(state, action: PayloadAction<any>) {
      state.exportUserContanct = { ...state.exportUserContanct, loading: true };
    },
    updateExportUserContact(state, action: PayloadAction<any>) {
      state.exportUserContanct = {
        ...state.exportUserContanct,
        ...action.payload,
      };
    },
    fetchELearninginfo(state, action: PayloadAction<any>) {
      state.elearningUrls = { ...state.elearningUrls, loading: true };
    },
    updateELearninginfo(state, action: PayloadAction<any>) {
      state.elearningUrls = {
        ...action.payload,
      };
    },
    fetchOnlineManualsInfo(state, action: PayloadAction<any>) {
      state.onlineManuals = { ...state.onlineManuals, loading: true };
    },
    updateOnlineManualsInfo(state, action: PayloadAction<any>) {
      state.onlineManuals = {
        ...action.payload,
      };
    },
    fetchTermsConditonInfo(state, action: PayloadAction<any>) {
      state.termsConditions = { ...state.termsConditions, loading: true };
    },
    updateTermsConditionInfo(state, action: PayloadAction<any>) {
      state.termsConditions = {
        ...action.payload,
      };
    },
    fetchPatchAcceptAgreement(state, action: PayloadAction<any>) {
      state.acceptAgreement = {
        ...state.acceptAgreement,
        loading: true,
      };
    },
    updatePatchAcceptAgreement(state, action: PayloadAction<any>) {
      state.acceptAgreement = {
        ...action.payload,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = globalSlice;
