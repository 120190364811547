/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState, MouseEvent, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  SearchIcon,
  RightArrowIcon,
  LeftArrowIcon,
  Cancel,
} from 'assets/component';
import { ListChildComponentProps } from 'react-window';
import { useDebounce } from 'utils/CommonFn';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'app/component/layout/Loader/Loader';
import { CustomList, CustomMenu } from './style';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { IconsButton } from '../Buttons';

interface Props {
  label?: string;
  mainList?: any;
  companyTypes?: any;
  value?: string;
  handleChange?: any;
  loading?: boolean;
  placeholder?: any;
  searchPlaceholder?: any;
  isDisabled?: boolean;
  onTextSearch?: any;
  onSelectionClear?: any;
}

const CustomToggleSearchSelect = ({
  label,
  mainList,
  companyTypes = [],
  value = '',
  handleChange,
  placeholder = '',
  loading = false,
  searchPlaceholder = '',
  isDisabled = false,
  onTextSearch = () => {},
  onSelectionClear = () => {},
}: Props): JSX.Element => {
  const DEFAULT_ITEM_COUNT = 8;
  const SUMUP_SIZE = 36;
  const DEFAULT_SIZE = 48;
  const CATEGORY_ALL = 'All';
  const list: any = [];
  const [listOptions, setlistOptions] = useState([]);
  const [options, setOptions] = useState(list);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(
    companyTypes[0],
  );
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const findSelectedSite = (dataList: any, value: any) => {
    const mainSite: any = dataList.find(
      (o: any) => parseInt(o.Id) === parseInt(value),
    );
    return mainSite;
  };
  const siteDetails: any = {};
  const [selectedSite, setSelectedSite] = useState(siteDetails);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event: MouseEvent<HTMLElement>): void => {
    if (!isDisabled) setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: any, site: any): void => {
    handleChange(event, site);
    setAnchorEl(null);
    setSearchText('');
    setOptions(listOptions);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setSearchText('');
    setOptions(listOptions);
  };

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = options.length;
  const itemSize = smUp ? SUMUP_SIZE : DEFAULT_SIZE;

  const getChildSize = (child: React.ReactElement): any => {
    if (child.hasOwnProperty('group')) {
      return DEFAULT_SIZE;
    }

    return itemSize;
  };

  const getHeight = (): any => {
    if (itemCount > DEFAULT_ITEM_COUNT) {
      return DEFAULT_ITEM_COUNT * itemSize;
    }
    const size = options.map(getChildSize).reduce((a: any, b: any) => a + b, 0);
    return size;
  };

  const handleSearch = (e: any): void => {
    setSearchText(e.target.value);
  };
  const filterMainList = (type: any) => {
    const updatedList = mainList.filter((o: any) => o?.Type === type);
    return updatedList;
  };
  const filterDataByText = (list: any, text: any) => {
    const filterData = list.filter((o: any) =>
      o?.Name?.toLowerCase()?.includes(text.toLowerCase()),
    );
    return filterData;
  };
  const companyCategorySelection = (index: any) => {
    const companyCategory: any = companyTypes[index];
    const dataList: any =
      index === 0 ? mainList : filterMainList(companyCategory?.Name);
    setSelectedCategoryIndex(index);
    setlistOptions(dataList);
    if (searchText) {
      const filterData = filterDataByText(dataList, searchText);
      setOptions(filterData);
    } else {
      setOptions(dataList);
    }
  };
  // Previous category selection
  const selectPrevious = () => {
    const index = selectedCategoryIndex - 1;
    if (index >= 0) {
      companyCategorySelection(index);
    } else {
      const index = companyTypes.length - 1;
      companyCategorySelection(index);
    }
  };
  // Next category selection
  const selectNext = () => {
    if (companyTypes.length - 1 > selectedCategoryIndex) {
      const index = selectedCategoryIndex + 1;
      companyCategorySelection(index);
    } else {
      const index = 0;
      companyCategorySelection(index);
    }
  };
  useEffect(() => {
    if (value) {
      const selected = findSelectedSite(mainList, value);
      setSelectedSite(selected);
      const index = companyTypes.findIndex(
        (type: any) => selected?.Type === type.Name,
      );
      if (
        selectedCategoryIndex === index ||
        companyTypes[selectedCategoryIndex]?.Name === CATEGORY_ALL
      ) {
        setSelectedCategoryValue(companyTypes[selectedCategoryIndex]);
      } else {
        const list: any = filterMainList(selected?.Type);
        setlistOptions(list);
        setOptions(list);
        setSelectedCategoryIndex(index);
        setSelectedCategoryValue(companyTypes[index]);
      }
    }
  }, [value]);
  useEffect(() => {
    if (searchText) {
      const filterData = filterDataByText(listOptions, searchText);
      if (searchText.length > 4 && filterData.length === 0) {
        onTextSearch(searchText);
      }
      setOptions(filterData);
    } else {
      setOptions(listOptions);
    }
  }, [useDebounce(searchText, 300)]);

  useEffect(() => {
    if (value) {
      const site: any = findSelectedSite(mainList, value);
      const list: any = filterMainList(site?.Type);
      setSelectedSite(site);
      companyTypes.forEach((element: any, index: any) => {
        if (site && element.Name === site?.Type) {
          setSelectedCategoryValue(element);
          setSelectedCategoryIndex(index);
        }
      });
      setlistOptions(list);
      setOptions(list);
    } else {
      setlistOptions(mainList);
      setOptions(mainList);
    }
  }, [mainList]);

  const renderRow = (props: ListChildComponentProps): any => {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + 1,
    };

    return (
      <>
        <CustomMenu
          theme={theme}
          component="li"
          {...dataSet}
          noWrap
          style={{ ...inlineStyle }}
          onClick={(event: any) => {
            handleMenuItemClick(event, dataSet);
          }}
          sx={{
            background:
              parseInt(dataSet?.Id) === parseInt(value)
                ? theme.palette.primary.main
                : theme.palette.customBackground.main,
            '.name': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <OverflowTip text={dataSet?.Name} />
        </CustomMenu>
      </>
    );
  };

  return (
    <>
      <Grid item style={{ display: 'flex' }}>
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label={label}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          sx={{
            bgcolor: theme.palette.customBackground.main,
            borderRadius: '0.5rem',
            padding: selectedCategoryValue?.Name
              ? '0 0.75rem'
              : '0.35rem 0.45rem',
            display: 'flex',
            cursor: 'pointer',
            opacity: isDisabled ? 0.7 : 1,
          }}
        >
          <ListItemText
            sx={{
              '& .MuiListItemText-primary': {
                fontSize: '0.75em',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                background: theme.palette.customBackground.main,
              },
            }}
            primary={
              <>
                <div style={{ fontSize: '0.55rem' }}>
                  {selectedCategoryValue?.Name ?? ''}
                </div>
                <div>{selectedSite?.Name || placeholder}</div>
              </>
            }
          />
          <div style={{ marginLeft: 'auto' }}>
            {loading ? (
              <Loader size={20} />
            ) : !open && selectedSite?.Name !== undefined ? (
              <IconsButton
                styleTooltip={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: 'auto',
                }}
                children={<Cancel />}
                OnIconClick={onSelectionClear}
                isShowTooltip={!isDisabled}
                title="Clear"
                isDisabled={isDisabled}
              />
            ) : (
              <></>
            )}
          </div>
        </ListItem>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          role: 'listbox',
          sx: { width: anchorEl?.offsetWidth, paddingTop: 0, paddingBottom: 0 },
        }}
      >
        <ListSubheader
          style={{
            padding: '0 5px',
            background: theme.palette.customBackground.main,
            lineHeight: '35px',
          }}
        >
          <div style={{ display: 'flex' }}>
            <IconsButton
              name={'Previous'}
              children={<LeftArrowIcon />}
              OnIconClick={selectPrevious}
              isShowTooltip={true}
              isDisabled={companyTypes.length === 0}
              style={{
                padding: '0.2rem',
              }}
              styleTooltip={{
                width: 'auto',
                height: 'auto',
              }}
            />
            <div
              style={{
                width: '100%',
                textAlign: 'center',
                color: theme.palette.text.primary,
                fontWeight: 700,
              }}
            >
              {companyTypes[selectedCategoryIndex]?.Name}
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconsButton
                name={'Next'}
                children={<RightArrowIcon />}
                OnIconClick={selectNext}
                isShowTooltip={true}
                isDisabled={companyTypes.length === 0}
                style={{
                  padding: '0.2rem',
                }}
                styleTooltip={{
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </div>
          </div>
          <TextField
            size="small"
            autoFocus
            style={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: '0.45rem',
              margin: '0',
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '10px',
              },
            }}
            placeholder={searchPlaceholder}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: { fontSize: '0.75rem' },
            }}
            value={searchText}
            onChange={handleSearch}
            onKeyDown={(e: any) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <CustomList
          theme={theme}
          innerElementType="ul"
          itemData={options}
          itemCount={options.length}
          itemSize={30}
          height={getHeight() + 2 * 8}
          width={'100%'}
          overscanCount={5}
        >
          {renderRow}
        </CustomList>
      </Menu>
    </>
  );
};
export default CustomToggleSearchSelect;
