import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';
import { getFromLocalStorage } from 'store/localStore';
import { STORE_KEY } from 'store/Constant';

const selectSlice = (state: RootState) =>
  state && state.dashboard != null ? state.dashboard : initialState;

export const getDashboardFilterData = createSelector(
  [selectSlice],
  state => state.FilterDropdownData,
);
export const getServicesCount = createSelector(
  [selectSlice],
  state => state.ServiceCount.serviceList,
);
export const getNetworkStatusData = createSelector(
  [selectSlice],
  state => state.NetworkStatusCount,
);
export const getViewType = createSelector(
  [selectSlice],
  state => getFromLocalStorage(STORE_KEY.VIEW) ?? state.viewType,
);
export const getFilterSiteData = createSelector(
  [selectSlice],
  state => state.FilterSiteList,
);
export const getFilterNetworkData = createSelector(
  [selectSlice],
  state => state.FilterNetworkList,
);
export const getFilterServiceRequestData = createSelector(
  [selectSlice],
  state => state.FilterServiceRequestList,
);
export const getFilterServiceTripData = createSelector(
  [selectSlice],
  state => state.FilterServiceTripList,
);
export const getFilterLocationList = createSelector(
  [selectSlice],
  state => state.LocationData,
);

export const getSiteManagerInfo = createSelector(
  [selectSlice],
  state => state.siteManagerInfo,
);

export const getSiteItemDetail = createSelector(
  [selectSlice],
  state => state.siteItemDetail,
);
export const getCaseNotesDetails = createSelector(
  [selectSlice],
  state => state.caseNotesDetail,
);
export const getNotesCommunicationLogs = createSelector(
  [selectSlice],
  state => state.caseNotesLogs,
);
export const getActiveTab = createSelector(
  [selectSlice],
  state => state.dashboardActiveTab,
);
export const getServiceCountInfo = createSelector(
  [selectSlice],
  state => state.ServiceCountInfo,
);
export const getNetworkCountInfo = createSelector(
  [selectSlice],
  state => state.NetworkCountInfo,
);
export const getSiteManager = createSelector(
  [selectSlice],
  state => state.siteManagerActive,
);

export const getTripsItemDetail = createSelector(
  [selectSlice],
  state => state.tripsItemDetail,
);
export const getCompanyId = createSelector(
  [selectSlice],
  state => state.selectedCompanyID,
);

export const getSiteId = createSelector(
  [selectSlice],
  state => state.selectedSiteID,
);

export const getDashboardFilter = createSelector(
  [selectSlice],
  state => state.dashboardFilter,
);

export const getResolvedEvents = createSelector(
  [selectSlice],
  state => state.resolvedEventsActive,
);
export const getVideoViewerInfo = createSelector(
  [selectSlice],
  state => state.videoViewerInfo,
);

export const getFilterSystemData = createSelector(
  [selectSlice],
  state => state.FilterSystemList,
);

export const getSelectedSiteName = createSelector(
  [selectSlice],
  state => state.selectedSiteName,
);

export const getActiveDashboardTab = createSelector(
  [selectSlice],
  state => state.activeDashboardTab,
);
export const getDashboardListPageNo = createSelector(
  [selectSlice],
  state => state.dashboardListPageNo,
);
export const getImpersonateSession = createSelector(
  [selectSlice],
  state => state.impersonateSession,
);

export const getSRItemCaseNote = createSelector(
  [selectSlice],
  state => state.addSRItemCaseNote,
);
export const getAddSRItemComment = createSelector(
  [selectSlice],
  state => state.addSRItemComment,
);
export const getSRItemComments = createSelector(
  [selectSlice],
  state => state.SRItemComments,
);
export const getSRItemEscalate = createSelector(
  [selectSlice],
  state => state.srItemEscalate,
);
export const getSRItemInternalNote = createSelector(
  [selectSlice],
  state => state.itemInternalNotes,
);
export const getAddSRItemInternalNote = createSelector(
  [selectSlice],
  state => state.srItemInternalNotes,
);
export const getFilterAlarmEvents = createSelector(
  [selectSlice],
  state => state.FilterSecurityEventList,
);
export const getSecurityEventDetails = createSelector(
  [selectSlice],
  state => state.securityEventDetails,
);
export const getSecurityEventZones = createSelector(
  [selectSlice],
  state => state.securityEventZones,
);
export const getSecurityEventActionDetails = createSelector(
  [selectSlice],
  state => state.securityEventCurrentActions,
);
export const getReportActiveTab = createSelector(
  [selectSlice],
  state => state.activeReportTab,
);
export const getAttachment = createSelector(
  [selectSlice],
  state => state.srDownloadAttachment,
);
export const getSiteActiveStatus = createSelector(
  [selectSlice],
  state => state.siteActiveStatus,
);
