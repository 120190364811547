import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  style?: React.CSSProperties;
  value: number;
  options: any;
  onChange?: any;
  disabled?: boolean;
}
export function CustomRadio({
  value,
  options,
  onChange,
  disabled,
  style = {},
}: Props): JSX.Element {
  const theme = useTheme();
  return (
    <FormControl>
      <RadioGroup row onChange={onChange} value={value}>
        {options &&
          options.length > 0 &&
          options.map((item: any) => {
            return (
              <FormControlLabel
                key={item.value}
                value={item.id}
                name={item.name || item.value}
                disabled={disabled}
                style={style}
                control={
                  <Radio
                    sx={{
                      '&, &.Mui-checked': {
                        color: theme.palette.text.primary,
                      },
                    }}
                  />
                }
                label={item.value}
                data-testid={item.id}
              />
            );
          })}
      </RadioGroup>
    </FormControl>
  );
}
