import { actions } from 'app/features/DashboardSlice/slice';
import { clearImpersonateSession, isLoggedIn } from 'app/features/authService';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { STORE_KEY } from 'store/Constant';
import { getFromSessionStorage, saveOnSessionStorage } from 'store/localStore';
// import { DISPLAY_TEXT } from 'utils/Constants/Content';

const SessionTimer = () => {
  const dispatch = useDispatch();
  /* const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0); */

  const clearTimeInterval = (interval: any) => {
    /* setHours(0);
    setMinutes(0);
    setSeconds(0); */
    clearInterval(interval);
  };
  const getTime = (expiredIn: any, interval: any) => {
    const time = expiredIn - new Date().getTime();
    if (time > 0 && isLoggedIn()) {
      const minutes: any = (time / 1000 / 60) % 60;
      if (minutes >= 5 && minutes < 5.02) {
        dispatch(
          actions.updateImpersonateSession({
            beforeSessionExpire: true,
            sessionExpire: false,
          }),
        );
      }
      saveOnSessionStorage(STORE_KEY.SESSION_EXPIRED_IN, time.toString());
      /* setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60)); */
    } else if (!isLoggedIn()) {
      clearTimeInterval(interval);
      // If user logged out close impersonate user tab
      clearImpersonateSession();
    } else {
      clearTimeInterval(interval);
      dispatch(
        actions.updateImpersonateSession({
          beforeSessionExpire: false,
          sessionExpire: true,
        }),
      );
    }
  };

  useEffect(() => {
    const expiredIn: any =
      getFromSessionStorage(STORE_KEY.SESSION_EXPIRED_IN) ?? 0;
    const time = new Date().getTime() + parseInt(expiredIn);
    const interval: any = setInterval(() => getTime(time, interval), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* <div>
        {DISPLAY_TEXT.SESSION_EXPIRE}
        <span
          style={{
            fontWeight: 700,
          }}
        >{`${hours >= 10 ? hours : `0${hours}`}:${
          minutes >= 10 ? minutes : `0${minutes}`
        }:${seconds >= 10 ? seconds : `0${seconds}`}`}</span>
      </div> */}
    </>
  );
};

export default SessionTimer;
