/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useState, MouseEvent, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DownFillIcon, SearchIcon, UpFillIcon, Cancel } from 'assets/component';
import { ListChildComponentProps } from 'react-window';
import { useDebounce } from 'utils/CommonFn';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loader from 'app/component/layout/Loader/Loader';
import { CustomList, CustomMenu } from './style';
import OverflowTip from '../CustomFixedListView/OverflowTip';
import { IconsButton } from '../Buttons';

interface Props {
  label?: string;
  listOptions?: any;
  value?: string;
  handleChange?: any;
  loading?: boolean;
  placeholder?: any;
  searchPlaceholder?: any;
  isDisabled?: boolean;
  onTextSearch?: any;
  onSelectionClear?: any;
}

const CustomSearchSelect = ({
  label,
  listOptions,
  value = '',
  handleChange,
  placeholder = '',
  loading = false,
  searchPlaceholder = '',
  isDisabled = false,
  onTextSearch = () => {},
  onSelectionClear = () => {},
}: Props): JSX.Element => {
  const list: any = [];
  const [options, setOptions] = useState(list);
  const theme = useTheme();
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const listRef = useRef(null);
  const selectedSite = listOptions.find(
    (o: any) => parseInt(o.Id) === parseInt(value),
  );
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: MouseEvent<HTMLElement>): void => {
    if (!isDisabled) setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: any, site: any): void => {
    handleChange(event, site);
    setAnchorEl(null);
    setSearchText('');
    setOptions(listOptions);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setSearchText('');
    setOptions(listOptions);
  };

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = options.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement): any => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = (): any => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    const size = options.map(getChildSize).reduce((a: any, b: any) => a + b, 0);
    return size;
  };

  const handleSearch = (e: any): void => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText) {
      const filterData = listOptions.filter((o: any) =>
        o?.Name?.toLowerCase()?.includes(searchText.toLowerCase()),
      );
      if (searchText.length > 4 && filterData.length === 0) {
        onTextSearch(searchText);
      }
      setOptions(filterData);
    } else {
      setOptions(listOptions);
    }
  }, [useDebounce(searchText, 300)]);

  useEffect(() => {
    setOptions(listOptions);
  }, [listOptions]);

  const renderRow = (props: ListChildComponentProps): any => {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
      ...style,
      top: (style.top as number) + 1,
    };

    return (
      <>
        <CustomMenu
          theme={theme}
          component="li"
          {...dataSet}
          noWrap
          style={{ ...inlineStyle }}
          onClick={(event: any) => {
            handleMenuItemClick(event, dataSet);
          }}
          sx={{
            background:
              parseInt(dataSet?.Id) === parseInt(value)
                ? theme.palette.primary.main
                : theme.palette.customBackground.main,
            '.name': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <OverflowTip text={dataSet?.Name} />
        </CustomMenu>
      </>
    );
  };

  return (
    <>
      <ListItem
        id="lock-button"
        aria-haspopup="listbox"
        aria-controls="lock-menu"
        aria-label={label}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickListItem}
        sx={{
          bgcolor: theme.palette.customBackground.main,
          borderRadius: '0.5rem',
          padding: '0.35rem 0.45rem',
          display: 'flex',
          cursor: 'pointer',
          opacity: isDisabled ? 0.7 : 1,
        }}
      >
        <ListItemText
          sx={{
            '& .MuiListItemText-primary': {
              fontSize: '0.75em',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              background: theme.palette.customBackground.main,
            },
          }}
          primary={selectedSite?.Name || placeholder}
        />
        <div style={{ marginLeft: 'auto' }}>
          {loading ? (
            <Loader size={20} />
          ) : open ? (
            <UpFillIcon />
          ) : !open && selectedSite?.Name !== undefined ? (
            <IconsButton
              styleTooltip={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: 'auto',
              }}
              children={<Cancel />}
              OnIconClick={onSelectionClear}
              isShowTooltip={!isDisabled}
              title="Clear"
              isDisabled={isDisabled}
            />
          ) : (
            <DownFillIcon />
          )}
        </div>
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          role: 'listbox',
          sx: { width: anchorEl?.offsetWidth, paddingTop: 0, paddingBottom: 0 },
        }}
      >
        <ListSubheader
          style={{
            padding: '0 10px',
            background: theme.palette.customBackground.main,
          }}
        >
          <TextField
            size="small"
            autoFocus
            style={{
              marginTop: '0.4rem',
              backgroundColor: theme.palette.primary.main,
              borderRadius: '0.45rem',
              margin: '0.55rem 0',
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '10px',
              },
            }}
            placeholder={searchPlaceholder}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: { fontSize: '0.75rem' },
            }}
            value={searchText}
            onChange={handleSearch}
            onKeyDown={(e: any) => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        <CustomList
          theme={theme}
          innerElementType="ul"
          itemData={options}
          itemCount={options.length}
          itemSize={30}
          height={getHeight() + 2 * 8}
          width={'100%'}
          overscanCount={5}
        >
          {renderRow}
        </CustomList>
      </Menu>
    </>
  );
};
export default CustomSearchSelect;
