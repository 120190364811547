import { PayloadAction } from '@reduxjs/toolkit';
import {
  DashboardState,
  FilterListMapProps,
  FilterProps,
  LocationProps,
  ResponseProps,
  ServiceCountProps,
} from './types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { COLORS } from 'styles/colors';
import { fetchMasterCompanyId, fetchMasterSiteId } from '../authService';
import { CONTEXT, DISPLAY_TEXT } from 'utils/Constants/Content';
import { DataMaptoArray } from '../utils';

export const initialState: DashboardState = {
  NetworkStatusCount: {
    loading: false,
    data: [],
  },
  FilterDropdownData: {
    loading: true,
    data: [],
  },
  ServiceCount: {
    loading: false,
    networkOutageCount: 0,
    serviceRequestCount: 0,
    securityEventsCount: 0,
    serviceList: [],
  },
  viewType: 'map',

  FilterSiteList: {
    loading: false,
    listData: [],
    mapData: [],
    recordCount: 0,
  },
  FilterNetworkList: {
    loading: false,
    listData: [],
    mapData: [],
    recordCount: 0,
  },
  FilterServiceRequestList: {
    loading: false,
    listData: [],
    mapData: [],
    recordCount: 0,
  },
  FilterServiceTripList: {
    loading: false,
    listData: [],
    mapData: [],
    recordCount: 0,
  },
  FilterSecurityEventList: {
    loading: false,
    listData: [],
    mapData: [],
    recordCount: 0,
  },
  LocationData: {
    loading: false,
    locationList: [],
  },
  siteManagerInfo: {
    data: [],
    loading: false,
  },
  siteItemDetail: {
    data: [],
    loading: false,
  },
  caseNotesDetail: {
    data: [],
    loading: false,
  },
  tripsItemDetail: {
    data: [],
    loading: false,
  },
  dashboardActiveTab: 'Sites',
  ServiceCountInfo: {
    loading: false,
    recordCount: 0,
    data: [],
  },
  NetworkCountInfo: {
    loading: false,
    recordCount: 0,
    data: [],
  },
  siteManagerActive: false,
  selectedCompanyID: fetchMasterCompanyId() ?? '',
  selectedSiteID: fetchMasterSiteId() ?? '',
  dashboardFilter: {
    locationValue: {
      address: '',
      city: '',
      siteName: '',
      state: [],
      zipcode: '',
      isFilterApplied: false,
    },
    ServiceValue: [],
    systemValue: [],
    statusValue: ['Pending', 'Scheduled', 'Started'],
    workOrderNo: '',
    subjectValue: [],
    SeverityValue: [],
    StatusValue: ['Open'],
    serviceReqNumber: '',
    networkStatusValue: [],
    switchStatusValue: [],
    billingStatusValue: [],
    systemTypeValue: [],
    incidentTypeValue: [],
    columnsValue: [],
    stateValue: [],
    pageNo: 1,
    sortColumn: '',
    sortBy: '',
    // srStatusValue: [],
    systemListValue: [],
    subStatusValue: [],
    eventType: [],
  },
  activeDashboardTab: 1,
  dashboardListPageNo: 1,
  resolvedEventsActive: false,
  videoViewerInfo: {
    data: [],
    loading: false,
  },
  FilterSystemList: {
    loading: false,
    listData: [],
    recordCount: 0,
  },
  selectedSiteName: '',
  impersonateSession: {
    beforeSessionExpire: false,
    sessionExpire: false,
  },
  addSRItemCaseNote: {
    data: [],
    loading: false,
  },
  addSRItemComment: {
    data: [],
    loading: false,
  },
  SRItemComments: {
    data: [],
    loading: false,
  },
  srItemEscalate: {
    data: [],
    loading: false,
  },
  srItemInternalNotes: {
    data: [],
    loading: false,
  },
  itemInternalNotes: {
    data: [],
    loading: false,
  },
  securityEventDetails: {
    loading: false,
    data: [],
  },
  securityEventZones: {
    loading: false,
    data: [],
  },
  securityEventCurrentActions: {
    loading: false,
    data: [],
  },
  activeReportTab: '',
  srDownloadAttachment: {
    loading: false,
    data: [],
  },
  caseNotesLogs: {
    loading: false,
    data: [],
  },
  siteActiveStatus: true,
};
const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    fetchNetworkStatusCount(state, action: PayloadAction<any>) {
      state.NetworkStatusCount = { ...state.NetworkStatusCount, loading: true };
    },
    updateNetworkStatusCount(state, action: PayloadAction<ResponseProps>) {
      state.NetworkStatusCount = {
        ...state.NetworkStatusCount,
        ...action.payload,
      };
      if (state.NetworkStatusCount?.data != null) {
        const EvaluateStatusList = state.NetworkStatusCount?.data.map(
          (item: any) => {
            if (item.CurrentStatus === 'Up on Backup') {
              item.label = 'Backup';
              item.fill = COLORS.backupColor;
              item.stroke = COLORS.backupColor;
            } else if (item.CurrentStatus === 'Up on Primary') {
              item.label = 'Primary';
              item.fill = COLORS.lightTintRed;
              item.stroke = COLORS.lightTintRed;
            } else if (item.CurrentStatus === 'Down') {
              item.label = 'Down';
              item.fill = COLORS.downColor;
              item.stroke = COLORS.downColor;
            }
            return {
              ...item,
            };
          },
        );
        state.NetworkStatusCount.data = EvaluateStatusList;
      }
    },
    fetchFillterData(state, action: PayloadAction<any>) {
      state.FilterDropdownData = { ...state.FilterDropdownData };
    },
    updateFilterData(state, action: PayloadAction<any>) {
      state.FilterDropdownData = {
        ...state.FilterDropdownData,
        ...action.payload,
      };
      if (state.FilterDropdownData?.data != null) {
        const allData: any = {};
        Object.keys(state.FilterDropdownData?.data).forEach(element => {
          allData[element] = [
            DataMaptoArray(state.FilterDropdownData?.data[element][0]),
          ];
        });
        state.FilterDropdownData.data = allData;
      }
    },
    updateEventStatus(state, action: PayloadAction<any>) {
      state.FilterDropdownData.data = {
        ...state.FilterDropdownData.data,
        ...action.payload,
      };
    },
    fetchServiceCount(state, action: PayloadAction<ServiceCountProps>) {
      state.ServiceCount = { ...state.ServiceCount };
    },
    updateServiceCount(state, action: PayloadAction<ServiceCountProps>) {
      state.ServiceCount = {
        ...state.ServiceCount,
        ...action.payload,
      };
      const ServiceDetails = [
        {
          label: CONTEXT.ACTIVE_SERVICE_REQUEST,
          score: state.ServiceCount.serviceRequestCount,
        },
        {
          label: CONTEXT.NETWORK_OUTAGES,
          score: state.ServiceCount.networkOutageCount,
        },
        {
          label: CONTEXT.EVENT,
          score: state.ServiceCount.securityEventsCount,
        },
        {
          label: CONTEXT.DEVICE_OFFLINE,
          score: DISPLAY_TEXT.FUTURE_ENHANCEMENT,
        },
      ];
      state.ServiceCount.serviceList = ServiceDetails;
    },
    updateServiceEventCount(state, action: PayloadAction<any>) {
      state.ServiceCount = {
        ...state.ServiceCount,
        ...action.payload,
      };
      if (state.ServiceCount.serviceList !== null) {
        const serviceList = state.ServiceCount.serviceList.map((it: any) => {
          return {
            ...it,
            score:
              it.label === CONTEXT.EVENT
                ? state.ServiceCount.securityEventsCount
                : it.score,
          };
        });
        state.ServiceCount.serviceList = serviceList;
      }
    },
    updateViewType(state, action: PayloadAction<any>) {
      state.viewType = action.payload;
    },
    // Filter site
    fetchFilterSiteData(state, action: PayloadAction<FilterProps>) {
      state.FilterSiteList = { ...state.FilterSiteList, loading: true };
    },
    updateFilterSiteData(state, action: PayloadAction<FilterListMapProps>) {
      state.FilterSiteList = {
        ...state.FilterSiteList,
        ...action.payload,
      };
    },
    // Filter Network
    fetchFilterNetworkData(state, action: PayloadAction<FilterProps>) {
      state.FilterNetworkList = { ...state.FilterNetworkList, loading: true };
    },
    updateFilterNetworkData(state, action: PayloadAction<FilterListMapProps>) {
      state.FilterNetworkList = {
        ...state.FilterNetworkList,
        ...action.payload,
      };
    },
    // Filter Service request
    fetchFilterServiceRequest(state, action: PayloadAction<FilterProps>) {
      state.FilterServiceRequestList = {
        ...state.FilterServiceRequestList,
        loading: true,
      };
    },
    updateFilterServiceRequest(
      state,
      action: PayloadAction<FilterListMapProps>,
    ) {
      state.FilterServiceRequestList = {
        ...state.FilterServiceRequestList,
        ...action.payload,
      };
    },
    // Filter Service Trip
    fetchFilterServiceTrip(state, action: PayloadAction<FilterProps>) {
      state.FilterServiceTripList = {
        ...state.FilterServiceTripList,
        loading: true,
      };
    },
    updateFilterServiceTrip(state, action: PayloadAction<FilterListMapProps>) {
      state.FilterServiceTripList = {
        ...state.FilterServiceTripList,
        ...action.payload,
      };
    },
    // Filter Security Events
    fetchFilterSecurityEvents(state, action: PayloadAction<FilterProps>) {
      state.FilterSecurityEventList = {
        ...state.FilterSecurityEventList,
        loading: true,
      };
    },
    updateFilterSecurityEvents(
      state,
      action: PayloadAction<FilterListMapProps>,
    ) {
      state.FilterSecurityEventList = {
        ...state.FilterSecurityEventList,
        ...action.payload,
      };
    },
    // Filter Location
    fetchLocationData(state, action: PayloadAction<any>) {
      state.LocationData = {
        ...state.LocationData,
        locationList: [],
        loading: true,
      };
    },
    updateLocationData(state, action: PayloadAction<LocationProps>) {
      if (action?.payload?.locationList.length > 0) {
        state.LocationData = {
          ...state.LocationData,
          ...action.payload,
        };
      } else {
        state.LocationData.loading = false;
      }
    },
    // Site manager Info
    fetchSiteManagerInfo(state, action: PayloadAction<any>) {
      state.siteManagerInfo = { ...state.siteManagerInfo, loading: true };
    },
    updateSiteManagerInfo(state, action: PayloadAction<any>) {
      state.siteManagerInfo = {
        ...state.siteManagerInfo,
        ...action.payload.siteManagerInfo,
      };
    },
    // Site manager Info
    fetchSiteItemDetail(state, action: PayloadAction<any>) {
      state.siteItemDetail = { ...state.siteItemDetail, loading: true };
    },
    updateSiteItemDetail(state, action: PayloadAction<any>) {
      state.siteItemDetail = {
        ...state.siteItemDetail,
        ...action.payload.siteItemDetail,
        ...{ loading: action.payload.loading },
      };
    },
    fetchCaseNotesDetail(state, action: PayloadAction<any>) {
      state.caseNotesDetail = { data: [], loading: true };
    },
    updateCaseNotesDetail(state, action: PayloadAction<any>) {
      state.caseNotesDetail = {
        ...state.caseNotesDetail,
        ...action.payload,
      };
    },
    fetchCaseNotesAndLogs(state, action: PayloadAction<any>) {
      state.caseNotesLogs = { data: [], loading: true };
    },
    updateNotesAndLogs(state, action: PayloadAction<any>) {
      state.caseNotesLogs = {
        ...state.caseNotesLogs,
        ...action.payload,
      };
    },
    // Dashboard Active Tab
    updateActiveTab(state, action: PayloadAction<any>) {
      state.dashboardActiveTab = action.payload;
    },
    // service count info
    fetchServiceCountInfo(state, action: PayloadAction<any>) {
      state.ServiceCountInfo = { ...state.ServiceCountInfo, loading: true };
    },
    updateServiceInfo(state, action: PayloadAction<any>) {
      state.ServiceCountInfo = {
        ...state.ServiceCountInfo,
        ...action.payload,
      };
    },
    // Network count info
    fetchNetworkCountInfo(state, action: PayloadAction<any>) {
      state.NetworkCountInfo = { ...state.NetworkCountInfo, loading: true };
    },
    updateNetworkServiceInfo(state, action: PayloadAction<any>) {
      state.NetworkCountInfo = {
        ...state.NetworkCountInfo,
        ...action.payload,
      };
    },
    // Dashboard Active Tab
    updateSiteManagerActive(state, action: PayloadAction<any>) {
      state.siteManagerActive = action.payload;
    },
    // Site manager Info
    fetchTripsItemDetail(state, action: PayloadAction<any>) {
      state.tripsItemDetail = { ...state.tripsItemDetail, loading: true };
    },
    updateTripsItemDetail(state, action: PayloadAction<any>) {
      state.tripsItemDetail = {
        ...state.tripsItemDetail,
        ...action.payload.tripsItemDetail,
        ...{ loading: action.payload.loading },
      };
    },
    updateCompanyId(state, action: PayloadAction<any>) {
      state.selectedCompanyID = action.payload;
    },
    updateSiteId(state, action: PayloadAction<any>) {
      state.selectedSiteID = action.payload;
    },
    updateDashboardFilter(state, action: PayloadAction<any>) {
      state.dashboardFilter = {
        ...state.dashboardFilter,
        ...action.payload,
      };
    },
    updateResolvedEventsActive(state, action: PayloadAction<any>) {
      state.resolvedEventsActive = action.payload;
    },
    fetchVideoViewerInfo(state, action: PayloadAction<any>) {
      state.videoViewerInfo = {
        ...state.videoViewerInfo,
        data: [],
        loading: true,
      };
    },
    updateVideoViewerInfo(state, action: PayloadAction<any>) {
      state.videoViewerInfo = {
        ...state.videoViewerInfo,
        ...action.payload,
      };
    },

    fetchFilterSystemData(state, action: PayloadAction<FilterProps>) {
      state.FilterSystemList = { ...state.FilterSystemList, loading: true };
    },
    updateFilterSystemData(state, action: PayloadAction<FilterListMapProps>) {
      state.FilterSystemList = {
        ...state.FilterSystemList,
        ...action.payload,
      };
    },
    updateSelectedSiteName(state, action: PayloadAction<any>) {
      state.selectedSiteName = action.payload;
    },
    updateDashboardActiveTab(state, action: PayloadAction<any>) {
      state.activeDashboardTab = action.payload;
    },
    updateDashboardListPageNo(state, action: PayloadAction<any>) {
      state.dashboardListPageNo = action.payload;
    },
    updateImpersonateSession(state, action: PayloadAction<any>) {
      state.impersonateSession = action.payload;
    },
    addSRItemCaseNote(state, action: PayloadAction<any>) {
      state.addSRItemCaseNote = { ...state.addSRItemCaseNote, loading: true };
    },
    updateAddSRItemCaseNote(state, action: PayloadAction<any>) {
      state.addSRItemCaseNote = {
        ...action.payload,
      };
    },
    addSRItemComment(state, action: PayloadAction<any>) {
      state.addSRItemComment = { ...state.addSRItemComment, loading: true };
    },
    updateAddSRItemComment(state, action: PayloadAction<any>) {
      state.addSRItemComment = {
        ...action.payload,
      };
    },
    fetchSRItemComment(state, action: PayloadAction<any>) {
      state.SRItemComments = { data: [], loading: true };
    },
    updateFetchSRItemComment(state, action: PayloadAction<any>) {
      state.SRItemComments = {
        ...state.SRItemComments,
        ...action.payload,
      };
    },
    srItemEscalate(state, action: PayloadAction<any>) {
      state.srItemEscalate = { ...state.srItemEscalate, loading: true };
    },
    updateSRItemEscalate(state, action: PayloadAction<any>) {
      state.srItemEscalate = {
        ...action.payload,
      };
    },
    fetchSRItemInternalNote(state, action: PayloadAction<any>) {
      state.itemInternalNotes = {
        data: [],
        loading: true,
      };
    },
    fetchActiveSecurityEventsCount(
      state,
      action: PayloadAction<ServiceCountProps>,
    ) {
      state.ServiceCount = { ...state.ServiceCount };
    },
    updateSRItemInternalNote(state, action: PayloadAction<any>) {
      state.itemInternalNotes = {
        ...state.itemInternalNotes,
        ...action.payload,
      };
    },
    addSRItemInternalNote(state, action: PayloadAction<any>) {
      state.srItemInternalNotes = {
        ...state.srItemInternalNotes,
        loading: true,
      };
    },
    updateddSRItemInternalNote(state, action: PayloadAction<any>) {
      state.srItemInternalNotes = {
        ...action.payload,
      };
    },
    fetchSecurityEventCategory(state) {
      state.FilterDropdownData = { ...state.FilterDropdownData };
    },
    fetchSecurityEventDetails(state, action: PayloadAction<any>) {
      state.securityEventDetails = {
        data: [],
        loading: true,
      };
    },
    updateSecurityEventDetails(state, action: PayloadAction<any>) {
      state.securityEventDetails = {
        ...state.securityEventDetails,
        ...action.payload,
      };
    },
    fetchSecurityEventZones(state, action: PayloadAction<any>) {
      state.securityEventZones = {
        ...state.securityEventZones,
        loading: true,
      };
    },
    updateSecurityEventZones(state, action: PayloadAction<any>) {
      state.securityEventZones = {
        ...state.securityEventZones,
        ...action.payload,
      };
    },
    fetchSecurityEventActionDetails(state, action: PayloadAction<any>) {
      state.securityEventCurrentActions = {
        ...state.securityEventCurrentActions,
        loading: true,
      };
    },
    updateSecurityEventActionDetails(state, action: PayloadAction<any>) {
      state.securityEventCurrentActions = {
        ...state.securityEventCurrentActions,
        ...action.payload,
      };
    },
    updateReportActiveTab(state, action: PayloadAction<any>) {
      state.activeReportTab = action.payload;
    },
    fetchSRDownload(state, action: PayloadAction<any>) {
      state.srDownloadAttachment = {
        ...state.srDownloadAttachment,
        loading: true,
      };
    },
    updateSRDownload(state, action: PayloadAction<any>) {
      state.srDownloadAttachment = {
        ...state.srDownloadAttachment,
        ...action.payload,
      };
    },
    updateSiteStatus(state, action: PayloadAction<any>) {
      state.siteActiveStatus = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = DashboardSlice;
