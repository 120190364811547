export const BUTTON_TEXT = {
  CONTACT_SALES: 'Contact Sales',
  LEARN_MORE: 'Learn More',
};
export const BUSINESS_INTERFACE = {
  LEFT_TITLE: 'Uncover Business Insights with Interface',
  LEFT_SUB_TITLE: `Gain actionable insights from video cameras, POS systems, and alarm
  events to reduce shrink, improve employee productivity, and deliver
  outstanding customer experiences.`,
  RIGHT_TITLE: "Find Out What Works and What Doesn't",
  RIGHT_SUB_TITLE:
    'Measure speed of service, spot compliance gaps, optimize shift management, and conduct investigations faster.',
};
export const BUSINESS_SECURITY = {
  LEFT_TITLE: 'Reimagine Business Security with Interface',
  LEFT_SUB_TITLE:
    'Deploy our suite of managed business security solutions to safeguard your people, assets, and customers.',
  RIGHT_TITLE: 'Secure All Your Locations with a Unified Solution',
  RIGHT_SUB_TITLE:
    'Our physical security experts can enable your business to streamline security, reduce shrink, and stop crimes before they happen.',
};
export const MANAGED_NETWORK = {
  LEFT_TITLE: 'Accelerate Digital Transformation with Managed Network & Voice',
  LEFT_SUB_TITLE:
    'Deploy our purpose-built network and voice solutions to offer seamless connectivity at all your locations, secure your data, and reduce network management complexity.',
  RIGHT_TITLE: 'Bespoke Network & Voice Solution in a Box',
  RIGHT_SUB_TITLE:
    'Rapidly deploy our customized network and voice solution that’s factory-fitted with routers, firewalls, and access points. Eliminate downtime and maximize sales.',
};
